import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { UserContext } from "./User/UserContext";

interface ProtectedWrapperProps {
    component: React.ReactElement;
    adminsOnly?: boolean;
    managerOnly?: boolean; // Marking as optional
}

const ProtectedWrapper: React.FC<ProtectedWrapperProps> = ({ component, adminsOnly = false, managerOnly = false }) => {
    const { user, isLoading } = useContext(UserContext);
    const loc = useLocation();

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!user) {
        return <Navigate to="/login" replace state={{ from: loc.pathname }} />;
    }

    if (adminsOnly && user.role < 2) {
        return <Navigate to="/unauthorized" replace={true} />;
    }

    if (managerOnly && user.role < 6) {
        return <Navigate to="/unauthorized" replace={true} />;
    }

    return component;
};

export default ProtectedWrapper;
