import styled from "styled-components";

const TopicListItem = styled.li`
  margin-bottom: 10px;
  margin-left: 15%;
  width: auto;
  align-items: center;
  justify-content: center;
`;

const TopicListItems = styled.li`
  margin-bottom: 10px;
  margin-left: 5%;
  width: auto;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 5px 8px;
    max-width: 100%;
  }
`;
const TopicListItems2 = styled.li`
  margin-top: 30px;
  margin-left: 35%;
  width: auto;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 5px 8px;
    margin-top: 0px;
    margin-left: 3%;
    max-width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  padding: 0 10px;
`;

const Card = styled.div`
  display: flex;
  /* flex-direction: column; */
  background: linear-gradient(135deg, #333333, #121212);
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 950px;
  color: white;
  margin: 20px;
  justify-content: center;
  padding: 20px;
`;

const LeftPanel = styled.div`
  flex: 1;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* margin-right: 1px; */
  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const RightPanel = styled.div`
  margin-right: 15px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  height: min-content;
  flex: 2;
  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;
// const RightCard = styled.div`
//   display: flex;
//   background: linear-gradient(135deg, #333333, #121212);
//   border-radius: 8px;
//   box-shadow: 0 2px 1px rgba(0, 0, 0, 0.1);
//   width: 100%;
//   color: white;
//   border: 5px brown solid;
//   margin: 20px;
//   padding: 20px;
// `;

const Heading = styled.h1`
  font-size: 1.5em;
  margin-bottom: 20px;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 1em;
    margin-left: 5px;
  }
`;

const Headings = styled.h1`
  font-size: 1.5em;
  margin-bottom: 20px;
  text-align: center;
  @media (max-width: 768px) {
    text-align: center;
    font-size: 1em;
  }
`;

const TopicList = styled.ul`
  list-style-type: none;
  padding: 0;
  justify-content: center;
  @media (max-width: 768px) {
    padding: 5px 8px;
  }
`;

const Button = styled.button`
  background: #1e1d1ddd;
  color: white;
  border: none;
  box-shadow: 0 0 2px gray;
  width: 100%;
  max-width: 190px;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background: #e67e00;
    color: black;
  }
  @media (max-width: 768px) {
    padding: 5px 8px;
    max-width: 100%;
  }
`;

const BackButton = styled(Button)`
  margin-top: 30px;
  margin-left: 0;
  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 10px;
  }
`;

export {
  TopicListItem,
  TopicListItems,
  TopicListItems2,
  Container,
  Card,
  LeftPanel,
  RightPanel,
  Heading,
  Headings,
  TopicList,
  Button,
  BackButton,
};
