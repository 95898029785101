import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { SunsetAPIAccountContext } from "../Networking/Interface/Account/AccountAPIProvider";

const StyledBanner = styled.div`
  width: 100%;
  min-height: 28px;
  border: 1px solid white;
  border-radius: 6px;
  background-color: #931e1e;
  margin-bottom: 25px;

  @media (max-width: 600px) {
    min-height: 50px;
  }
`;

const StyledLabel = styled.p`
  line-height: 1.9;
  color: white;
  font-weight: 100;
  text-align: center;
  font-size: 17px;
  word-spacing: 3px;

  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 1.6;
    font: roboto;
  }

  a {
    color: white;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
`;

export default function Banned({ account }) {
  const [currentBan, setCurrentBan] = useState(null);
  const api = useContext(SunsetAPIAccountContext);

  function formatDate(dateString) {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  useEffect(() => {
    const getBan = async () => {
      const response = await api.GetAccountBansData(account);
      if (response.success && response.data) {
        setCurrentBan(response.data);
      } else {
        setCurrentBan(null); // Reset currentBan when there's no ban data
      }
    };

    getBan();
  }, [api, account]);

  return (
    <>
      {currentBan && (
        <StyledBanner>
          <StyledLabel>
            Banned by {currentBan[0].admin} on {formatDate(currentBan[0].bandate)} for: {currentBan[0].reason}
          </StyledLabel>
        </StyledBanner>
      )}
    </>
  );
}
