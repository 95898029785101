import "./App.css";
import Login from "./pages/Login/login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import LogDashboard from "./Staff/Logs/LogDashboard";
import ProtectedWrapper from "./ProtectedWrapper";
import { AccountDashboard } from "./pages/Account/AccountDashboard";
import DynamicPage from "./Utils/pagetitle";
import { UserDashboard } from "./User/UserDashboard";
import HelpPage from "./pages/Help/helpPage";
import DealershipPage from "./pages/Assets/DealershipPage";
import LandingPage from "./pages/Index/LandingPage";
import { FactionDashboard } from "./pages/Factions/FactionsDashboard";
import { SunsetAPIProvider } from "./Networking/ApiProvider";
import { LauncherAPIProvider } from "./Networking/LauncherAPIProvider";
import { FactionView } from "./pages/Factions/FactionView";
import { DonationView } from "./pages/Donations/DonationView";
import CrateListView from "./pages/Gamba/CrateListView";
import CrateContainerView from "./pages/Gamba/CrateContainerView";
import { DefaultMetaTags } from "./Components/DefaultMetaTags";
import { ToastProvider } from "./Components/Toast/ToastProvider";
import { ApprovePayment } from "./pages/Donations/ApprovePayment";
import { SuccessPage } from "./pages/Donations/SuccessPage";
import ErrorFallback from "./ui/ErrorFallback";
import { ErrorBoundary } from "react-error-boundary";
import { SunsetFactionAPIProvider } from "./Networking/Interface/Faction/FactionApiProvider";
import { UserProvider } from "./User/UserProvider";
import { SunsetAccountAPIProvider } from "./Networking/Interface/Account/AccountAPIProvider";
import SessionPage from "./Launcher/SessionPage";
import { SunsetLogAPIProvider } from "./Networking/Interface/Logs/LogAPIProvider";
import { SunsetGambaAPIProvider } from "./Networking/Interface/Gamba/GambaAPIProvider";
import { ManagementDashboard } from "./pages/Management/ManagementDashboard";
import { SunsetAPIMangementProvider } from "./Networking/Interface/Management/SunsetAPIManagementProvider";
import Footer from "./Components/Navbar/Footer";
import BalanceViewSixy from "./pages/Donations/BalanceViewSixy";
import ApproveStripePayment from "./pages/Donations/ApproveStripePayment";
// import LauncherDownload from "./pages/Launcher/Launcher";
import ManagementLauncher from "./pages/Management/ManagementLauncher";
import LauncherDownload from "./pages/Launcher/Launcher";

export default function App() {
  return (
    <div id="page-container">
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => window.location.replace("/")}
      >
        <ToastProvider>
          <SunsetAPIProvider>
            <SunsetAPIMangementProvider>
              <SunsetGambaAPIProvider>
                <SunsetLogAPIProvider>
                  <SunsetFactionAPIProvider>
                    <SunsetAccountAPIProvider>
                      <LauncherAPIProvider>
                        <UserProvider>
                          <BrowserRouter>
                            <DefaultMetaTags />
                            <Navbar />
                            <Routes>
                              <Route
                                path="/"
                                element={
                                  <DefaultRoute
                                    title="Sunset Roleplay"
                                    component={<LandingPage />}
                                  />
                                }
                              />
                              <Route
                                path="/crates/:crateID"
                                element={<CrateContainerView />}
                              />
                              <Route
                                path="/crates"
                                element={
                                  <ProtectedWrapper
                                    adminsOnly={false}
                                    component={<CrateListView />}
                                  />
                                }
                              />
                              <Route
                                path="/management"
                                element={
                                  <ManagerRoute
                                    title="Sunset Roleplay - Management"
                                    component={<ManagementDashboard />}
                                  />
                                }
                              />
                              <Route
                                path="/management-launcher"
                                element={
                                  <ManagerRoute
                                    title="Sunset Roleplay - Launcher Dashboard"
                                    component={<ManagementLauncher />}
                                  />
                                }
                              />
                              <Route
                                path="/login"
                                element={
                                  <DefaultRoute
                                    title="Sunset Roleplay - Login"
                                    component={<Login />}
                                  />
                                }
                              />
                              <Route
                                path="/logs"
                                element={
                                  <AdminRoute
                                    title="Sunset Roleplay - Logs"
                                    component={<LogDashboard />}
                                  />
                                }
                              />
                              <Route path="/user" element={<UserRoute />} />
                              <Route
                                path="/donations"
                                element={
                                  <DefaultRoute
                                    title="Sunset Roleplay - Donation"
                                    component={<DonationView />}
                                  />
                                }
                              />
                              <Route
                                path="/donations/add-credits"
                                element={
                                  <DefaultRoute
                                    title="Sunset Roleplay - Donation"
                                    component={<BalanceViewSixy />}
                                  />
                                }
                              />
                              <Route
                                path="/accounts"
                                element={
                                  <AccountRoute
                                    title="Sunset Roleplay-Accounts"
                                    component={<AccountDashboard />}
                                  />
                                }
                              />
                              <Route
                                path="/accounts/:id/:name"
                                element={
                                  <ProtectedWrapper
                                    component={<AccountDashboard />}
                                    adminsOnly={false}
                                  />
                                }
                              />
                              <Route
                                path="/launcher-download"
                                element={
                                  <DefaultRoute
                                    title="Sunset Roleplay - Launcher"
                                    component={<LauncherDownload />}
                                  />
                                }
                              />
                              <Route
                                path="/launcher/session/:id"
                                element={
                                  <ProtectedWrapper
                                    component={<SessionPage />}
                                    adminsOnly={true}
                                  />
                                }
                              />
                              <Route
                                path="/faction/:tag"
                                element={
                                  <ProtectedWrapper
                                    component={<FactionView />}
                                    adminsOnly={false}
                                  />
                                }
                              />
                              <Route
                                path="/factions/*"
                                element={
                                  <ProtectedWrapper
                                    component={<FactionWrapper />}
                                    adminsOnly={false}
                                  />
                                }
                              />
                              <Route
                                path="/approve-payment/:amount"
                                element={
                                  <ProtectedWrapper
                                    component={<ApprovePayment />}
                                    adminsOnly={false}
                                  />
                                }
                              />

                              <Route
                                path="/approve-stripepayment/:amount"
                                element={
                                  <ProtectedWrapper
                                    component={<ApproveStripePayment />}
                                    adminsOnly={false}
                                  />
                                }
                              />
                              <Route
                                path="/payment-success"
                                element={
                                  <ProtectedWrapper
                                    component={<SuccessPage />}
                                    adminsOnly={false}
                                  />
                                }
                              />
                              <Route path="/help" element={<HelpPage />} />
                              <Route path="/help/:id" element={<HelpPage />} />
                              <Route
                                path="/dealership"
                                element={<DealershipPage />}
                              />
                            </Routes>
                            <Footer
                              creditedPeople={[
                                "Valentin",
                                "Sixy",
                                "Foksha",
                                "Duplon",
                              ]}
                            />
                          </BrowserRouter>
                        </UserProvider>
                      </LauncherAPIProvider>
                    </SunsetAccountAPIProvider>
                  </SunsetFactionAPIProvider>
                </SunsetLogAPIProvider>
              </SunsetGambaAPIProvider>
            </SunsetAPIMangementProvider>
          </SunsetAPIProvider>
        </ToastProvider>
      </ErrorBoundary>
    </div>
  );
}

interface RouteProps {
  title: string;
  description?: string;
  component: React.ReactElement;
}

const DefaultRoute: React.FC<RouteProps> = ({
  title,
  description,
  component,
}) => (
  <>
    <DynamicPage
      title={title}
      description={
        description ||
        "You must be signed in to be authorized to view this page."
      }
    />
    {component}
  </>
);

const AdminRoute: React.FC<RouteProps> = ({
  title,
  description,
  component,
}) => (
  <>
    <DynamicPage
      title={title}
      description={description || "Admin restricted page for logs."}
    />
    <ProtectedWrapper component={component} adminsOnly={true} />
  </>
);

const UserRoute: React.FC = () => (
  <>
    <DynamicPage title="Sunset Roleplay - User" description="User dashboard." />
    <ProtectedWrapper component={<UserDashboard />} adminsOnly={false} />
  </>
);
interface ManagerRouteProps {
  title: string;
  component: React.ReactElement;
}

const ManagerRoute: React.FC<ManagerRouteProps> = ({ title, component }) => (
  <>
    <DynamicPage title={title} description="Management dashboard." />
    <ProtectedWrapper
      component={component}
      adminsOnly={true}
      managerOnly={true}
    />
  </>
);
const AccountRoute: React.FC<RouteProps> = ({
  title,
  description,
  component,
}) => (
  <>
    <DynamicPage
      title={title}
      description={description || "Search here for accounts or characters"}
    />
    <ProtectedWrapper component={component} adminsOnly={false} />
  </>
);

const FactionWrapper: React.FC = () => (
  <Routes>
    <Route
      path="/"
      element={<FactionDashboard defaultTabKey="recentPosts" />}
    />
    <Route
      path="recentPosts"
      element={<FactionDashboard defaultTabKey="recentPosts" />}
    />
    <Route
      path="myFaction"
      element={<FactionDashboard defaultTabKey="myFaction" />}
    />
    <Route
      path="factions"
      element={<FactionDashboard defaultTabKey="factions" />}
    />
  </Routes>
);
