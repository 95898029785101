import React from 'react';
import LauncherAPI from "./LauncherAPI";

interface LauncherAPIProviderProps {
    children: React.ReactNode;
}

const LauncherAPIContext = React.createContext<LauncherAPI | undefined>(undefined);

const LauncherAPIProvider: React.FC<LauncherAPIProviderProps> = ({ children}) => {
    const launcherAPI = new LauncherAPI("http://localhost:7203/");
    const api = new LauncherAPI()

    return (
        <LauncherAPIContext.Provider value={api}>
            {children}
        </LauncherAPIContext.Provider>
    );
};

export { LauncherAPIProvider, LauncherAPIContext };
