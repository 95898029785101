import React, { useContext, useEffect, useState } from "react";
import InfoLineView, { InfoLine } from "../../Components/InfoLineView";
import { Table } from "../../Components/Table";
import { TabPane, Tabs } from "../../Components/Tabs";
import {
  CharacterDTO,
  ItemDTO,
  PlayerNameHistory,
  PropertyDTO,
  VehicleDTO,
  Wealth,
} from "../../Networking/Interface/Account/AccountInterface";
import { UserContext } from "../../User/UserContext";
import { JobClass, UserRole } from "../../Utils/enums";
import ItemsView from "../Assets/ItemsView";
import VehicleView from "../Assets/VehicleView";
import WealthView from "../Assets/WealthView";
import PlaytimeGraph from "./PlaytimeGraph";
import { SunsetAPIAccountContext } from "../../Networking/Interface/Account/AccountAPIProvider";
import { JobDataDTO } from "../../Networking/SunsetAPI";
import PropertyView from "../Assets/PropertyView";
import SetCharacterNameButton from "../../Staff/Actions/SetCharacterName";

interface CharacterViewProps {
  character: CharacterDTO;
}

export const CharacterView: React.FC<CharacterViewProps> = ({ character }) => {
  const api = useContext(SunsetAPIAccountContext);
  const [jobData, setJobData] = useState<JobDataDTO | null>(null);
  const [vehicles, setVehicles] = useState<VehicleDTO[]>([]);
  const [properties, setProperties] = useState<PropertyDTO[]>([]);
  const [wealth, setWealth] = useState<Wealth | undefined>();
  const [items, setItems] = useState<ItemDTO[]>([]);
  const [nameHistory, setNameHistory] = useState<PlayerNameHistory[]>([]);
  const { user, isLoading } = useContext(UserContext);

  const fetchData = async () => {
    if (api) {
      let response = await api.getCharacterJobData(
        character.id,
        character.characterName
      );
      if (response?.success) {
        setJobData(response.data!);
      }
      if (hasFullView()) {
        let vehicleResponse = await api.getVehicles(character.characterName);
        if (vehicleResponse.success) {
          setVehicles(vehicleResponse.data!);
        }
        let propertyResponse = await api.getProperties(character.characterName);
        if (propertyResponse.success) {
          setProperties(propertyResponse.data!);
        }
        let wealthResponse = await api.getWealth(character.characterName);
        if (wealthResponse.success) {
          setWealth(wealthResponse.data!);
        }
        let itemResponse = await api.getItems(character.characterName);
        if (itemResponse.success) {
          setItems(itemResponse.data!);
        }
        let nameHistoryResponse = await api.getPlayerNameHistory(character.id);
        if (nameHistoryResponse.success) {
          if (nameHistoryResponse.data?.length === 0) {
            return;
          }
          setNameHistory(nameHistoryResponse.data!);
        }
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, [character, api]);

  const hasFullView = (): boolean => {
    if (!user || isLoading) {
      return false;
    }
    if (Number.parseInt(user.id) === character.userId) {
      return true;
    }
    if (user.role >= UserRole.Admin) {
      return true;
    }
    return false;
  };
  const hasRole = (minLevel: number) => {
    return user && user.role >= minLevel;
  };
  return (
    <Tabs>
      <TabPane tabKey="1" tabTitle="Stats">
        {hasRole(6) && (
          <SetCharacterNameButton userId={character.id} onSuccess={fetchData} />
        )}

        <InfoLineView title="Character stats">
          <InfoLine
            label="Playtime:"
            value={(character.totalPlaytime / 60).toFixed(2) + "h"}
          />
          {jobData && (
            <InfoLine label="Occupation:" value={JobClass[jobData.jobClass]} />
          )}
        </InfoLineView>
        <hr style={{ margin: "20px 0" }} />
        <PlaytimeGraph character={character}></PlaytimeGraph>
      </TabPane>
      {hasFullView() && vehicles?.length > 0 && (
        <TabPane tabKey="2" tabTitle="Vehicles">
          <VehicleView vehicles={vehicles}></VehicleView>
        </TabPane>
      )}
      {hasFullView() && !!wealth && (
        <TabPane tabKey="3" tabTitle="Assets">
          <WealthView
            userId={character.id}
            wealth={wealth}
            characterName={character.characterName} // Pass character name
            setWealth={setWealth} // Pass setWealth function
          />
          <ItemsView items={items}></ItemsView>
        </TabPane>
      )}
      {hasFullView() && !!wealth && (
        <TabPane
          style={{ marginBottom: "30px" }}
          tabKey="4"
          tabTitle="Name History"
        >
          <Table headers={["From", "To", "Date", "Reason"]}>
            {nameHistory.map((name, index) => (
              <tr key={index}>
                <td>{name.oldname}</td>
                <td>{name.newname}</td>
                <td>{new Date(name.date).toLocaleDateString()}</td>
                <td>{name.reason}</td>
              </tr>
            ))}
          </Table>
        </TabPane>
      )}
      {hasFullView() && properties?.length > 0 && (
        <TabPane tabKey="5" tabTitle="Properties">
          <PropertyView properties={properties}></PropertyView>
        </TabPane>
      )}
    </Tabs>
  );
};
