import React, { useState, useEffect } from 'react';
import styles from './Gallery.module.css'; // Importing the CSS module

import { MdArrowLeft, MdArrowRight } from 'react-icons/md'; // Importing Material Design icons from react-icons

// @ts-ignore
const imageContext = require.context('./GalleryImages', false, /\.(png|jpe?g|webp)$/);

const Gallery: React.FC = () => {
    // @ts-ignore
    const images: string[] = imageContext.keys().map(key => imageContext(key));
    const [activeIndex, setActiveIndex] = useState(0);
    const [transitionState, setTransitionState] = useState('entering');

    useEffect(() => {
        const nextImage = () => {
            setTransitionState('exiting');
            setTimeout(() => {
                const nextIndex = (activeIndex + 1) % images.length;
                setActiveIndex(nextIndex);
                setTransitionState('entering');
            }, 700); // Ensure timing matches CSS transition duration
        };

        const intervalId = setInterval(nextImage, 3700); // Duration each image remains visible
        return () => clearInterval(intervalId);
    }, [activeIndex, images.length]);

    const handlePrev = () => {
        setActiveIndex(prevIndex => prevIndex === 0 ? images.length - 1 : prevIndex - 1);
    };

    const handleNext = () => {
        setActiveIndex(prevIndex => (prevIndex + 1) % images.length);
    };

    return (
        <div className={styles.galleryContainer}>
            <MdArrowLeft size="6em" onClick={handlePrev} className={styles.arrowLeft} />
            <img
                src={images[activeIndex]}
                alt={`Gallery  ${activeIndex + 1}`}
                className={`${styles.galleryImage} ${styles[transitionState]}`}
            />
            <MdArrowRight size="6em" onClick={handleNext} className={styles.arrowRight} />
            <div className={styles.indicators}>
                {images.map((_, idx) => (
                    <span key={idx} className={`${styles.dot} ${activeIndex === idx ? styles.activeDot : ''}`} />
                ))}
            </div>
        </div>
    );
}

export default Gallery;
