
import React, { useContext } from "react";
import { AccountDTO } from "../../Networking/Interface/Account/AccountInterface";
import { UserContext } from "../../User/UserContext";
import { Button } from "antd";
import { SunsetAPIAccountContext } from "../../Networking/Interface/Account/AccountAPIProvider";

interface UnbanUserProps {
  accountDTO: AccountDTO;
  onSuccess: () => void;
}

function UnbanUser({ accountDTO, onSuccess }: UnbanUserProps) {
  const { user } = useContext(UserContext);
  const api = useContext(SunsetAPIAccountContext);

  async function submitUnban() {
    try {

      const unbanResult = await api!.unbanCurrentUser(accountDTO.id);

      if (unbanResult.success) {
    
        onSuccess();
      } else {
        console.error("Failed to unban user");
      }
    } catch (error) {
      console.error("Error sending unban request:", error);
    }
  }

  if (user && user.role > 3) {
    return (
      <div>
        <Button style={{ border: "none" }} onClick={submitUnban}>
          Unban
        </Button>
      </div>
    );
  }

  return null;
}

export default UnbanUser;
