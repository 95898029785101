import React from "react";
import { SunsetAPIManagement } from "./SunsetAPIManagement";

const SunsetAPIManagementContext = React.createContext<SunsetAPIManagement | undefined>(undefined);

interface SunsetAPIManagementProps {
    children: React.ReactNode;
    baseURL?: string; // Allow optional customization of the API base URL
}

// Define the provider component
const SunsetAPIMangementProvider: React.FC<SunsetAPIManagementProps> = ({ children, baseURL = "https://ss-rp.com/" }) => {
    const api = new SunsetAPIManagement(baseURL);

    return (
        <SunsetAPIManagementContext.Provider value={api}>
            {children}
        </SunsetAPIManagementContext.Provider>
    );
};

export { SunsetAPIMangementProvider, SunsetAPIManagementContext };