import React, { useState, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { SunsetAPIAccountContext } from "../../Networking/Interface/Account/AccountAPIProvider";
import { ToastType } from "../../Components/Toast/Toast";
import { useToast } from "../../Components/Toast/ToastContext";
import { Button, Input } from "antd";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const InputBox = styled(Input)`
  display: flex;
  align-items: center;
  margin-left: 15px;
  width: 150px;
  margin-right: 1px;
`;

interface SetCharacterNameButtonProps {
  userId: number;
  onSuccess?: () => void;
}

const SetCharacterNameButton: React.FC<SetCharacterNameButtonProps> = ({ userId, onSuccess }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState<string>("");
  const { showToast } = useToast();
  const api = useContext(SunsetAPIAccountContext);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsEditing(false);
        setName(""); // Clear the input field
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSubmit = async () => {
    if (name.trim() === "") {
      showToast(ToastType.Error, "Character name cannot be empty", 5);
      return;
    }

    try {
 
      const response = await api?.setCharacterName(userId, name);
      if (!response?.success) {
        throw new Error("Failed to set character name");
      }
      showToast(ToastType.Success, "Character name set successfully", 5);

      setIsEditing(false); // Close the input field after successful submission
      setName(""); // Clear the input field
      if (onSuccess) {
        onSuccess(); // Call onSuccess if provided
      }
    } catch (error) {
      console.error("Error setting character name:", error);
      showToast(ToastType.Error, "Failed to set character name", 5);
    }
  };

  return (
    <Container ref={containerRef}>
      {isEditing ? (
        <>
          <InputBox
            placeholder="Character Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
          />
          <Button style={{ border: "none" }} onClick={handleSubmit}>
            Set
          </Button>
        </>
      ) : (
        <Button style={{ border: "none" }} onClick={() => setIsEditing(true)}>
          Set Character Name
        </Button>
      )}
    </Container>
  );
};

export default SetCharacterNameButton;
