import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SuccessPage.module.css";
import Confetti from 'react-confetti';
import useWindowSize from "react-use/lib/useWindowSize";

export const SuccessPage = () => {
    const navigate = useNavigate();
    const { width, height } = useWindowSize();

    return (
        <div className={styles.successContainer}>
            <Confetti width={width} height={height} />
            <div className={styles.successBox}>
                <h1 className={styles.successTitle}> 🎉 Hooray! 🎉 </h1>
                <h2 className={styles.successSubtitle}> Your Payment was Successful!</h2>
                <p className={styles.successText}>
                    Congratulations! Your payment has been processed successfully, and your order is now confirmed.
                </p>
                <p className={styles.successText}>
                    Your credits will be given out automatically. Any issues regarding your donations can be resolved through support.
                </p>
                <button className={styles.successButton} onClick={() => navigate('/')}>
                    Go back to Home
                </button>
            </div>
        </div>
    );
};
