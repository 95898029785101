import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";

interface AHKScript {
  FileName: string;
  Contents: string | string[];
  StartTime: string;
}

interface AHKScriptModalProps {
  open: boolean;
  data: AHKScript[];
  onClose: () => void;
}

const AHKScriptModal: React.FC<AHKScriptModalProps> = ({
  open,
  data,
  onClose,
}) => {
  const getDateTime = (
    date: string | Date | null
  ): {
    dateString: string;
    timeString: string;
  } => {
    if (date === null) {
      return { dateString: "", timeString: "" };
    }
    const d = new Date(date);
    const dateString = d.toLocaleDateString();
    const timeString = d.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    return { dateString, timeString };
  };

  const formatScript = (script: AHKScript, index: number) => {
    Object.entries(script).forEach(([key, value], idx) => {});

    return (
      <div key={index} style={{ marginBottom: "20px" }}>
        {Object.entries(script).map(([key, value], idx) => (
          <div key={idx} style={{ marginBottom: "10px" }}>
            {value[0] === "StartTime" ? (
              <p style={{ margin: "0", marginBottom: "5px" }}>
                <strong>{"Start Time"}:</strong>{" "}
                {getDateTime(value[1] as string).dateString}{" "}
                {getDateTime(value[1] as string).timeString}
              </p>
            ) : (
              <p style={{ margin: "0", marginBottom: "5px" }}>
                <strong>{value[0]}:</strong> {value[1]}
              </p>
            )}
          </div>
        ))}
        <hr
          style={{
            margin: "10px 0",
            border: "none",
            borderTop: "1px solid #ccc",
          }}
        />
      </div>
    );
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="ahk-modal-title">
      <DialogTitle id="ahk-modal-title">AHK Script Details</DialogTitle>
      <DialogContent dividers>
        {data.map((script, index) => formatScript(script, index))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AHKScriptModal;
