import { APIResponse, APIUtility } from "./ApiUtility";
import { AccountDTO } from "./Interface/Account/AccountInterface";
class LauncherAPI {
  private apiUtility: APIUtility;

  constructor(baseURL: string = "https://ss-rp.com/") {
    this.apiUtility = new APIUtility(baseURL);
    // this.apiUtility.setDefaultHeaders({});
  }

  async authenticate(username: string, password: string): Promise<void> {
    const response = await this.apiUtility.post("/login", {
      username,
      password,
    });

    console.log("Authenticated successfully");
  }

  //   async getSessions(): Promise<any> {
  // //     const authToken = document.cookie
  // //         .split('; ')
  // //         .find(row => row.startsWith('AuthorizationCookie='))
  // //         ?.split('=')[1];
  // // console.log(authToken)
  // //     if (!authToken) {
  // //         // Handle case where authentication token is not found
  // //         console.error("Authentication token not found in cookies.");
  // //         return;
  // //     }

  // //     const customHeaders = {
  // //         Authorization: `Bearer ${authToken}`,
  // //     };

  //     const response = await this.apiUtility.get("exp-api/launcher/session/list");

  //     // Handle the response as needed
  //     return response;
  // }
  async getSessions(id: number): Promise<any> {
    await this.apiUtility.get(`exp-api/launcher/session/list/${id}`);
  }
  async forceUser(id:number): Promise<APIResponse<AccountDTO>> {
    return await this.apiUtility.post(`api/exp-api/launcher/force`)
  }

  async getScreenshot(id: number): Promise<any> {
    await this.apiUtility.post(`exp-api/launcher/screenshot/request/${id}`);
  }

  async checkFileUses(name: string, hash: string): Promise<any> {
    const response = await this.apiUtility.post("/launcher/file/uses", {
      name,
      hash,
    });

    return response.data;
  }

  async getCurrentGameDirectory(sessId: string): Promise<any> {
    const response = await this.apiUtility.post("/launcher/directory/display", {
      sessId,
    });

    return response.data;
    // Process the json data as needed
  }

  async takeScreenshot(sessId: string): Promise<any> {
    const response = await this.apiUtility.post(
      "/launcher/screenshot/display",
      { sessId }
    );
    return response.data;
  }

  async getActiveProcesses(sessId: string): Promise<any> {
    const response = await this.apiUtility.post("/launcher/processes/display", {
      sessId,
    });

    return response.data;
  }

  async getSessionData(sessId: string): Promise<any> {
    const response = await this.apiUtility.get(`/launcher/session/${sessId}`);

    return response.data;
  }

  async getLauncherLogs(sessId: string): Promise<any> {
    const response = await this.apiUtility.post("/launcher/logs", { sessId });

    return response.data;
  }

  async compileFileDownload(
    name: string,
    hash: string,
    sessId: string
  ): Promise<void> {
    try {
      // Retrieve content from client
      const response = await this.apiUtility.get(
        `/launcher/directory/files/content/${name}/${hash}`
      );

      if (response.success) {
        const response2 = await this.apiUtility.post(
          "/launcher/file/content/view",
          {}
        );

        if (response2.success) {
          const json = response2.data;
          console.log(json);
        } else {
          console.error("Failed to fetch file content");
        }
      } else {
        console.error("Failed to fetch directory file content");
      }
    } catch (error) {
      console.error("Error fetching file content:", error);
      // Handle the error as needed
    }
  }
}

export default LauncherAPI;
