import React, { useState, useEffect, useContext } from "react";
import { InfinitySpin } from "react-loader-spinner";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { BlackCard } from "../../Components/BlackCard";
import GoldShinyButton from "../../Components/GoldShinyButton";
import { ToastType } from "../../Components/Toast/Toast";
import { useToast } from "../../Components/Toast/ToastContext";
import Wrapper from "../../Components/Wrapper";
import styles from "./ApprovePayment.module.css";
import { AccountDTO } from "../../Networking/Interface/Account/AccountInterface";
import { SunsetAPIAccountContext } from "../../Networking/Interface/Account/AccountAPIProvider";
import { UserContext } from "../../User/UserContext";

const disableBackNavigation = () => {
  window.history.pushState(null, "", window.location.href);
  window.onpopstate = function(event) {
    window.history.pushState(null, "", window.location.href);
  };
};

const ApproveStripePayment: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { showToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [sessionID, setSessionID] = useState<string>("");
  const { amount } = useParams<{ amount?: string }>();
  const amountNumber = parseInt(amount || "0", 10);
  const creditAmount = amountNumber / 1000;
  const [userAccountId, setUserAccountId] = useState<AccountDTO | number>();
  const api = useContext(SunsetAPIAccountContext);
  const userContext = useContext(UserContext);

  useEffect(() => {
    const fetchAccount = async () => {
      try {
        const response = await api!.getAccountByIdAndName(
          parseInt(userContext.user?.id!),
          userContext.user?.username!
        );
        if (response.success && response.data) {
          setUserAccountId(response.data.id);
        } else {
          showToast(ToastType.Error, "Failed to fetch the account.", 5);
        }
      } catch (error) {
        console.error("Error fetching account:", error);
        showToast(ToastType.Error, "Error fetching the account.", 5);
      }
    };

    fetchAccount();
  }, [api, userContext.user?.id, userContext.user?.username, showToast]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const sessionIDParam = searchParams.get("session_id");
    setSessionID(sessionIDParam || "");

    const checkPaymentStatus = async () => {
      if (sessionIDParam) {
        try {
          const response = await fetch(
            `https://ss-rp.com/exp-api/payment/check-status`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({ sessionId: sessionIDParam }),
            }
          );
          const result = await response.json();
          if (result.success && result.paymentStatus === "paid") {
            // Optionally handle already paid status without navigating
          }
        } catch (error) {
          console.error("Error checking payment status:", error);
        }
      }
    };

    checkPaymentStatus();
  }, [location.search, navigate]);

  const approvePayment = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        "https://ss-rp.com/exp-api/payment/execute-stripe-payment",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ sessionId: sessionID, userId: userAccountId }),
        }
      );
      const result = await response.json();

      if (result.success) {
        showToast(ToastType.Success, "Successfully added your credits!", 10);
        localStorage.setItem("paymentAttempted", "true");
        navigate("/payment-success");
        disableBackNavigation();
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      showToast(
        ToastType.Error,
        "Something went wrong! Please contact staff",
        10
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <Wrapper width="1000px">
      <div className={styles.paymentApproval}>
        <BlackCard
          subtitle="You are all set!"
          text="Confirm your purchase to receive your credits!"
        ></BlackCard>
        <div className={styles.creditInfo}>
          <img
            src={`${process.env.PUBLIC_URL}/credit.png`}
            alt="Credit"
            className={styles.creditIcon}
          />
          <span className={styles.creditAmount}>
            + {creditAmount.toFixed(2)}
          </span>
        </div>
        {!loading ? (
          <GoldShinyButton
            text="Approve"
            onClick={approvePayment}
          ></GoldShinyButton>
        ) : (
          <InfinitySpin></InfinitySpin>
        )}
      </div>
    </Wrapper>
  );
};

export default ApproveStripePayment;
