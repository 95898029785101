import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Information from "../../Components/Information";
import {
  AccountDTO,
  Punishment,
} from "../../Networking/Interface/Account/AccountInterface";

import { formatDateForDisplay } from "../../Utils/DateTimeUtils";
import "./Punishments.css";
import { SunsetAPIAccountContext } from "../../Networking/Interface/Account/AccountAPIProvider";
import ExpungePunishment from "./ExpungePunishment";

interface PunishmentViewProps {
  account: AccountDTO;
}
const PunishmentsView: React.FC<PunishmentViewProps> = ({ account }) => {
  const [punishments, setPunishments] = useState<Punishment[]>([]);
  const api = useContext(SunsetAPIAccountContext);
  const [expungedReasons, setExpungedReasons] = useState<{
    [id: number]: string;
  }>({});

  useEffect(() => {
    const fetchPunishments = async () => {
      try {
        const response = await api!.getPunishments(account.username);
        if (response && response.success && response.data) {
          setPunishments(response.data);
        } else {
          setPunishments([]);
        }
      } catch (error) {
        setPunishments([]);
      }
    };

    fetchPunishments();
  }, [api, account.username]);

  const handleViewReason = (id: number, reason: string) => {
    setExpungedReasons((prevReasons) => ({
      ...prevReasons,
      [id]: reason,
    }));
  };

  return (
    <>
      <Information title="Account punishments">
        <div className="punishments-container" data-title="Punishments">
          <table className="punishments-table">
            <thead>
              <tr>
                <th>Time</th>
                <th>Reason</th>
                <th>Type</th>
                <th>Admin</th>
                <th>Actions</th>
                <th>Expunge Reason</th>
              </tr>
            </thead>
            <tbody>
              {punishments.map((p) => (
                <tr
                  key={p.id}
                  className={p.active ? "" : "inactive-punishment"}
                >
                  <td className="date-column">
                    {formatDateForDisplay(new Date(p.time))}
                  </td>
                  <td className="reason-column">{p.reason}</td>
                  <td>{p.type}</td>
                  <td>
                    {p.adminuserid === -1 ? (
                      "Watchdog"
                    ) : (
                      <Link
                        to={`/accounts/${p.adminuserid}/${p.adminUsername}`}
                      >
                        {p.adminUsername}
                      </Link>
                    )}
                  </td>

                  <td>
                    {!p.expungeReason && (
                      <ExpungePunishment
                        punishmentId={p.id}
                        onViewReason={(reason) =>
                          handleViewReason(p.id, reason)
                        }
                      />
                    )}
                  </td>
                  <td>
                    {p.expungeAdmin && p.expungeReason && (
                      <>
                        <span>{p.expungeReason}</span> -{" "}
                        <Link
                          to={`/accounts/${p.expungeAdminId}/${p.expungeAdmin}`}
                        >
                          {p.expungeAdmin}
                        </Link>{" "}
                      </>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Information>
    </>
  );
};

export default PunishmentsView;
