import { useContext, useEffect, useState } from "react";
import {useNavigate } from "react-router-dom";
import { AccountDTO } from "../Networking/Interface/Account/AccountInterface";
import { AccountView } from "../pages/Account/AccountView";
import { SunsetAPIAccountContext } from "../Networking/Interface/Account/AccountAPIProvider";
import { UserContext } from "./UserContext";

export const UserDashboard: React.FC = () => {
    const [userAccount, setUserAccount] = useState<AccountDTO | undefined>();
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const api = useContext(SunsetAPIAccountContext);
    const userContext = useContext(UserContext);
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await api!.getAccountByIdAndName(parseInt(userContext.user?.id!), userContext.user?.username!);
                if (response.success && response.data) {
                    setUserAccount(response.data);
                } else {
                    setError('Failed to fetch the account. Please try again later.');
                }
            } catch (e) {
                setError('Error fetching the account. Please try again later.');
            }
        }

        if (userContext.user) {
            fetchUser();
        }
        else if (!userContext.isLoading) {
            navigate("/login");
        }



    }, [userContext, api,navigate]);

    return (
        <>
            <div className="account-wrapper">
                {error && <div className="error-message">{error}</div>}
                {userAccount && <AccountView accountDTO={userAccount} />}
            </div>
        </>
    );
}