import React, { ReactNode } from 'react';
import styles from './IconCard.module.css'; // Ensure this is the correct path to the CSS module

interface IconCardProps {
    backgroundColor?: string;
    title: string;
    icon: ReactNode;
    children: ReactNode;
}

export const IconCard: React.FC<IconCardProps> = ({ backgroundColor, title, icon, children }) => {
    const cardStyle = {
        '--card-background-color': backgroundColor,
    } as React.CSSProperties;

    return (
        <div className={styles.card} style={cardStyle}>
            <div className={styles.cardHeader}>
                <div className={styles.cardIcon}>{icon}</div>
                <div className={styles.cardTitle}>{title}</div>
            </div>
            <div className={styles.divider}></div> {/* Divider added here */}
            <div className={styles.cardContent}>{children}</div>
        </div>
    );
};
