import React from 'react';
import { Faction } from '../../Networking/Interface/Faction/FactionInterface';

import { Link } from 'react-router-dom';
import { InitialsAPI } from '../../Networking/InitialsAPI';
import { FactionColours, getBannerType } from './FactionUtils';

interface FactionCardViewProps {
    faction: Faction;
}


const getFactionColor = (faction: Faction): string => {
    return FactionColours[faction.color] || "#FFFFFF";
};

export const FactionCardView: React.FC<FactionCardViewProps> = ({ faction }) => {
    return (
        <Link className="faction-card" to={`/faction/${faction.tag}`}>
            <div
                key={faction.id}
                style={{ borderColor: getFactionColor(faction) }}
            >
                <img
                    src={faction.bannerURL || process.env.PUBLIC_URL + `/DefaultFactionBanners/${getBannerType(faction.type)}.png`}
                    alt={`${faction.name} banner`}
                    className="faction-banner"
                />
                <div className="faction-info">
                    <img
                        src={faction.profileURL || new InitialsAPI().getAvatarURL({ name: faction.tag, length: 4, fontSize: 0.35 })}
                        alt={faction.name}
                        className="faction-avatar"
                        style={{ borderColor: getFactionColor(faction) }}
                    />
                    <h2 className="faction-name">{faction.name}</h2>
                </div>
            </div>
        </Link>
    );
};
