import React, { useState } from "react";
import styled from "styled-components";

const LauncherDownloadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 70px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  max-height: 600px;
  max-width: 1000px;
  margin: auto;
  font-family: Georgia, "Times New Roman", Times, serif;
  margin-top: 70px;
  overflow-y: auto; /* Added to enable scrolling */
`;

const Logo2 = styled.img`
  width: 200px; /* Adjust size as needed */
  margin-bottom: 20px;
  position: absolute;
  top: 70px;
  z-index: 0;
`;

// const Logo = styled.img`
//   width: 190px; /* Adjust size as needed */
//   margin-bottom: 20px;
//   position: absolute;
//   top: 250px;
//   right: 50;
//   z-index: 3;
// `;

const Description = styled.p`
  margin-top: 20px;
  font-size: 16px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
`;

const DownloadButton = styled.button`
  z-index: 500;
  align-items: center;
  background-clip: padding-box;
  background-color: initial;
  background-image: none;
  border-style: none;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  flex-direction: row;
  flex-shrink: 0;
  font-family: Eina01, sans-serif;
  font-size: 16px;
  font-weight: 800;
  justify-content: center;
  line-height: 24px;
  margin: 0;
  min-height: 64px;
  outline: none;
  overflow: visible;
  padding: 19px 26px;
  margin-bottom: 15px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  vertical-align: middle;
  width: auto;
  word-break: keep-all;

  @media (min-width: 768px) {
    padding: 19px 32px;
  }

  &::before,
  &::after {
    border-radius: 80px;
  }

  &::before {
    background-image: linear-gradient(92.83deg, #e8b10d 0, #f7c100 100%);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -2;
  }

  &::after {
    background-color: initial;
    background-image: linear-gradient(#541a0f 0, #0c0d0d 100%);
    bottom: 4px;
    content: "";
    display: block;
    left: 4px;
    overflow: hidden;
    position: absolute;
    right: 4px;
    top: 4px;
    transition: all 100ms ease-out;
    z-index: -1;
  }

  &:hover:not(:disabled)::before {
    background: linear-gradient(
      92.83deg,
      rgb(255, 116, 38) 0%,
      rgb(249, 58, 19) 100%
    );
  }

  &:hover:not(:disabled)::after {
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    transition-timing-function: ease-in;
    opacity: 0;
  }

  &:active:not(:disabled) {
    color: #ccc;
  }

  &:disabled {
    cursor: default;
    opacity: 0.24;
  }
`;

const TermsLink = styled.span`
  font-size: 12px;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
  margin-top: 30px;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 800px;
  max-height: 80%;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const CloseButton = styled.button`
  background-color: transparent;
  color: #333;
  border: none;
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;

  &:hover {
    color: #ff5c5c;
  }
`;

const ModalTitle = styled.h2`
  margin-top: 0;
  margin-bottom: 15px;
  font-size: 24px;
  color: #333;
`;

const ModalParagraph = styled.p`
  margin-bottom: 15px;
  line-height: 1.6;
  color: #666;
`;

const List = styled.ul`
  margin-bottom: 15px;
  padding-left: 20px;
  color: #666;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;
const Title = styled.h2`
  margin-top: 60px;
`;

const LauncherDownload: React.FC = () => {
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [showTermsModal, setShowTermsModal] = useState(false);

  const handleDownload = () => {
    console.log("Download button clicked");
    window.location.href = "https://ss-rp.com/static/launcher/SunsetLauncher.rar"; 
  };
  
  const togglePrivacyModal = () => {
    setShowPrivacyModal(!showPrivacyModal);
  };

  const toggleTermsModal = () => {
    setShowTermsModal(!showTermsModal);
  };

  const handleOverlayClick = (
    e: React.MouseEvent<HTMLDivElement>,
    toggleModal: () => void
  ) => {
    if (e.target === e.currentTarget) {
      toggleModal();
    }
  };

  return (
    <LauncherDownloadContainer>
      {/* <Logo src="./watchdog.png" alt="Watchdog Logo" /> */}
      <Title>WATCHDOG CLIENT-SIDE ANTICHEAT</Title>
      <Description>
        Download our anti-cheat launcher to ensure a fair and secure gaming
        experience.
      </Description>
      <Logo2 src="./watchdog.png" alt="Watchdog Logo" />
      <DownloadButton onClick={handleDownload}>Download Latest</DownloadButton>
      <p>Version v3.201.34</p>
      <div>   <TermsLink onClick={togglePrivacyModal}>View Privacy Policy</TermsLink>
      <TermsLink onClick={toggleTermsModal} style={{ marginLeft: "10px" }}>
        View Terms of Use
      </TermsLink> </div>
   
      {showPrivacyModal && (
        <ModalOverlay
          onClick={(e) => handleOverlayClick(e, togglePrivacyModal)}
        >
          <ModalContent>
            <CloseButton onClick={togglePrivacyModal}>&times;</CloseButton>
            <ModalTitle>Privacy Policy</ModalTitle>
            <ModalParagraph>
              Welcome to Sunset Network / Sunset Roleplay ("Sunset," "we,"
              "our," or "us"). This Privacy Policy explains how we collect, use,
              disclose, and safeguard your information when you visit our
              website ss-rp.com or use our gaming community and client-side
              anti-cheat services (collectively, the "Services"). Please read
              this policy carefully to understand our practices regarding your
              personal data and how we will treat it.
            </ModalParagraph>
            <ModalParagraph>
              <strong>Information We Collect</strong>
            </ModalParagraph>
            <ModalParagraph>
              <strong>Information You Provide:</strong>
            </ModalParagraph>
            <ModalParagraph>
              In-Game: When you participate in our games, we collect your
              username, IP address, and securely encrypted password.
            </ModalParagraph>
            <ModalParagraph>
              Forums: When you register on our forums, we collect your username,
              email address, and IP address.
            </ModalParagraph>
            <ModalParagraph>
              Launcher: Our client-side anti-cheat launcher collects your
              username, password (same as in-game), IP address, Hardware ID
              (HWID), and other anti-cheat related data such as file/process
              scanning and screenshots within the game environment.
            </ModalParagraph>
            <ModalParagraph>
              <strong>How We Use Your Information</strong>
            </ModalParagraph>
            <ModalParagraph>
              We use the information we collect for the following purposes:
            </ModalParagraph>
            <List>
              <ListItem>To manage and maintain user accounts.</ListItem>
              <ListItem>
                To enhance security and integrity within our gaming community.
              </ListItem>
              <ListItem>To prevent cheating and ensure fair play.</ListItem>
              <ListItem>
                To communicate with you regarding your account and updates to
                our Services.
              </ListItem>
            </List>
            <ModalParagraph>
              <strong>How We Share Your Information</strong>
            </ModalParagraph>
            <List>
              <ListItem>
                We do not sell or rent your personal information to third
                parties.
              </ListItem>
              <ListItem>
                We may share your information with trusted partners who assist
                us in operating our Services, conducting our business, or
                serving our users, so long as those parties agree to keep this
                information confidential.
              </ListItem>
              <ListItem>
                We may also release your information when we believe release is
                appropriate to comply with the law, enforce our site policies,
                or protect ours or others' rights, property, or safety.
              </ListItem>
            </List>
            <ModalParagraph>
              <strong>Security of Your Information</strong>
            </ModalParagraph>
            <ModalParagraph>
              We use administrative, technical, and physical security measures
              to help protect your personal information. While we have taken
              reasonable steps to secure the personal information you provide to
              us, please be aware that despite our efforts, no security measures
              are perfect or impenetrable, and no method of data transmission
              can be guaranteed against any interception or other type of
              misuse.
            </ModalParagraph>
            <ModalParagraph>
              <strong>Changes to This Privacy Policy</strong>
            </ModalParagraph>
            <ModalParagraph>
              We may update this Privacy Policy from time to time. We will
              notify you of any changes by posting the new Privacy Policy on
              this page. You are advised to review this Privacy Policy
              periodically for any changes.
            </ModalParagraph>
            <ModalParagraph>
              <strong>Contact Us</strong>
            </ModalParagraph>
            <ModalParagraph>
              If you have any questions about this Privacy Policy, please
              contact us at Discord: proxuzz & duplon.
            </ModalParagraph>
          </ModalContent>
        </ModalOverlay>
      )}
      {showTermsModal && (
        <ModalOverlay onClick={(e) => handleOverlayClick(e, toggleTermsModal)}>
          <ModalContent>
            <CloseButton onClick={toggleTermsModal}>&times;</CloseButton>
            <ModalTitle>Terms of Use</ModalTitle>
            <ModalParagraph>
              Welcome to Sunset Network / Sunset Roleplay ("Sunset," "we,"
              "our," or "us"). By accessing or using our website (ss-rp.com),
              gameserver for GTA San Andreas Multiplayer (SA-MP), and our
              client-side anti-cheat launcher (collectively, the "Services"),
              you agree to comply with and be bound by these Terms of Use
              ("Terms"). Please read these Terms carefully before using our
              Services. If you do not agree with these Terms, please do not use
              our Services.
            </ModalParagraph>
            <ModalParagraph>
              <strong>1. Acceptance of Terms</strong>
            </ModalParagraph>
            <ModalParagraph>
              By using our Services, you agree to be bound by these Terms, our
              Privacy Policy, and any additional terms and conditions that are
              referenced herein or that otherwise may apply to specific areas of
              our Services.
            </ModalParagraph>
            <ModalParagraph>
              <strong>2. Eligibility</strong>
            </ModalParagraph>
            <ModalParagraph>
              You must be at least 13 years of age to use our Services. By using
              our Services, you represent and warrant that you meet this age
              requirement.
            </ModalParagraph>
            <ModalParagraph>
              <strong>3. Account Registration</strong>
            </ModalParagraph>
            <ModalParagraph>
              To access certain features of our Services, you may be required to
              register an account. You agree to:
            </ModalParagraph>
            <List>
              <ListItem>
                Provide accurate, current, and complete information during the
                registration process.
              </ListItem>
              <ListItem>
                Maintain and promptly update your account information to keep it
                accurate, current, and complete.
              </ListItem>
              <ListItem>
                Maintain the security of your password and accept all risks of
                unauthorized access to your account.
              </ListItem>
              <ListItem>
                Notify us immediately if you suspect any unauthorized use of
                your account.
              </ListItem>
            </List>
            <ModalParagraph>
              <strong>4. Use of Services</strong>
            </ModalParagraph>
            <ModalParagraph>
              You agree to use our Services only for lawful purposes and in
              accordance with these Terms. You agree not to:
            </ModalParagraph>
            <List>
              <ListItem>
                Use our Services in any manner that could disable, overburden,
                damage, or impair the Services or interfere with any other
                party's use of the Services.
              </ListItem>
              <ListItem>
                Use any robot, spider, or other automatic device, process, or
                means to access the Services for any purpose, including
                monitoring or copying any of the material on the Services.
              </ListItem>
              <ListItem>
                Use any manual process to monitor or copy any of the material on
                the Services or for any other unauthorized purpose without our
                prior written consent.
              </ListItem>
              <ListItem>
                Use any device, software, or routine that interferes with the
                proper working of the Services.
              </ListItem>
              <ListItem>
                Introduce any viruses, trojan horses, worms, logic bombs, or
                other material that is malicious or technologically harmful.
              </ListItem>
              <ListItem>
                Attempt to gain unauthorized access to, interfere with, damage,
                or disrupt any parts of the Services, the server on which the
                Services are stored, or any server, computer, or database
                connected to the Services.
              </ListItem>
              <ListItem>
                Attack the Services via a denial-of-service attack or a
                distributed denial-of-service attack.
              </ListItem>
              <ListItem>
                Otherwise attempt to interfere with the proper working of the
                Services.
              </ListItem>
            </List>
            <ModalParagraph>
              <strong>5. Anti-Cheat Policy</strong>
            </ModalParagraph>
            <ModalParagraph>
              Our client-side anti-cheat launcher is designed to prevent
              cheating and ensure fair play. By using our launcher, you agree
              to:
            </ModalParagraph>
            <List>
              <ListItem>
                Allow the launcher to collect data necessary for anti-cheat
                purposes, including file/process scanning and in-game
                screenshots.
              </ListItem>
              <ListItem>
                Refrain from attempting to bypass or interfere with the
                anti-cheat measures.
              </ListItem>
              <ListItem>
                Accept that any attempt to cheat or manipulate the game will
                result in immediate suspension or termination of your account.
              </ListItem>
            </List>
            <ModalParagraph>
              <strong>6. Intellectual Property</strong>
            </ModalParagraph>
            <ModalParagraph>
              The Services and their entire contents, features, and
              functionality are owned by Sunset, its licensors, or other
              providers of such material and are protected by international
              copyright, trademark, patent, trade secret, and other intellectual
              property or proprietary rights laws.
            </ModalParagraph>
            <ModalParagraph>
              <strong>7. User Contributions</strong>
            </ModalParagraph>
            <ModalParagraph>
              You may be permitted to submit, upload, publish, or otherwise make
              available content through our Services ("User Contributions"). You
              retain ownership of your User Contributions, but by making any
              User Contributions available through the Services, you grant
              Sunset a worldwide, non-exclusive, royalty-free, sublicensable,
              and transferable license to use, reproduce, distribute, prepare
              derivative works of, display, and perform the User Contributions
              in connection with the Services and Sunset's business.
            </ModalParagraph>
            <ModalParagraph>
              <strong>8. Termination</strong>
            </ModalParagraph>
            <ModalParagraph>
              We may terminate or suspend your access to all or part of our
              Services, without prior notice or liability, for any reason,
              including if you breach these Terms. Upon termination, your right
              to use the Services will immediately cease.
            </ModalParagraph>
            <ModalParagraph>
              <strong>9. Limitation of Liability</strong>
            </ModalParagraph>
            <ModalParagraph>
              To the fullest extent permitted by law, in no event shall Sunset,
              its affiliates, directors, employees, or licensors be liable for
              any indirect, incidental, special, consequential, or punitive
              damages, or any loss of profits or revenues, whether incurred
              directly or indirectly, or any loss of data, use, goodwill, or
              other intangible losses, resulting from (a) your use or inability
              to use the Services; (b) any unauthorized access to or use of our
              servers and/or any personal information stored therein; (c) any
              interruption or cessation of transmission to or from the Services;
              (d) any bugs, viruses, trojan horses, or the like that may be
              transmitted to or through our Services by any third party; (e) any
              errors or omissions in any content or for any loss or damage
              incurred as a result of your use of any content posted, emailed,
              transmitted, or otherwise made available through the Services;
              and/or (f) the defamatory, offensive, or illegal conduct of any
              third party.
            </ModalParagraph>
            <ModalParagraph>
              <strong>10. Governing Law</strong>
            </ModalParagraph>
            <ModalParagraph>
              These Terms shall be governed and construed in accordance with the
              laws of United Kingdom, without regard to its conflict of law
              provisions.
            </ModalParagraph>
            <ModalParagraph>
              <strong>11. Changes to Terms</strong>
            </ModalParagraph>
            <ModalParagraph>
              We reserve the right, at our sole discretion, to modify or replace
              these Terms at any time. If a revision is material, we will
              provide at least 30 days' notice prior to any new terms taking
              effect. What constitutes a material change will be determined at
              our sole discretion.
            </ModalParagraph>
            <ModalParagraph>
              <strong>12. Contact Us</strong>
            </ModalParagraph>
            <ModalParagraph>
              If you have any questions about these Terms, please contact us at discord: proxuzz & duplon.
            </ModalParagraph>
          </ModalContent>
        </ModalOverlay>
      )}
    </LauncherDownloadContainer>
  );
};

export default LauncherDownload;

