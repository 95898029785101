
import { useContext, useEffect, useState } from "react";
import { FactionCharacter, User } from "./User";
import { UserContext } from "./UserContext";
import { SunsetAPIContext } from "../Networking/ApiProvider";

export interface UserProviderProps {
    children: React.ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | undefined>();
    const api = useContext(SunsetAPIContext)
    const [isLoading, setIsLoading] = useState(true);
    const refetchUser = () => {
        fetchCurrentUser();
    };
    const fetchCurrentUser = async () => {
        setIsLoading(true);
        const response = await api!.getCurrentUser();

        if (!response.success) {
            setIsLoading(false);
            return;
        }

        let userFactions: FactionCharacter[] | null = null;

        const factionResponse = await api!.getUserFactions();
        if (factionResponse.success) {
            userFactions = factionResponse.data!;
        }

        const completeUser: User = {
            ...response.data!,
            factions: userFactions,
        };

        setUser(completeUser);


        setIsLoading(false);

    };
    useEffect(() => {
        try {
            fetchCurrentUser();
        } catch (error) {
            // Handle the error here
            console.error(`User probably not logged in. Error: ${error}`);
        }
    }, [api]);

    return (
        <UserContext.Provider value={{ user, setUser, isLoading, refetchUser }}>
            {children}
        </UserContext.Provider>
    );

};

