import React, { useState, useEffect } from "react";
import "./Logs.css";
import { ToastType } from "../../Components/Toast/Toast";
import { useToast } from "../../Components/Toast/ToastContext";
import Button from "../../ui/Button";
// Removed unnecessary import

export interface APIResponse<T> {
  success: boolean;
  code: number;
  data?: T;
  error?: string;
}

export interface Log {
  logId: number;
  subcategory: string;
  log: string;
  logTimestamp: string;
}

interface LogViewProps {
  response?: APIResponse<Log[]>;
}

const formatLog = (log: Log) => {
  const formattedTimestamp = `[${new Date(log.logTimestamp).toLocaleString()}]`;
  return `${formattedTimestamp} ${log.log}`;
};

const LogView: React.FC<LogViewProps> = ({ response }) => {
  const { showToast } = useToast();
  const [isCopied, setIsCopied] = useState(false);
  const [logText, setLogText] = useState("");

  const handleCopyClick = () => {
    if (logText) {
      navigator.clipboard
        .writeText(logText)
        .then(() => {
          showToast(ToastType.Success, "Log content copied to clipboard", 5);
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 1500);
        })
        .catch((error) => {
          showToast(
            ToastType.Error,
            "There was an issue copying the log content..",
            5
          );
        });
    }
  };

  useEffect(() => {
    if (response && response.success) {
      const logs = response.data || [];
      const logContent = logs.map(formatLog);
      setLogText(logContent.join("\n"));
    }
  }, [response]);

  if (!response) {
    return null;
  }

  if (!response.success) {
    return (
      <div className="error-card log-view">
        {response.error || "Error fetching logs."}
      </div>
    );
  }

  const logs = response.data || [];

  return (
    <div className="log-view">
      <div className="log-count">
        Total Logs: {logs.length}
        <Button onClick={handleCopyClick}>
          {" "}
          {isCopied ? "Copied!" : "Copy"}
        </Button>
      </div>
      <div className="log-list">
        {logs.map((log) => (
          <div key={log.logId} className="log-item">
            <div className="log-subcategory">{log.subcategory}</div>
            <div className="log-timestamp">
              {formatLog(log)} <div className="log-id">ID: {log.logId}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LogView;
