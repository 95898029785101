import React from 'react';
import { Link } from 'react-router-dom';
import styles from './AccountDisplayCard.module.css';

interface AccountDisplayCardProps {
    pfp: string;
    name: string;
    description: string;
    rank: string;
    rankColor: string;
    accountID: string;
    account: string;
}

export const AccountDisplayCard: React.FC<AccountDisplayCardProps> = ({
    pfp,
    name,
    description,
    rank,
    rankColor,
    accountID,
    account
}) => {
    const profileLink = `/account/${accountID}/${account}`;

    return (
        <Link to={profileLink} className={styles.card}>
            <div className={styles.imageContainer}>
                <img src={`/pfps/${pfp}`} alt={`${name}'s profile`} className={styles.profileImage} />
            </div>
            <div className={styles.infoContainer}>
                <div className={styles.nameRank}>
                    <h3 className={styles.name}>{name}</h3>
                    {rank && (
                        <div className={styles.rank}>
                            <span style={{ color: rankColor }}>{rank}</span>
                        </div>
                    )}
                </div>
                <p className={styles.description}>{description}</p>
            </div>
        </Link>
    );
};
