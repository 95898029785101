import "./BlackCard.css"
interface BlackCardProps {
    subtitle: string;
    text: string;
}

export const BlackCard: React.FC<BlackCardProps> = ({ subtitle, text }) => {
    return (
        <div className="black-card">
            <h2 className="blac-card-subtitle">{subtitle}</h2>
            <p className="blac-card-text">{text}</p>
        </div>
    );
}