import { useContext, useEffect, useState } from 'react';
import { CrateOpening, CrateReward } from '../../Networking/Interface/Gamba/GambaInterface';
import CrateAnimation from './CrateAnimation';
import styles from './CrateViewer.module.css';
import RewardClaimView from './RewardClaimView';
import { Helmet } from 'react-helmet';
import { useToast } from '../../Components/Toast/ToastContext';
import { ToastType } from '../../Components/Toast/Toast';
import { SunsetAPIContext } from '../../Networking/ApiProvider';
interface CrateOpenerProps {
    crateID: number,
    onCrateOpened: () => void
}
export const CrateOpener: React.FC<CrateOpenerProps> = ({ crateID, onCrateOpened }) => {

    const [wonReward, setWonReward] = useState<CrateOpening>();
    const [scrollRewards, setScrollRewards] = useState<CrateReward[]>([]);

    const [showImage, setShowImage] = useState<boolean>(true);
    const [showAnimation, setShowAnimation] = useState<boolean>(false);
    const [showReward, setShowReward] = useState<boolean>(false);
    const [userCrates, setUserCrates] = useState<{ [key: number]: number }>({});
    const api = useContext(SunsetAPIContext);
    const { showToast } = useToast();
    useEffect(() => {
        const fetchData = async () => {
            const cratesResponse = await api!.getCrates();
            if (cratesResponse.success) {
                setUserCrates(cratesResponse.data!);
            }
        }
        fetchData();
    },[crateID,api])

    const fetchOpeningRewards = async () => {
        const winResponse = await api!.openCrate(crateID);
        const scrollResponse = await api!.getScrollRewards(crateID, 50);
        if (winResponse.success && scrollResponse.success) {
            setWonReward(winResponse.data!);
            setScrollRewards(scrollResponse.data!);
        }
    }

    const openCrate = async () => {
        try {
            setShowReward(false);
            const currentCrateCount = userCrates[Number(crateID)] || 0;

            if (currentCrateCount > 0) {
                setShowImage(false);  // Hide the image
                setUserCrates(prevCrates => {
                    const updatedCrates = { ...prevCrates };
                    updatedCrates[Number(crateID)] -= 1;
                    return updatedCrates;
                });
                await fetchOpeningRewards();  // Fetch the rewards


                setTimeout(() => {
                    setShowAnimation(true);  // Show the CrateAnimation after 2s
                }, 2000);
                setTimeout(() => {
                    setShowAnimation(false);
                    setShowImage(true);  // Reset states for next time
                    setShowReward(true);
                    onCrateOpened();
                }, 13000);
            } else {
                // Handle the scenario where the user doesn't have crates
                // E.g., show a notification or a message
                showToast(ToastType.Error, "Erm, you don't have this type of crate in your inventory!", 10); // this should never execute, since the button is now properly disabled - foksha
            }
        }
        catch {
            showToast(ToastType.Error, "Crates are currently not functioning. Try again later", 10);
        }
        
    };
    return (<div className={styles.crateContainer}>
        <Helmet>
            <title>Crate Opener - Exciting Crate Opening Experience</title>
            <meta name="description" content="Unleash the thrill of opening mystery crates with our Crate Opener! Each crate, unique in contents, awaits your discovery. Will you win the rarest rewards?" />
            <meta property="og:title" content="Crate Opener - Exciting Crate Opening Experience" />
            <meta property="og:description" content="Unleash the thrill of opening mystery crates with our Crate Opener! Each crate, unique in contents, awaits your discovery. Will you win the rarest rewards?" />
            <meta property="og:image" content={`http://ss-rp.com/public/gamba/${crateID}.png`} />
            <meta property="og:url" content={`http://ss-rpn.com/crates/${crateID}`} />
            <meta property="og:type" content="website" />
            <meta property="og:site_name" content="SSRP" />
        </Helmet>
        <img
            src={`${process.env.PUBLIC_URL}/gamba/${crateID}.png`}
            alt="Crate Image"
            className={showImage ? styles.crateImage : `${styles.crateImage} ${styles.fadeOut}`}
        />
        {showAnimation && <CrateAnimation possibleRewards={scrollRewards} wonReward={wonReward!.crateRewardWon} />}
        <button className={styles.crateBtn} onClick={openCrate} disabled={userCrates[Number(crateID)] === undefined || userCrates[Number(crateID)] === 0 || showAnimation || !showImage}>Open Crate</button>
        <div className={styles.crateCount}>
            {userCrates[Number(crateID)] || 0} crates available
        </div>
        
        {showReward && wonReward && <RewardClaimView wonItem={wonReward} />}




    </div>)
}