import React, { useContext, useEffect, useState } from 'react';
import { SunsetAPIContext } from '../../Networking/ApiProvider';
import { Faction } from '../../Networking/Interface/Faction/FactionInterface';
import { FactionListView } from './FactionListView';
import { FactionSearch } from './FactionsSearch';
import './FactionsDashboard.css';
import { TabPane, Tabs } from '../../Components/Tabs';
import { FactionPostsDashboard } from './Posts/FactionPostsDashboard';
import { CreatePostButton } from './Posts/CreatePostButton';
import { MyFactions } from './MyFactions';
import { useNavigate } from 'react-router-dom';
import { SunsetAPIFactionContext } from '../../Networking/Interface/Faction/FactionApiProvider';

interface FactionDashboardProps {
    defaultTabKey: "factions" | "recentPosts" | "myFaction";
}

export const FactionDashboard: React.FC<FactionDashboardProps> = ({defaultTabKey}) => {
    const [factions, setFactions] = useState<Faction[]>([]);
    const api = useContext(SunsetAPIFactionContext);
    const navigate = useNavigate();
    useEffect(() => {
        const getFactions = async () => {
            let response = await api!.getFactions();
            if (response.success) {
                setFactions(response.data!);
            }
        };
        getFactions();
    }, [api]);
    const handleSelectFactionTag = (tag: string) => {
        navigate(`/faction/${tag}`);
    };
    return (
        <div className="card dashboard-container">
            <Tabs defaultActiveKey={defaultTabKey}>
                <TabPane style={{width: "95%"}} tabKey="factions" tabTitle="Factions">
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center" }}>
                           <FactionSearch onSelectFactionTag={handleSelectFactionTag} />
                    </div>
                 
                    <FactionListView factions={factions}></FactionListView>
                </TabPane>
                <TabPane style={{width: "90%"}} tabKey="myFaction" tabTitle="My Factions">
                    <MyFactions factions={factions}></MyFactions>
                </TabPane>
            </Tabs>
        </div>
    );
};
