import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ActiveProcesses from "./GetActiveProcess";
import TakeScreenshot from "./GetScreenshot";
import GetDirectory from "./GetDirectory";
import {
  OptionsIcon,
  OptionsMenu,
  StyledHeader,
  StyledContainer,
  StyledBox,
  StlyedMain
} from "./StyledComponents";
import SessionTimelineModalee from "./ViewSessionTimeline";
import { Tabs, TabPane } from "../Components/Tabs";
import GetHotkey from "./GetHotkeys";
import { Button } from "antd";

interface sessionData {
  active: number;
  active_processes: string;
  end: string;
  forced: number;
  gpci: string;
  injected_dlls: string;
  ip: string;
  library_results: string;
  linked_session_id: number;
  samp_hash: string;
  session_id: number;
  socket_alive: number;
  timestamp: string;
  user_id: number;
  username: string;
}

const SessionPage: React.FC = () => {
  const [fetchedData, setFetchedData] = useState<sessionData | null>(null);
  const [timestamp, setTimestamp] = useState<string | null>(null);
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [userId, setUserId] = useState<number | undefined>();
  const [username, setUsername] = useState<string | undefined>();
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const optionsRef = useRef<HTMLDivElement>(null);

  const displayYesNo = (value: number) => (value === 1 ? "Yes" : "No");

  function handleClick() {
    navigate(-1);
  }

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target as Node)
      ) {
        setShowOptions(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleOptions = () => {
    setShowOptions((prev) => !prev);
  };


  const fetchData = async () => {
    try {
      const response = await fetch(
        `https://ss-rp.com/exp-api/launcher/session/${id}`,
        {
          credentials: "include",
        }
      );
      const result = await response.json();

      setFetchedData(result.launcherSessionInfo);
      setUserId(result.launcherSessionInfo.user_id);
      setUsername(result.launcherSessionInfo.username)
      setTimestamp(result.launcherSessionInfo.timestamp); 
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  return (
    <StyledContainer>
      <Tabs>
        <TabPane tabKey="0" tabTitle="Overview">
          {fetchedData ? (
            <StyledBox>
              <StyledHeader>
                Username: {fetchedData.username} | User ID:{" "}
                {fetchedData.user_id}
              </StyledHeader>
              <StlyedMain>
                <p>IP: {fetchedData.ip}</p>
                <p>GPCI: {fetchedData.gpci}</p>
                <p>Username: {fetchedData.username}</p>
                <p>User ID: {fetchedData.user_id}</p>
                <p>Forced: {displayYesNo(fetchedData.forced)}</p>
                <p>Socket Alive: {displayYesNo(fetchedData.socket_alive)}</p>
                <p>SAMP hash: {fetchedData.samp_hash}</p>
                <p>Session ID: {fetchedData.session_id}</p>
                <p>Timestamp: {fetchedData.timestamp}</p>
                <p>Active: {displayYesNo(fetchedData.active)}</p>
                <p>Active Processes: {fetchedData.active_processes}</p>
                <p>End: {fetchedData.end}</p>
                <p>Injected Dlls: {fetchedData.injected_dlls}</p>
                <p>Library Results: {fetchedData.library_results}</p>
                <p>Linked Session ID: {fetchedData.linked_session_id}</p>
              </StlyedMain>
            </StyledBox>
          ) : (
            <p>No data available</p>
          )}
        </TabPane>
        <TabPane tabKey="1" tabTitle="Timeline">
          {fetchedData && (
            <StyledBox>
              <SessionTimelineModalee
                userId = {userId || 0}
                userName = {username || '' }
                sessionId={id || ""}
                timestamp={timestamp || ""}
              />
            </StyledBox>
          )}
        </TabPane>
      </Tabs>
      <OptionsIcon onClick={toggleOptions}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M12 5c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z" />
        </svg>
      </OptionsIcon>
      <OptionsMenu ref={optionsRef} show={showOptions}>
        <TakeScreenshot id={userId || 0} />

        <ActiveProcesses userId={userId || 0} />

        <GetDirectory userId={userId || 0} />

        <GetHotkey userId={userId || 0} />
      </OptionsMenu>
      <Button style={{marginBottom: "10px"}} onClick={handleClick}>Go back</Button>
    </StyledContainer>
  );
};

export default SessionPage;
