import React, { useContext, useState, useEffect, useCallback } from "react";
import Button from "../../ui/Button";
import { ButtonProps } from "../../ui/ButtonProps";
import { SunsetAPIAccountContext } from "../../Networking/Interface/Account/AccountAPIProvider";
import { UserRole } from "../../Utils/enums";
import { SunsetAPIContext } from "../../Networking/ApiProvider";
import { useToast } from "../../Components/Toast/ToastContext";
import { ToastType } from "../../Components/Toast/Toast";
import { ExpungeModal } from "../../Components/ExpungeModal";

interface ExpungePunishmentProps {
  punishmentId: number;
  onViewReason: (reason: string) => void;
}

const ExpungePunishment: React.FC<ExpungePunishmentProps> = ({
  punishmentId,
  onViewReason,
}) => {
  const [isReasonModalVisible, setReasonModalVisible] = useState(false);
  const [reasonText, setReasonText] = useState("");
  const [isExpunging, setIsExpunging] = useState(false);
  const [userRole, setUserRole] = useState<UserRole | null>(null);

  const api = useContext(SunsetAPIContext);
  const accountApi = useContext(SunsetAPIAccountContext);
  const { showToast } = useToast();

  const fetchCurrentUser = useCallback(async () => {
    try {
      const res = await api!.getCurrentUser();
      setUserRole(res.data?.role ?? null);
    } catch (error) {}
  }, [api]);

  useEffect(() => {
    if (userRole === null) {
      fetchCurrentUser();
    }
  }, [fetchCurrentUser, userRole]);

  const isAdmin = userRole !== null && userRole > UserRole.Mod;

  const openReasonModal = () => {
    onViewReason(reasonText);
    setReasonModalVisible(true);
  };

  const closeReasonModal = () => {
    setReasonModalVisible(false);
    setReasonText("");
  };
  const handleExpunge = () => {
    openReasonModal();
  };

  const handleExpungeReason = async () => {
    setIsExpunging(true);

    const reason = reasonText;
    const expungeResponse = await accountApi!.expungePunishment(
      punishmentId,
      reason
    );
    if (expungeResponse.success) {
      showToast(ToastType.Success, "Punishment expunged successfully", 5);
      setIsExpunging(false);
      closeReasonModal();
      onViewReason(reason);
    } else {
      showToast(ToastType.Error, "Punishment expunge failed", 5);
    }
  };

  const buttonProps: ButtonProps = {
    size: "small",
    variation: "danger",
    disabled: isExpunging,
    onClick: handleExpunge,
  };

  return (
    <>
      {isAdmin && <Button {...buttonProps}>Expunge</Button>}

      {isReasonModalVisible && (
        <ExpungeModal onClose={closeReasonModal}>
          <div>
            <h2>Expunge Reason</h2>
            <textarea
              value={reasonText}
              onChange={(e) => setReasonText(e.target.value)}
              placeholder="Enter the reason for expunging..."
              required
            />
            <Button onClick={handleExpungeReason}>Submit</Button>
            <Button onClick={closeReasonModal}>Cancel</Button>
          </div>
        </ExpungeModal>
      )}
    </>
  );
};

export default ExpungePunishment;
