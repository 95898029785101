import { useContext, useEffect, useState } from "react";
import FactionPlaytimeGraph from "./FactionPlaytimeGraph";
import FactionPlaytimePieChart from "./FactionPlaytimePieChart";
import "./FactionGraphs.css";
import Card from "../../../Components/Card";
import { BlackCard } from "../../../Components/BlackCard";
import { TabPane, Tabs } from "../../../Components/Tabs";
import { FactionPunishmentChart } from "./FactionPunishmentChart";
import { Oval } from "react-loader-spinner";
import { SunsetAPIFactionContext } from "../../../Networking/Interface/Faction/FactionApiProvider";
import { AccountSessionFactionDTO } from "../../../Networking/Interface/Faction/FactionInterface";
import React from "react";
interface FactionManagementViewProps {
    tag: string;
}


export const FactionManagementView: React.FC<FactionManagementViewProps> = ({ tag }) => {
    const [sessions, setSessions] = useState<AccountSessionFactionDTO[] | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const api = useContext(SunsetAPIFactionContext);
    useEffect(() => {
        const fetchSessions = async () => {
            try {
                // Replace with your API endpoint
                const response = await api?.getFactionActivity(tag);
                if (!!response && response.success){
                    setSessions(response.data!);
                }
              
            } catch (e) {
                setError('Error fetching data');
                console.error(e);
            } finally {
                setLoading(false);
            }
        };

        fetchSessions();
    }, [tag, api]);

    if (loading) return <p className="loading">
        <Oval
    height={80}
    width={80}
    color="#843"
    wrapperStyle={{}}
    wrapperClass=""
    visible={true}
    ariaLabel='oval-loading'
    secondaryColor="#843"
    strokeWidth={2}
    strokeWidthSecondary={2}
  
  />
  </p>;
    if (error) return <p className="error">{error}</p>;
    if (!sessions) return <p>No data available</p>;

    return (
        <Tabs defaultActiveKey="1">
            <TabPane tabKey="1" tabTitle="Punishments">
                <FactionPunishmentChart tag={tag }></FactionPunishmentChart>
            </TabPane>
            
            <TabPane tabKey="3" tabTitle="Activity">
                
                <div className="factionManagementView">
                        <BlackCard subtitle="Weekly activity" text="Barcharts the weekly activity. If there are too many members, it displays the rest as others."></BlackCard>
                        <FactionPlaytimeGraph sessions={sessions} />
                        <BlackCard subtitle="Most active members" text="Represents most active faction members in proportion to total faction activity."></BlackCard>
                        <FactionPlaytimePieChart sessions={sessions} />
                        <Card subtitle="Note:" text="These stats are not a full represenation of faction activity. Name changes delete all previous activity."></Card>
                    </div>
                
            </TabPane>
            
        </Tabs>
        
    );
};