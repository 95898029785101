import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styles from './Toast.module.css';

// Toast types enum
export enum ToastType {
    Error = 'Error',
    Warning = 'Warning',
    Information = 'Information',
    Success = 'Success',
}

// Props interface
interface ToastProps {
    type: ToastType;
    duration: number;
    message: string
}

// Toast component
const Toast: React.FC<ToastProps> = ({ type, duration, message}) => {
    const [isVisible, setIsVisible] = useState(true);

    const handleClose = () => {
        setIsVisible(false);
    };


    const progressBarAnimation = {
        animationDuration: `${duration}s`,
    };

    return (
        isVisible ? ReactDOM.createPortal(
            <div className={`${styles.toast} ${styles[type.toLowerCase()]}`}>
                <div className={styles.content}>
                    <span className={styles.message}>{message}</span>
                    <button onClick={handleClose} className={styles.closeButton}>X</button>
                </div>
                <div className={`${styles.progressBar} ${styles[type.toLowerCase()]}`} style={progressBarAnimation}></div>
            </div>,
            document.body
        ) : null
    );
};
export default Toast;
