
export interface Faction {
  id: number;
  name: string;
  tag: string;
  type: FactionType;
  status: number;
  linkedJobId: number;
  color: number;
  points: number;
  skinId: number;
  created: Date;
  alliance?: number;
  specialisation?: number;
  changedSkinTimestamp?: number;
  level?: number;
  profileURL?: string | null;
  profileURLDateChanged?: Date | null;
  bannerURL?: string | null;
  bannerURLDateChanged?: Date | null;
  slogan?: string | null;
}
export enum FactionType {
  business,
  criminal,
  goverment
}
export interface FactionRank {
  id: number;
  factionId: number;
  rankName: string;
  rankLevel: number;
  defaultRank: number;
  permissions: string;
  jobPermissions: string;
  basePaycheck?: number;
}

export interface FactionAlliance {
  Id: number;
  Name?: string;
  AllianceLeaderId?: number;
}

export interface FactionMember {
  characterName: string;
  skinID: number;
  username: string;
  userID: number;
  rankID: number;
  lastSessionStart: Date;
  lastSessionEnd: Date;
}
export interface FactionPost {
  id: number;
  postingUserId: number;
  postingCharacterName: string;
  postingSkinId: number;
  postingUserUsername: string;
  factionTag: string;
  text: string;
  title: string;
  location: string;
  isPinned: boolean;
  postTime: Date;
  factionPostMedia: FactionPostMedia[];
}
export interface FactionPostMedia {
  id: number;
  factionPostId: number;
  mediaTypeId: number;
  url: string;
}
export interface FactionPostRequest {
  factionTag: string;
  text: string;
  title: string;
  location: string;
  isPinned: boolean;
  images?: FileList;  
  urls?: string[];
}
export interface AccountSessionFactionDTO {
    userId: number;
    sessionId: number;
    username?: string;
    start: Date;
    end: Date;
}