import React, { useState, useContext, useEffect, useRef } from "react";
import styled from "styled-components";
import { SunsetAPIAccountContext } from "../../Networking/Interface/Account/AccountAPIProvider";
import { ToastType } from "../../Components/Toast/Toast";
import { useToast } from "../../Components/Toast/ToastContext";
import { Button, Input } from "antd";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const InputBox = styled(Input)`
  display: flex;
  align-items: center;
  margin-left: 15px;
  width: 150px; // Adjust width as needed
  margin-right: 1px;
`;

interface SetAccountNameButtonProps {
  userId: number;
  onSuccess?: () => void; // Ensure onSuccess prop is provided
}

const SetAccountNameButton: React.FC<SetAccountNameButtonProps> = ({ userId, onSuccess }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [accountName, setAccountName] = useState<string>("");
  const { showToast } = useToast();
  const api = useContext(SunsetAPIAccountContext);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setIsEditing(false);
        setAccountName(""); // Clear the input field
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSubmit = async () => {
    if (accountName.trim() === "") {
      showToast(ToastType.Error, "Account name cannot be empty", 5);
      return;
    }

    try {
      const response = await api?.setAccountName(userId, accountName);
      if (!response?.success) {
        throw new Error("Failed to set account name");
      }
      showToast(ToastType.Success, "Account name set successfully", 5);
      setIsEditing(false); // Close the input field after successful submission
      setAccountName(""); // Clear the input field
      if (onSuccess) {
        onSuccess(); // Call onSuccess if provided
      }
    } catch (error) {
      console.error("Error setting account name:", error);
      showToast(ToastType.Error, "Failed to set account name", 5);
    }
  };

  return (
    <Container ref={containerRef}>
      {isEditing ? (
        <>
          <InputBox
            placeholder="Account Name"
            value={accountName}
            onChange={(e) => setAccountName(e.target.value)}
          />
          <Button style={{ border: "none" }} onClick={handleSubmit}>
            Set
          </Button>
        </>
      ) : (
        <Button style={{ border: "none" }} onClick={() => setIsEditing(true)}>Set Account Name</Button>
      )}
    </Container>
  );
};

export default SetAccountNameButton;
