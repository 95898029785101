import React from 'react';
import styles from './ItemsView.module.css';
import { ItemDTO } from '../../Networking/Interface/Account/AccountInterface';
type ItemsViewProps = {
    items: ItemDTO[];
};

const ItemsView: React.FC<ItemsViewProps> = ({ items }) => (
    <div className={styles.itemsContainer}>
        <h2 className={styles.title}>Items</h2>
        <div className={styles.itemsGrid}>
            {items.map((item, index) => (
                <div key={index} className={styles.itemCard}>
                    <div className={styles.itemName}>{item.itemName}</div>
                    <div className={styles.quantity}>Quantity: {item.quantity}</div>
                </div>
            ))}
        </div>
    </div>
);

export default ItemsView;
