import { APIResponse, APIUtility } from '../../ApiUtility'
import { FilterPayload, Log, LogPermission } from "./LogInterface";


export class SunsetAPILogs{
  private apiUtility: APIUtility;
  public baseURL: string;

  constructor(baseURL: string = "https://ss-rp.com/") {
      this.apiUtility = new APIUtility(baseURL);
      this.baseURL = baseURL;
  }

  async postLogFilter(filterData: FilterPayload): Promise<APIResponse<Log[]>> {
  return await this.apiUtility.post("api/ServerLogs/Filter", filterData);
}
async getLogPermissions(): Promise<APIResponse<LogPermission[]>> {
  return await this.apiUtility.get("api/user/logPerms");
}
}
