import React, { useContext, useState } from "react";
import { AccountDTO } from "../Networking/Interface/Account/AccountInterface";
import {
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ViewSession from "./ViewSession";
import styled from "styled-components";
import { UserContext } from "../User/UserContext";
import { Button } from "antd";

interface SessionData {
  session_id: number;
  timestamp: string;
  end: string;
  duration: { hours: number; minutes: number; seconds: number };
  username: string;
}

const StyledModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95vw;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  outline: none;

  @media (min-width: 768px) {
    width: 80vw;
    overflow-x: scroll;
    overflow-y: scroll;
  }

  @media (min-width: 1024px) {
    width: 70vw;
    overflow-x: scroll;
    overflow-y: scroll;
  }
`;

const CenteredH3 = styled.h3`
  text-align: center;
  margin: 20px 0;
  font-size: 1.5em;
  color: #333;

  @media (max-width: 768px) {
    font-size: 1.3em;
  }
`;

const NoDataMessage = styled.p`
  text-align: center;
  color: #888;
  font-size: 1.2em;
`;

interface GetSessionButtonProps {
  accountDTO: AccountDTO;
}

function GetSessionButton({ accountDTO }: GetSessionButtonProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const [fetchedData, setFetchedData] = useState<SessionData[]>([]);
  const { user } = useContext(UserContext);

  async function handleLauncher() {
    try {
      const response = await fetch(
        `https://ss-rp.com/exp-api/launcher/session/list/${accountDTO.id}`,
        {
          credentials: "include",
        }
      );

      const result = await response.json();

      if (response && response.ok) {
        // Sort the result by session_id in descending order
        const sortedData = result.sort(
          (a: SessionData, b: SessionData) => b.session_id - a.session_id
        );

        setFetchedData(sortedData);
        setModalOpen(true);
      } else {
        setModalOpen(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  if (user && user.role > 3) {
    return (
      <div>
        <Button style={{ border: "none" }} onClick={handleLauncher}>
          Get Session
        </Button>

        <Modal open={modalOpen} onClose={handleCloseModal}>
          <StyledModalContent>
            <CenteredH3>Session Stats of {accountDTO.username}</CenteredH3>

            {Array.isArray(fetchedData) && fetchedData.length > 0 ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Session ID</TableCell>
                      <TableCell>Start</TableCell>
                      <TableCell>End</TableCell>
                      <TableCell>Duration</TableCell>
                      <TableCell>Username</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fetchedData.map((item) => (
                      <TableRow key={item.session_id}>
                        <TableCell>{item.session_id}</TableCell>
                        <TableCell>{item.timestamp}</TableCell>
                        <TableCell>{item.end}</TableCell>
                        <TableCell>
                          {`${item.duration.hours}h ${item.duration.minutes}m ${item.duration.seconds}s`}
                        </TableCell>
                        <TableCell>{item.username}</TableCell>
                        <TableCell>
                          <ViewSession id={item.session_id} />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <NoDataMessage>No session data available.</NoDataMessage>
            )}
          </StyledModalContent>
        </Modal>
      </div>
    );
  } else {
    return null;
  }
}

export default GetSessionButton;
