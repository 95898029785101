import React, { useState } from "react";
import { Modal } from "@mui/material";
import { Button } from "antd";
import styled from "styled-components";

interface UseButtonProps {
  fileName: string;
  fileHash: string;
}

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  background: #f2f2f2;
  padding: 10px;
  text-align: left;
  color: black;
  border-bottom: 1px solid #ddd;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background: #f9f9f9;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const UseButton: React.FC<UseButtonProps> = ({ fileName, fileHash }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [usesData, setUsesData] = useState<any>(null);

  const handleFetchUses = async () => {
    const requestData = {
      name: fileName,
      hash: fileHash,
    };

    try {
      const response = await fetch(
        `https://ss-rp.com/exp-api/launcher/file/uses`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify(requestData), 
        }
      );
      if (response.ok) {
        const result = await response.json();
        setUsesData(result);
        setModalOpen(true);
      } else {
        console.error("Failed to fetch uses data");
      }
    } catch (error) {
      console.error("Error fetching uses data:", error);
    }
  };

  return (
    <>
      <Button onClick={handleFetchUses}>Uses</Button>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="use-modal-title"
        aria-describedby="use-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ModalContent>
          <h4 style={{ marginBottom: "20px" }}>Uses Data</h4>
          {usesData && usesData.length > 0 ? (
            <Table>
              <thead>
                <tr>
                  <TableHeader>User ID</TableHeader>
                  <TableHeader>Username</TableHeader>
                  <TableHeader>Count</TableHeader>
                </tr>
              </thead>
              <tbody>
                {usesData.map((user: any, index: number) => (
                  <TableRow key={index}>
                    <TableCell>{user.user_id}</TableCell>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.Count}</TableCell>
                  </TableRow>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No uses data available</p>
          )}
        </ModalContent>
      </Modal>
    </>
  );
};

export default UseButton;
