import React from 'react';
import styles from './List.module.css'; // Import the CSS module for List

interface ListProps {
    items: string[];
}

const List : React.FC<ListProps> = ({ items }) => {
    return (
        <ul className={styles.list}>
            {items.map((item, index) => (
                <li key={index} className={styles.listItem}>{item}</li>
            ))}
        </ul>
    );
};

export default List;