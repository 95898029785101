import React from 'react';
import { SunsetAPILogs } from './SunsetAPILogs';

interface SunsetAPIProviderProps {
    children: React.ReactNode;
}

const SunsetAPILogContext = React.createContext<SunsetAPILogs | undefined>(undefined);

const SunsetLogAPIProvider: React.FC<SunsetAPIProviderProps> = ({ children }) => {

    const api = new SunsetAPILogs();

    return (
        <SunsetAPILogContext.Provider value={api}>
            {children}
        </SunsetAPILogContext.Provider>
    );
};

export { SunsetLogAPIProvider, SunsetAPILogContext };