import React from 'react';
import { Faction, FactionType } from '../../Networking/Interface/Faction/FactionInterface';

import { FactionCardView } from './FactionCardView';
import './FactionListView.css';

interface FactionListViewProps {
    factions: Faction[]
}

export const FactionListView: React.FC<FactionListViewProps> = ({ factions }) => {
    // State to track which sections are expanded
    const [expandedSections, setExpandedSections] = React.useState<Record<string, boolean>>({
        'Government Factions': false,
        'Business Factions': false,
        'Criminal Factions': false,
    });

    const toggleSection = (sectionName: string) => {
        // Toggle the boolean value for the clicked section
        setExpandedSections({
            ...expandedSections,
            [sectionName]: !expandedSections[sectionName],
        });
    };

    return (
        <div className="faction-list">
            {renderFactionSection('Government Factions', factions.filter(f => f.type === FactionType.goverment), expandedSections, toggleSection)}
            {renderFactionSection('Business Factions', factions.filter(f => f.type === FactionType.business), expandedSections, toggleSection)}
            {renderFactionSection('Criminal Factions', factions.filter(f => f.type === FactionType.criminal), expandedSections, toggleSection)}
        </div>
    );
};
const renderFactionSection = (
    title: string,
    factions: Faction[],
    expandedSections: Record<string, boolean>,
    toggleSection: (sectionName: string) => void,
) => {
    // Determine if this section is currently expanded
    const isExpanded = expandedSections[title];

    return (
        <section className={`faction-section ${isExpanded ? 'expanded' : 'collapsed'}`}>
            <h1 className="card section-title" onClick={() => toggleSection(title)}>
                {title}
                <span className="indicator">{isExpanded ? '➖' : '➕'}</span>
            </h1>
            <div className="faction-container">
                {factions.map(faction => (
                    <FactionCardView key={faction.id} faction={faction }></FactionCardView>
                    
                ))}
            </div>
        </section>
    );
};

const FactionColours: Record<number, string> = {
    0: "#FFFFFF60",
    1: "#BB0A2160",
    2: "#25262760",
    3: "#CF5C3660",
    4: "#FFFD8260",
    5: "#5F0A8760",
    6: "#2B972060",
    7: "#5EF38C60",
    8: "#696A6960",
    9: "#0EDEE860",
    10: "#592A0060",
    11: "#001F5960",
    12: "#8B000060",
    13: "#5D3FD360",
    14: "#70296360",
    15: "#F44E4A60",
    16: "#FFFF0060",
    17: "#000033"
};


const getFactionColor = (faction: Faction): string => {
    return FactionColours[faction.color] || "#FFFFFF";
};

const getBannerType = (type: FactionType) => {
    switch (type) {
        case FactionType.business:
            return 'business';
        case FactionType.criminal:
            return 'hood';
        case FactionType.goverment:
            return 'police';
        default:
            return 'business';
    }
};