import { useContext, useEffect, useState } from "react";
import Wrapper from "../../Components/Wrapper";
import { UserContext } from "../../User/UserContext";
import styles from './CrateListView.module.css';
import { Link } from "react-router-dom";
import { MultiplyElement } from "../../Components/MultiplyElement";
import Grid from "../../Components/Grid";
import Information from "../../Components/Information";
import { SunsetAPIGambaContext } from "../../Networking/Interface/Gamba/GambaAPIProvider";
import { SunsetAPIContext } from "../../Networking/ApiProvider";
export const CrateInventoryView = () => {
    const { user, isLoading } = useContext(UserContext);
    const api = useContext(SunsetAPIContext);
    const [crates, setCrates] = useState<{ [key: number]: number }>({});
    useEffect(() => {
        if (!user) {
            return;
        }
        
        const fetchData = async () => {
            const cratesResponse = await api?.getCrates();
            setCrates(cratesResponse?.data!);
        }
        fetchData();
        
    },[isLoading,api,user])
    return (
        <Wrapper width="1000px">
            <Information title="Your Inventory">

           
            <Grid elementWidth="300px">
                {Object.entries(crates).map(([key, value], index) => (
    
                    <MultiplyElement key={index} count={value}>
                        <Link to={`${process.env.PUBLIC_URL}/crates/${key}`}  className={styles.crateLink}>
                    
                            <div className={styles.crate}>
                       
                                <img
                                    src={`${process.env.PUBLIC_URL}/gamba/${key}.png`}
                                    alt={`crate ${key}`}
                                    className={styles.crateImage}
                                />
                       
                            </div>
                    
                        </Link>
                    </MultiplyElement>
                ))}
                </Grid>
            </Information>
        </Wrapper>
    );
}