import React from 'react';
import styles from './OrangeButton.module.css'; // Import the CSS module

const OrangeButton: React.FC<{ children: React.ReactNode, onClick?: () => void }> = ({ children, onClick }) => {
    return (
        <button className={styles.orangeButton} onClick={onClick}>
            {children}
        </button>
    );
};

export default OrangeButton;