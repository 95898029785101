import React from 'react';
import { SunsetAPIGamba } from './SunsetAPIGamba';

interface SunsetAPIProviderProps {
    children: React.ReactNode;
}

const SunsetAPIGambaContext = React.createContext<SunsetAPIGamba | undefined>(undefined);

const SunsetGambaAPIProvider: React.FC<SunsetAPIProviderProps> = ({ children }) => {
    const api = new SunsetAPIGamba();

    return (
        <SunsetAPIGambaContext.Provider value={api}>
            {children}
        </SunsetAPIGambaContext.Provider>
    );
};

export { SunsetGambaAPIProvider, SunsetAPIGambaContext };