// Information.tsx
import React from 'react';
import styles from './Information.module.css';

interface InformationProps {
    title: string;
    children?: React.ReactNode; // Add this line to include children in the props type
}
const Information: React.FC<InformationProps> = ({ title, children }) => {
    return (
        <div className={styles.infoContainer}>
            <h3 className={styles.infoTitle}>{title}</h3>
            <div className={styles.infoContent}>{children}</div>
        </div>
    );
}

export default Information;
