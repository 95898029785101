import React, { useContext} from 'react';
import styles from './CrateOpeningsView.module.css';
import CrateRewardViewStyles from './CrateRewardView.module.css';
import { CrateOpening } from '../../Networking/Interface/Gamba/GambaInterface';
import { Link } from 'react-router-dom';
import { SunsetAPIGambaContext } from '../../Networking/Interface/Gamba/GambaAPIProvider';

interface OpeningsViewProps {
    openings: CrateOpening[]
}

export const OpeningsView: React.FC<OpeningsViewProps> = ({ openings}) => {
    
    const api = useContext(SunsetAPIGambaContext);

    const getRarityColor = (rarity: number): string => {
        switch (rarity) {
            case 1: return CrateRewardViewStyles.common;
            case 2: return CrateRewardViewStyles.uncommon;
            case 3: return CrateRewardViewStyles.rare;
            case 4: return CrateRewardViewStyles.legendary;
            default: return '';
        }
    };
    const getRarityClass = (rarity: number): string => {
        switch (rarity) {
            case 3: return styles.rare;
            case 4: return styles.legendary;
            default: return '';
        }
    };
    return (
            <div className={styles.tableWrapper}>
                <table className={styles.table}>
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>Reward</th>
                            <th>Description</th>
                            <th>Seed</th>
                            <th>User</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {openings.map(opening => (
                            <tr key={opening.crateOpeningId} className={getRarityClass(opening.crateRewardWon.rarity)}>
                                <td className={getRarityColor(opening.crateRewardWon.rarity)}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/gamba/${opening.crateRewardWon.reward.imageUrl}`}
                                        alt={opening.crateRewardWon.reward.name}
                                        className={styles.image}
                                    />
                                </td>
                                <td >
                                    {opening.crateRewardWon.quantity}x {opening.crateRewardWon.reward.name}
                                </td>
                                <td>{opening.crateRewardWon.reward.description}</td>
                                <td>{opening.seed}</td>
                                <td>
                                    <Link className={styles.details} to={`/accounts/${opening.userId}/${opening.username}`}>
                                        {opening.username}
                                    </Link>
                                </td>
                                <td>{opening.openingDate}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
    );
}

export default OpeningsView;
