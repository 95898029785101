import React from 'react';
interface CardProps {
    subtitle: string;
    text: string;
}

const Card: React.FC<CardProps> = ({ subtitle, text }) => {
    return (
        <div className="card">
            <h2 className="card-subtitle">{subtitle}</h2>
            <p className="card-text">{text}</p>
        </div>
    );
}

export default Card;
