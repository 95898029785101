import React from 'react';
import styles from './Connections.module.css'; // Correct path to the CSS module
import OrangeButton from '../../Components/OrangeButton';
import swatImage from './Gallery/GalleryImages/swat 4.png';

export const Connections: React.FC = () => {
    const connectToServer = () => {
        window.open('samp://play.ss-rp.com:7777');
    };

    return (
        <div className={styles.container}>
            <img src={swatImage} alt="Banner Background" className={styles.bannerImage} />
            <div className={styles.connectArea}>
                <a href="https://discord.gg/sunset-roleplay-444849547089281045" target="_blank" rel="noopener noreferrer">
                    <img src="/discord.webp" alt="Discord" className={styles.icon} />
                </a>
                <span className={styles.address}>play.ss-rp.com:7777</span>
                <OrangeButton onClick={connectToServer} >Connect</OrangeButton>
            </div>
        </div>
    );
};
