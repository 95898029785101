// StyledComponents.tsx
import styled from "styled-components";
export const OptionsIcon = styled.div`
  position: absolute;
  top: 90px;
  right: 50px;
  cursor: pointer;
  z-index: 1000;
`;


export const LogInfoText = styled.p`
  font-weight: 500; 
  color: #000000;
  margin: 0; 
  padding: 0;
  line-height: 1.8; 
`;

interface OptionsMenuProps {
  show: boolean;
}

export const Badge = styled.span`
  display: inline-flex;
  align-items: center;
  padding: 1px 5px;
  border-radius: 0.25em;
  color: #ffffff;
  background-color: #ba150f; 
  font-weight: bold;
  font-size: 0.775em;
  margin-right: 3px;
  text-transform: uppercase;

  svg {
    margin-right: 0.5em;
  }
`;
// export const Badge = styled.span<{ text: string }>`
//   display: inline-block;
//   padding: 0.5em;
//   border-radius: 0.25em;
//   color: #000000;
//   background-color: #e0a10c;
// `;


export const OptionsMenu = styled.div<OptionsMenuProps>`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 80px;
  right: 40px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
  justify-content: center;
  align-items: flex-start; 
  opacity: ${(props) => (props.show ? "1" : "0")};
  transition: opacity 0.5s ease-in-out;
  z-index: 999; 

`;

export const StyledHeader = styled.div`
  font-size: 20px;
  background-color: transparent;
  color: #020202;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  border-bottom: 2px black solid;
`;

export const StyledLine = styled.line`
  width: 100%;
  margin-top: 20px;

  margin-bottom: 4px;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-family: roboto;
  justify-content: center;
  margin: 5px;
  background-color: var(--gray-trans-color);
  padding: 1em;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  height: auto;
  margin-bottom: 10px;
  z-index: 500;
`;

export const StyledBox = styled.div`
  justify-content: space-between;
  width: 80%;
  margin: 20px;
  height: fit-content;
  border-top-right-radius: 5px;
`;

export const StlyedMain = styled.div`
  border: #171717 3px;
  width: 100%;
  margin: 12px;
  line-height: 30px;
`;

export const StyledButton = styled.button`
  color: transparent;
  border: black 1px solid;
  width: auto;
  height: fit-content;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
  outline: none;
  font-size: 13px;
`;

export const ButtonDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 6px;
  justify-content: space-evenly;
`;

export const ScrollableContent = styled.div`
  background-color: transparent;
  max-height: 70vh;
  overflow-y: auto;
  padding: 20px;
`;

export const Card = styled.div`
  background-color: #ffffff5d;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03),
    0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03),
    0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05),
    0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
  border: 1px solid rgba(26, 54, 126, 0.125);
  border-radius: 0.25rem;
  padding: 1.25rem;
  margin-top: 1px;
  width: 120%;
`;

export const VerticalTimeline = styled.div`
  width: 100%;
  position: relative;
  padding: 1.5rem 0 1rem;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 145px;
    height: 100%;
    width: 5px;
    background: #e9ecef;
    border-radius: 0.25rem;
  }
`;

export const VerticalTimelineElement = styled.div`
  position: relative;
  margin: 0 0 1rem;
  display: flex;
  flex-direction: row;
`;
export const VerticalTimelineElementIcon = styled.span<{ color: string }>`
  position: absolute;
  top: 0;
  left: 140px; // Adjust this if needed based on your layout

  i {
    width: 15px; // Size of the dot
    height: 15px; // Size of the dot
    position: relative;
    border-radius: 50%; // Make it round
    background-color: ${(props) => props.color}; // Use the color prop
    box-shadow: 0 0 0 5px #fff; // White ring around the dot
    display: block;

    &::before {
      content: "";
      width: 12px; // Match the size of the dot
      height: 12px; // Match the size of the dot
      border-radius: 50%; // Ensure it's round
      background: ${(props) => props.color}; // Match the background color
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`;

export const VerticalTimelineElementContent = styled.div`
  position: relative;
  margin-left: 161px;
  font-size: 0.8rem;

  .timeline-title {
    font-size: 0.8rem;
    text-transform: uppercase;
    margin: 0 0 0.5rem;
    padding: 2px 0 0;
    font-weight: bold;
  }
`;
export const DateandTime = styled.div`
  .vertical-timeline-element-date {
    display: block;
    position: absolute;
    left: 40px;
    top: -5px;
    color: #5e6165;
    font-size: 0.85rem;
    white-space: nowrap;
  }
  .vertical-timeline-element-time {
    display: block;
    position: absolute;
    left: 40px;
    top: 10px; // Adjusted for visual spacing
    color: #5e6165;
    font-size: 0.85rem;
    white-space: nowrap;
  }
`;
// Styled components
export const Container = styled.div`
  font-family: Arial, sans-serif;
  margin: 20px;
`;

export const Button = styled.button`
  background-color: var(--orange-color);
  color: white;
  border: none;
  width: auto;
  padding: 2px 6px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
  outline: none;
  margin: 2px;
  font-size: 13px;

  &:hover {
    background-color: #2d2d2d; /* Hover background color */
  }
`;

// export const Modal = styled.div`
//     position: fixed;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     background: rgba(0, 0, 0, 0.5);
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     visibility: ${props => props.show ? 'visible' : 'hidden'};
//     opacity: ${props => props.show ? 1 : 0};
//     transition: visibility 0s, opacity 0.5s linear;
// `;

export const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  color: black;
  max-width: 600px;
`;

export const Table = styled.table`
  width: 100%;
  color: black;
  border-collapse: collapse;
`;

export const TableHeader = styled.th`
  background-color: #f2f2f2;
  color: black;
  border: 1px solid #ddd;
  padding: 8px;
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

export const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
`;
