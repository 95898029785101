import React, { useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

interface ModalProps {
  onClose: () => void;
  children: React.ReactNode;
  className?: string; // Add className prop to allow custom styling
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(42, 41, 41, 0.404);
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  z-index: 1000; 
`;

const ModalContainer = styled.div`
  border-radius: 8px;
  align-items: center;
  margin: 20px;
  display: flex;
  justify-content: center;
  max-height: 97%; 
  overflow: hidden; 
  padding: 20px;
  position: relative;
  padding-bottom: 2px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  color: #333;
`;

export const Modal: React.FC<ModalProps> = ({
  onClose,
  children,
  className,
}) => {
  const handleEscapeKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEscapeKey);
    document.body.classList.add("modal-open"); // Prevent background scrolling
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
      document.body.classList.remove("modal-open"); // Re-enable background scrolling
    };
  }, [handleEscapeKey]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (!(event.target as HTMLElement).closest(".modal-container")) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return ReactDOM.createPortal(
    <Overlay>
      <ModalContainer className={`modal-container ${className}`}>
        <CloseButton onClick={onClose}>X</CloseButton>
        {children}
      </ModalContainer>
    </Overlay>,
    document.body
  );
};
