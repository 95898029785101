import React from "react";

interface MultiplyElementProps {
    children: React.ReactNode;
    count: number;
}

export const MultiplyElement: React.FC<MultiplyElementProps> = ({ children, count }) => {
    return (
        <>
            {Array.from({ length: count }, (_, index) => (
                <div key={index}>{children}</div>
            ))}
        </>
    );
};
