import React from 'react';
import { CharacterView } from "./CharacterView";
import { TabPane } from '../../Components/Tabs';
import { CharacterDTO } from '../../Networking/Interface/Account/AccountInterface';

interface CharacterTabProps {
    characters: CharacterDTO[];
    activeTab: number | null;
    setActiveTab: (tab: number | null) => void;
    setCharacterName: (characterId: number, newName: string) => void; // Updated to accept two parameters
}

const CharacterTab: React.FC<CharacterTabProps> = ({ characters, activeTab, setActiveTab, setCharacterName }) => {
    return (
        <TabPane tabKey="0" tabTitle="Overview">
            <div className="tab-container">
                {characters.map((char) => (
                    <div
                        key={char.id}
                        className={`tab ${activeTab === char.id ? 'active' : ''}`}
                        onClick={() => setActiveTab(char.id)}
                    >
                        <div className="character-head">
                            <img src={`${process.env.PUBLIC_URL}/samp/headshots/${char.lastSkin}.png`} alt={char.characterName} />
                        </div>
                        {char.characterName}
                    </div>
                ))}
            </div>
            <div>
                {characters.filter(char => char.id === activeTab).map(char => (
                    <div key={char.id}>  
                        <CharacterView character={char} />
                    </div>
                ))}
            </div>
        </TabPane>
    );
};

export default CharacterTab;
