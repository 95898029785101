import { message } from "antd";
import { useCallback, useState } from "react";
import Toast, { ToastType } from "./Toast";
import { ToastContext } from "./ToastContext";

interface ToastProviderProps {
    children: React.ReactNode;
}

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
    const [toast, setToast] = useState({ show: false, type: ToastType.Information, message: '', duration: 3 });

    const showToast = useCallback((type: ToastType, message: string, duration: number = 3) => {
        setToast({ show: true, type, message, duration });

        setTimeout(() => {
            setToast(prev => ({ ...prev, show: false }));
        }, duration * 1000);
    }, []);

    return (
        <ToastContext.Provider value={{ showToast }}>
            {children}
            {toast.show && (
                <Toast type={toast.type} duration={toast.duration} message={toast.message }/>
            )}
        </ToastContext.Provider>
    );
};
