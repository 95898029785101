import { useContext, useEffect, useState } from "react";
import Information from "../../Components/Information";
import DailyActivityGraph from "../../Components/Stats/DailyActivityGraph";
import { AccountDTO } from "../../Networking/Interface/Account/AccountInterface";
import { SunsetAPIAccountContext } from "../../Networking/Interface/Account/AccountAPIProvider";

interface AcccountActivityViewProps {
    accountDTO: AccountDTO;
}
export const AccountActivityView: React.FC<AcccountActivityViewProps> = ({ accountDTO }) => {
    const api = useContext(SunsetAPIAccountContext);
    const [playtime, setPlaytime] = useState < { [key: number]: number }>()
    useEffect(() => {
        const fetch = async () => {
            const response = await api!.getActivityByAccountId(accountDTO.username);
            if (response.success && response.data) {
                setPlaytime(response.data);
            }
        }
        fetch();
    }, [api, accountDTO])

    return (
        <>
            <Information title="Monthly activity charted to represent daily playtime peaks." >
                {playtime && <DailyActivityGraph data={playtime!}></DailyActivityGraph>}
            </Information>
            
        </>
        
    )

}