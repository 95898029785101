import React, { useState, useEffect } from "react";
import { ButtonBase } from "@mui/material";
import {
  Card,
  VerticalTimeline,
  VerticalTimelineElement,
  VerticalTimelineElementContent,
  VerticalTimelineElementIcon,
  DateandTime,
  LogInfoText,
  Badge,
} from "./StyledComponents";
import ProcessModal from "./ActiveProcessModal";
import ScreenshotInfo from "./ScreenshotData";
import FileModal from "./DirectoryData";
import AHKScriptModal from "./AHKModal";
import FileDataModal from "./FileDataModal";

interface TimelineItem {
  timestamp: string;
  category: string;
  log_info: string;
}

interface Process {
  Id: number;
  ProcessName: string;
  PrivateMemorySize64: number;
  StartTime: string;
  InjectedProcess: boolean;
}

interface File {
  Name: string;
  Path: string;
  Size: number;
  LastModified: string;
  Hash: string;
  Blacklisted: boolean;
  Content: string | null;
  Remark: string | null;
}
interface AHKScript {
  FileName: string;
  Contents: string | string[]; // Adjusting Contents to be string or string[]
  StartTime: string;
}

interface FileData {
  name: string;
  hash: string;
  content: string;
  file_size: number;
  requested_by: number;
}

const getBadgeColor = (category: string): string => {
  switch (category) {
    case "warn":
      return "#ffc107";
    case "punish":
      return "#dc3545";
    case "screenshot":
      return "#042195";
    case "directory":
      return "#042195";
    case "process":
      return "#042195";
    case "connect":
      return "#a1a1a1";
    case "ahk":
      return "#f124d3";
    case "file":
      return "#10ebf3";
    default:
      return "#28a745";
  }
};

interface SessionTimelineModaleeProps {
  sessionId: string;
  timestamp: string;
  userId: number;
  userName: string;
}

const SessionTimelineModalee: React.FC<SessionTimelineModaleeProps> = ({
  sessionId,
  timestamp,
  userId,
  userName,
}) => {
  const [timelineData, setTimelineData] = useState<TimelineItem[]>([]);
  const [processModalOpen, setProcessModalOpen] = useState(false);
  const [fileModalOpen, setFileModalOpen] = useState(false);
  const [processes, setProcesses] = useState<Process[]>([]);
  const [injectedDlls, setInjectedDlls] = useState<
    Array<{ ModulePath: string; IsValid: boolean; AlertMessage: string }>
  >([]);

  const [launcherDlls, setLauncherDlls] = useState<
    Array<{ ModulePath: string; IsValid: boolean; AlertMessage: string }>
  >([]);

  const [files, setFiles] = useState<File[]>([]);
  const [deletedFilesData, setDeletedFilesData] = useState<File[]>([]);
  const [deletedFilesCount, setDeletedFilesCount] = useState(0);
  const [ahkModalOpen, setAhkModalOpen] = useState(false);
  const [ahkData, setAhkData] = useState<AHKScript[]>([]);
  const [fileData, setFileData] = useState<FileData | null>(null);
  const [fileDataModal, setFileDataModal] = useState(false);

  const getDateTime = (
    date: string | Date | null
  ): {
    dateString: string;
    timeString: string;
  } => {
    if (date === null) {
      return { dateString: "", timeString: "" };
    }
    const d = new Date(date);
    const dateString = d.toLocaleDateString();
    const timeString = d.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
    return { dateString, timeString };
  };

  const formattedTimestamp = getDateTime(timestamp);

  useEffect(() => {
    fetchTimelineData();
  }, []);

  const fetchTimelineData = async () => {
    try {
      const response = await fetch(
        `https://ss-rp.com/exp-api/launcher/session/timeline/${sessionId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const result = await response.json();

        setTimelineData(result);
      } else {
        console.error("Failed to fetch session timeline data");
      }
    } catch (error) {
      console.error("Error fetching session timeline data:", error);
    }
  };
  const handleViewProcesses = (logInfo: string) => {
    try {
      const parsedData = JSON.parse(logInfo);
      const processData: Process[] = parsedData.data;
      // const injectedDlls: string =
      const injectedDlls: Array<{
        ModulePath: string;
        IsValid: boolean;
        AlertMessage: string;
      }> = parsedData.injected_dlls;
      const launcherDlls: Array<{
        ModulePath: string;
        IsValid: boolean;
        AlertMessage: string;
      }> = parsedData.launcher_dlls;

      if (Array.isArray(processData)) {
        setProcesses(processData);
        setInjectedDlls(injectedDlls);
        setLauncherDlls(launcherDlls);
        setProcessModalOpen(true);
      } else {
        console.error("Parsed process data is not an array", processData);
      }
    } catch (error) {
      console.error("Error parsing process data", error);
    }
  };

  const handleViewFiles = (logInfo: string, index: number) => {
    try {
      const parsedData = JSON.parse(logInfo);
      const fileData = JSON.parse(parsedData.data);
      const directories = timelineData.filter(
        (item) => item.category === "directory"
      );
      directories.sort(
        (a, b) =>
          new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
      );

      const formattedFileData = fileData.map((file: any) => {
        const fileDateTime = getDateTime(file.LastModified);
        // console.log(fileDateTime.dateString , formattedTimestamp.dateString)
        // console.log(fileDateTime.dateString > formattedTimestamp.dateString)
        const isModified =
          fileDateTime.dateString > formattedTimestamp.dateString &&
          fileDateTime.timeString > formattedTimestamp.timeString;

        return {
          ...file,
          Remark: isModified
            ? "⚠️ This file has possibly been modified during the session."
            : "",
        };
      });

      if (Array.isArray(formattedFileData)) {
        setFiles(formattedFileData);

        setDeletedFilesData(parsedData.deletedFiles);

        const deletedFilesCount =
          parsedData.deletedFilesCount || deletedFilesData.length;
        setDeletedFilesCount(deletedFilesCount);

        setFileModalOpen(true);
      } else {
        console.error("Parsed file data is not an array", formattedFileData);
      }
    } catch (error) {
      console.error("Error parsing file data", error);
    }
  };

  const handleViewAHK = (logInfo: string) => {
    try {
      const data = JSON.parse(logInfo);
      const realData: Record<string, string>[] = data.data;
      const formattedData = realData.map((item) => {
        return Object.entries(item).map(([key, value]) => {
          // if (key === "StartTime") {
          //   return [key, getDateTime(value)];
          // }
          return [key, value];
        });
      });

      setAhkData(formattedData as unknown as AHKScript[]);
      setAhkModalOpen(true);
    } catch (error) {
      console.error("Error parsing AHK data", error);
    }
  };

  const handleViewFile = (logInfo: string) => {
    const data = JSON.parse(logInfo);

    setFileData(data);
    setFileDataModal(true);
  };
  return (
    <Card>
      <>
        <h5 className="card-title">User Timeline</h5>
        <VerticalTimeline>
          {timelineData.map((item, index) => (
            <VerticalTimelineElement key={index}>
              <DateandTime>
                <span className="vertical-timeline-element-date">
                  {getDateTime(item.timestamp).dateString}
                </span>
                <span className="vertical-timeline-element-time">
                  {getDateTime(item.timestamp).timeString}
                </span>
              </DateandTime>
              {item.category === "screenshot" ? (
                <>
                  <VerticalTimelineElementIcon
                    color={getBadgeColor(item.category)}
                  >
                    <i className="badge badge-dot badge-dot-xl"></i>
                  </VerticalTimelineElementIcon>
                  <VerticalTimelineElementContent>
                    <h4 className="timeline-title">{item.category}</h4>
                    <ScreenshotInfo
                      data={item.log_info}
                      userName={userName}
                      userId={userId}
                    />
                  </VerticalTimelineElementContent>
                </>
              ) : item.category === "process" ? (
                <>
                  <VerticalTimelineElementIcon
                    color={getBadgeColor(item.category)}
                  >
                    <i className="badge badge-dot badge-dot-xl"></i>
                  </VerticalTimelineElementIcon>
                  <VerticalTimelineElementContent>
                    <h4 className="timeline-title">{item.category}</h4>
                    <ButtonBase
                      onClick={() => handleViewProcesses(item.log_info)}
                    >
                      Click here for more
                    </ButtonBase>
                  </VerticalTimelineElementContent>
                </>
              ) : item.category === "directory" ? (
                <>
                  <VerticalTimelineElementIcon
                    color={getBadgeColor(item.category)}
                  >
                    <i className="badge badge-dot badge-dot-xl"></i>
                  </VerticalTimelineElementIcon>
                  <VerticalTimelineElementContent>
                    <h4 className="timeline-title">{item.category}</h4>
                    <ButtonBase
                      onClick={() => handleViewFiles(item.log_info, index)}
                    >
                      Click here for more
                    </ButtonBase>
                  </VerticalTimelineElementContent>
                </>
              ) : item.category === "punish" ? (
                <>
                  <VerticalTimelineElementIcon
                    color={getBadgeColor(item.category)}
                  >
                    <i className="badge badge-dot badge-dot-xl"></i>
                  </VerticalTimelineElementIcon>
                  <VerticalTimelineElementContent>
                    <h4 className="timeline-title">{item.category}</h4>
                    <LogInfoText>
                      <Badge>
                        {/* <MdWarning/> */}
                        <span className="badge badge-danger">kick </span>{" "}
                      </Badge>
                      {item.log_info.replace(/"|\[kick\] /g, "")}
                    </LogInfoText>
                  </VerticalTimelineElementContent>
                </>
              ) : item.category === "warn" ? (
                <>
                  <VerticalTimelineElementIcon
                    color={getBadgeColor(item.category)}
                  >
                    <i className="badge badge-dot badge-dot-xl"></i>
                  </VerticalTimelineElementIcon>
                  <VerticalTimelineElementContent>
                    <h4 className="timeline-title">{item.category}</h4>
                    <LogInfoText>{item.log_info.replace(/"/g, "")}</LogInfoText>
                  </VerticalTimelineElementContent>
                </>
              ) : item.category === "connect" ? (
                <>
                  <VerticalTimelineElementIcon
                    color={getBadgeColor(item.category)}
                  >
                    <i className="badge badge-dot badge-dot-xl"></i>
                  </VerticalTimelineElementIcon>
                  <VerticalTimelineElementContent>
                    <h4 className="timeline-title">{item.category}</h4>
                    <LogInfoText>{item.log_info.replace(/"/g, "")}</LogInfoText>
                  </VerticalTimelineElementContent>
                </>
              ) : item.category === "ahk" ? (
                <>
                  <VerticalTimelineElementIcon
                    color={getBadgeColor(item.category)}
                  >
                    <i className="badge badge-dot badge-dot-xl"></i>
                  </VerticalTimelineElementIcon>
                  <VerticalTimelineElementContent>
                    <h4 className="timeline-title">{item.category}</h4>
                    <ButtonBase onClick={() => handleViewAHK(item.log_info)}>
                      Click here for more
                    </ButtonBase>
                  </VerticalTimelineElementContent>
                </>
              ) : item.category === "file" ? (
                <>
                  <VerticalTimelineElementIcon
                    color={getBadgeColor(item.category)}
                  >
                    <i className="badge badge-dot badge-dot-xl"></i>
                  </VerticalTimelineElementIcon>
                  <VerticalTimelineElementContent>
                    <h4 className="timeline-title">{item.category}</h4>
                    <ButtonBase onClick={() => handleViewFile(item.log_info)}>
                      Click here for more
                    </ButtonBase>
                  </VerticalTimelineElementContent>
                </>
              ) : (
                <>
                  <VerticalTimelineElementIcon
                    color={getBadgeColor(item.category)}
                  >
                    <i className="badge badge-dot badge-dot-xl"></i>
                  </VerticalTimelineElementIcon>
                  <VerticalTimelineElementContent>
                    <h4 className="timeline-title">{item.category}</h4>
                    <LogInfoText>{item.log_info}</LogInfoText>
                  </VerticalTimelineElementContent>
                </>
              )}
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </>
      <ProcessModal
        processes={processes}
        injectedDlls={injectedDlls}
        launcherDlls={launcherDlls}
        open={processModalOpen}
        dateTime={formattedTimestamp}
        onClose={() => setProcessModalOpen(false)}
      />
      <AHKScriptModal
        open={ahkModalOpen}
        data={ahkData}
        onClose={() => setAhkModalOpen(false)}
      />
      <FileModal
        userId={userId || 0}
        files={files}
        deletedFiles={deletedFilesData}
        deletedFilesCount={deletedFilesCount}
        open={fileModalOpen}
        onClose={() => setFileModalOpen(false)}
      />
      <FileDataModal
        open={fileDataModal}
        onClose={() => setFileDataModal(false)}
        data={fileData}
      />
    </Card>
  );
};

export default SessionTimelineModalee;
