import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, List, ListItem, ListItemText } from '@mui/material';

interface FileData {
  name: string;
  hash: string;
  content: string;
  file_size: number;
  requested_by: number;
}


interface FileDataModalProps {
  open: boolean;
  data: FileData | null;
  onClose: () => void;
}

const decodeBase64 = (base64String: string) => {
  try {

    const binaryString = window.atob(base64String);

    const decodedString = decodeURIComponent(
      Array.from(binaryString).map((char) =>
        `%${('00' + char.charCodeAt(0).toString(16)).slice(-2)}`
      ).join('')
    );

    return decodedString;
  } catch (e) {
    console.error('Failed to decode base64 string:', e);
    return '';
  }
}


const FileDataModal: React.FC<FileDataModalProps> = ({ open, data, onClose }) => {

  const decodedContent = data ? decodeBase64(data.content) : '';
  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg" fullWidth PaperProps={{
      style: {
        width: '80vw', 
        maxWidth: '1250px', 
      },
    }}>
      <DialogTitle>File Information</DialogTitle>
      <DialogContent>
        {data ? (
          <List>
            <ListItem>
              <ListItemText primary="Name" secondary={data.name} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Hash" secondary={data.hash} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Content" secondary={decodedContent} />
            </ListItem>
            <ListItem>
              <ListItemText primary="File Size" secondary={`${data.file_size} bytes`} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Requested By" secondary={data.requested_by} />
            </ListItem>
          </List>
        ) : (
          <Typography>No data available</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default FileDataModal;
