import React from "react";
import styles from "./VehicleView.module.css";
import InfoLineView, { InfoLine } from "../../Components/InfoLineView";
import { PropertyDTO } from "../../Networking/Interface/Account/AccountInterface";
import { FcLock, FcUnlock } from "react-icons/fc";

type PropertyViewProps = {
  properties: PropertyDTO[];
};

const removeHexColor = (propName: string | undefined): string => {
  if (!propName) {
    return "No property name."; // Or any default value you prefer
  }
  // Regular expression to match hex color codes (e.g., {FFFF00})
  const hexRegex = /\{[0-9A-Fa-f]+\}/g;
  return propName.replace(hexRegex, ""); // Remove hex color codes
};

const PropertyView: React.FC<PropertyViewProps> = ({ properties }) => {
  const formatDate = (timestamp: number): string => {
    if (timestamp === 0) {
      return "Never.";
    }
    const date = new Date(timestamp * 1000); // Convert Unix timestamp to milliseconds
    return date.toLocaleDateString(); // Format date as per locale
  };
  return (
    <div className={styles.vehicleList}>
      {properties.map((property) => (
        <div key={property.id} className={styles.vehicleCard}>
          <div style={{ padding: "5px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center"
              }}
            >
              <h3 style={{ marginTop: "15px", color:"#525252" }}>{removeHexColor(property.propName)}</h3>
              {/* <span  style={{ fontSize: "35px", marginTop: "10px"}}> {property.locked ? <FcLock /> : <FcUnlock />}</span> */}
              {property.locked ? (
                <FcLock title="Locked" style={{ fontSize: "35px", marginTop: "10px", marginLeft: "10px" }} />
              ) : (
                <FcUnlock title="Unlocked" style={{ fontSize: "35px", marginTop: "10px", marginLeft: "10px" }} />
              )}
            </div>
            <InfoLineView title="Details">
              <InfoLine label="Address" value={property.address} />
              <InfoLine
                label="Value"
                value={`$` + property.price.toLocaleString()}
              />
              <InfoLine label="Interior" value={property.interior} />
              <InfoLine
                label="Tax"
                value={`$` + property.tax.toLocaleString()}
              />
              <InfoLine
                label="Last Tax Date"
                value={formatDate(property.lastTaxDate)}
              />
            </InfoLineView>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PropertyView;
