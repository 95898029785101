import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../../User/UserContext";
import Logout from "../../pages/Login/logout";
import "../../App.css";
import "./Navbar.css";
import { UserRole } from "../../Utils/enums";

interface ProfileProps {
  username: string;
}
// Generic NavItem component
interface NavItemProps {
  label?: string;
  path: string;
  iconSrc?: string;
  altText?: string;
}
const NavItem: React.FC<NavItemProps> = ({ label, path, iconSrc, altText }) => (
  <li>
    <Link to={path} className="no-hover">
      {iconSrc ? (
        <img src={iconSrc} alt={altText || ""} className="nav-icon" />
      ) : (
        <button className="nav-button">{label}</button>
      )}
    </Link>
  </li>
);

export default function Navbar() {
  const userProp = React.useContext(UserContext);
  const [menuOpen, setMenuOpen] = useState(false);
  const navRef = useRef<HTMLElement>(null);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const handleClickOutside = (event: MouseEvent) => {
    if (navRef.current && !navRef.current.contains(event.target as Node)) {
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar-main" ref={navRef}>
      <div className="nav-container">
        <input
          type="checkbox"
          id="menu-toggle"
          className="menu-button"
          checked={menuOpen}
          onChange={toggleMenu}
        />
        <label htmlFor="menu-toggle" id="menu-icon">
          &#9776;
        </label>
        <Logo></Logo>
        <ul className={`nav-list ${menuOpen ? "open" : ""}`}>
          <NavItem label="Accounts" path="/accounts" />
          <NavItem label="Factions" path="/factions" />
          <NavItem label="Crates" path="/crates" />
          {!!userProp && userProp.user?.role! >= UserRole.Mod && <Staff />}
          <Community />
          <NavItem label="Donate" path="/donations" />
          {userProp.user ? (
            <NavItem label="Launcher" path="/launcher-download" />
          ) : (
            ""
          )}
        </ul>
        {userProp.user ? (
          <Profile {...userProp.user} />
        ) : (
          <NavItem label="Login" path="/login" />
        )}
      </div>
    </nav>
  );
}

const Profile: React.FC<ProfileProps> = ({ username }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div
      className="nav-button "
      onMouseEnter={() => setIsDropdownOpen(true)}
      onMouseLeave={() => setIsDropdownOpen(false)}
      role="button" // Important for accessibility
      tabIndex={0} // To make it focusable
    >
      <Link className="username" to="/user">
        {username}
      </Link>
      {isDropdownOpen && (
        <div className="dropdown-content">
          <Link to="/logout">
            <Logout />
          </Link>
        </div>
      )}
    </div>
  );
};

const Staff: React.FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const userProp = React.useContext(UserContext);
  return (
    <div
      className="nav-button dropdown"
      onMouseEnter={() => setIsDropdownOpen(true)}
      onMouseLeave={() => setIsDropdownOpen(false)}
      role="button" // Important for accessibility
      tabIndex={0} // To make it focusable
    >
      Staff
      {isDropdownOpen && (
        <div
          className="dropdown-content"
          style={{ marginLeft: "-20px", marginTop: "12px" }}
        >
          <Link to="/logs">Logs</Link>
          {!!userProp && userProp.user?.role! > UserRole.SnrAdmin && (
            <>
              <Link to="/management">Management</Link>
            </>
          )}
          {!!userProp && userProp.user?.role! > UserRole.SnrAdmin && (
            <>
              <Link to="/management-launcher">Manage Launcher</Link>
            </>
          )}
        </div>
      )}
    </div>
  );
};

const Community: React.FC = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleMouseEnter = () => {
    setIsDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setIsDropdownOpen(false);
  };

  return (
    <div
      className="nav-button dropdown"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      role="button" // Important for accessibility
      tabIndex={0} // To make it focusable
    >
      Community
      {isDropdownOpen && (
        <div
          className="dropdown-content"
          style={{ marginLeft: "-20px", marginTop: "12px" }}
        >
          <Link to="/help">Help</Link>
          <a className="my-link" href="https://forum.ss-rp.com/">
            Forums
          </a>
          <a className="my-link" href="https://discord.gg/2Y3t5B9XCe">
            Discord
          </a>
        </div>
      )}
    </div>
  );
};
const Logo: React.FC = () => (
  <Link to="/" className="logo">
    <img
      src={`${window.location.origin}/logo.png`}
      alt="Server logo"
      className="logo"
    />
  </Link>
);
