// CrateListView.tsx
import React from 'react';
import styles from './CrateListView.module.css';
import { Link } from 'react-router-dom';
import Information from '../../Components/Information';
import Description from '../../Components/Description';
import { Crate } from './Models/Crate';
import cratesData from './crates.json'; 
import Grid from '../../Components/Grid';
import { CrateInventoryView } from './CrateInventoryView';

const CrateListView: React.FC = () => {

    const crates: Crate[] = cratesData.crates; 

    return (
        <>
            <div className={styles.wrapper}>
                <Information title="Openable crates">
                    <Grid elementWidth="300px">
                        {crates.map((crate) => (
                            <Link key={crate.ID} to={`${process.env.PUBLIC_URL}/crates/${crate.ID}`} className={styles.crateLink}>
                                <div className={styles.crate}>
                                    <img
                                        src={`${process.env.PUBLIC_URL}/gamba/${crate.ID}.png`}
                                        alt={`${crate.Name}`}
                                        className={styles.crateImage}
                                    />
                                    <div className={styles.crateInfo}>
                                        <h2 className={styles.crateName}>{crate.Name}</h2>
                                        <p className={styles.crateDescription}>{crate.Description}</p>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </Grid>
                </Information>
                <Description size={18} mode="Light" text="Crates are obtainable ingame items that give various different rewards. Total amounts of crates are accounted from both character's inventories."></Description>
            </div>

            <CrateInventoryView></CrateInventoryView>
        </>
        
    );
};

export default CrateListView;
