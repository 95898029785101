import React from 'react';
import styles from "./VehicleView.module.css";
import InfoLineView, { InfoLine } from '../../Components/InfoLineView';
import { VehicleDTO } from '../../Networking/Interface/Account/AccountInterface';
type VehicleViewProps = {
    vehicles: VehicleDTO[];
}

const VehicleView: React.FC<VehicleViewProps> = ({ vehicles }) => {
    return (
        <div className={styles.vehicleList}>
            {vehicles.map(vehicle => (
                <div key={vehicle.dbId} className={styles.vehicleCard}>
                    <img src={`${process.env.PUBLIC_URL}/samp/vehicles/${vehicle.model}.png`} alt="Vehicle" className={styles.vehicleImage} />
                    <div className={styles.vehicleDetails}>
                        <h2>{vehicle.numberplate} (DB Id: {vehicle.dbId})</h2>
                        <div className={styles.vehicleHealth}>
                            <div className={styles.healthBar} style={{ width: `${(vehicle.vehicleHealth / vehicle.vehicleMaxHealth) * 100}%` }}></div>
                        </div>
                        <InfoLineView title="Details">
                            <InfoLine label="Model" value={vehicle.model} />
                            <InfoLine label="Color 1" value={vehicle.color1} />
                            <InfoLine label="Color 2" value={vehicle.color2} />
                            <InfoLine label="Fuel" value={vehicle.vehicleFuel} />
                            <InfoLine label="Distance" value={vehicle.distance} />
                            <InfoLine label="Tax" value={vehicle.tax} />
                            <InfoLine label="Last Tax Date" value={vehicle.lastTaxDate} />
                            <InfoLine label="Locked" value={vehicle.locked} />
                            <InfoLine label="Transferrable?" value={vehicle.transferrable} />
                        </InfoLineView>
                    </div>
                </div>
            ))}
        </div>
    );
}

export default VehicleView;
