import { Faction, FactionType } from "../../Networking/Interface/Faction/FactionInterface";


export const FactionColours: Record<number, string> = {
    0: "#FFFFFF60",
    1: "#BB0A2160",
    2: "#25262760",
    3: "#CF5C3660",
    4: "#FFFD8260",
    5: "#5F0A8760",
    6: "#2B972060",
    7: "#5EF38C60",
    8: "#696A6960",
    9: "#0EDEE860",
    10: "#592A0060",
    11: "#001F5960",
    12: "#8B000060",
    13: "#5D3FD360",
    14: "#70296360",
    15: "#F44E4A60",
    16: "#FFFF0060",
    17: "#000033"
};


export const getFactionColor = (faction: Faction): string => {
    return FactionColours[faction.color] || "#FFFFFF";
};
export const getBannerType = (type: FactionType) => {
    switch (type) {
        case FactionType.business:
            return 'business';
        case FactionType.criminal:
            return 'hood';
        case FactionType.goverment:
            return 'police';
        default:
            return 'business';
    }
};