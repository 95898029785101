
import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./help.css";
import { UserContext } from "../../User/UserContext";
import { UserRole } from "../../Utils/enums";
import {   TopicListItem,
  TopicListItems,
  Container,
  Card,
  LeftPanel,
  RightPanel,
  Heading,
  Headings,
  TopicList,
  Button,
  BackButton,
  TopicListItems2} from './help'
import { ToastType } from "../../Components/Toast/Toast";
import { useToast } from "../../Components/Toast/ToastContext";

interface MenuItem {
  id: number;
  type: number;
  parentId: number;
  specialId: string;
  title: string;
  content: string;
}



const removeHyphens = (title: string): string => {
  const cleanedTitle = title.trim().replace(/-/g, " ");
  return cleanedTitle;
};

export default function HelpPage() {
  const userProp = useContext(UserContext);
  const { id } = useParams<{ id: string | "1" }>();
  const [helpContent, setHelpContent] = useState<MenuItem | null>(null);
  const [relatedPages, setRelatedPages] = useState<MenuItem[]>([]);
  const [menuItems, setMenuItems] = useState<MenuItem[]>([]);
  const navigate = useNavigate();
  const [relatedPagesTitle, setRelatedPagesTitle] =
    useState<string>("Related Pages");
  const [lastClickedTitle, setLastClickedTitle] = useState<string>("");
  const [isEditing, setIsEditing] = useState(false);
  const [editContent, setEditContent] = useState<string>("");
  const [originalContent, setOriginalContent] = useState<string>("");
  const [showBackButton, setShowBackButton] = useState(false);
  const { showToast } = useToast();

  useEffect(() => {
    if (helpContent) {
      setOriginalContent(helpContent.content);
      setEditContent(helpContent.content);
    }
  }, [helpContent]);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditContent(originalContent); // Reset to original content if cancelled
  };

  const handleSaveClick = () => {
    if (editContent !== originalContent) {
      if (helpContent) {
        const updatedItem: MenuItem = {
          ...helpContent,
          content: editContent,
        };
  
        fetch(`https://ss-rp.com/api/serverinfo/helpinfo/${helpContent.id}`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            credentials: "include",
          },
          credentials: "include",
          body: JSON.stringify(updatedItem.content), // Only send the content as the backend expects
        })
          .then((response) => {
            if (response.ok) {
              showToast(ToastType.Success, "Updated! Good boy.", 5);
              return response.json().catch(() => {
                throw new Error("Failed to parse JSON");
              });
            } else {
              throw new Error("Network response was not ok.");
            }
          })
          .then((data) => {
            if (data.success) {
              setHelpContent(updatedItem);
              setOriginalContent(editContent); // Update original content to new content
              setIsEditing(false);
            } else {
              console.error("Failed to update data:", data.error);
            }
          })
          .catch((error) => {
            console.error("Error updating data:", error);
          });
      } else {
        console.error("helpContent is null or undefined.");
      }
    } else {
      setIsEditing(false); // No changes detected, just exit edit mode
    }
  };
  

  const handleBackClick = () => {
    setRelatedPages([]);
    setHelpContent(null);
    setShowBackButton(false);
    navigate(`/help`); // Navigate back to the main help page
  };

  const handleItemClick = (item: MenuItem) => {
    if (["Jobs", "Properties", "Drugs"].includes(item.title)) {
      setRelatedPagesTitle(item.title);
      setLastClickedTitle(item.title);

      // Fetch all help info data
      fetch(`https://ss-rp.com/api/serverinfo/helpinfo`)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data from the API");
          }
          return response.json();
        })
        .then((data) => {

          if (data.success) {
            const related = data.data.filter(
              (relatedItem: MenuItem) =>
                relatedItem.parentId === item.id && relatedItem.type === 1
            );
     
            setRelatedPages(related);
            setHelpContent(null);
            setShowBackButton(true); // Show the back button for categories with subcategories

            navigate(`/help/${item.id}`);
          } else {
            console.error("API returned an error:", data.error);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    } else {
      setRelatedPagesTitle("Related Pages");
      setLastClickedTitle("");
      setRelatedPages([]);
      setHelpContent(item);
      setShowBackButton(false); // Hide the back button for other categories

      const encodedTitle = encodeURIComponent(item.title.replace(/ /g, "-"));
      navigate(`/help/${encodedTitle}`);
    }
  };

  useEffect(() => {
    const fetchHelpInfo = () => {
      fetch("https://ss-rp.com/api/serverinfo/helpinfo")
        .then((response) => {
          if (!response.ok) {
            throw new Error("Failed to fetch data from the API");
          }
          return response.json();
        })
        .then((data) => {
          if (data.success) {
            setMenuItems(data.data);

            if (id) {
              const isNumericId = /^\d+$/.test(id);

              if (isNumericId) {
                const selectedItem = data.data.find(
                  (item: MenuItem) => item.id === parseInt(id)
                );

                if (selectedItem) {
                  setHelpContent(selectedItem);

                  const related = data.data.filter(
                    (relatedItem: MenuItem) =>
                      relatedItem.parentId === selectedItem.id &&
                      relatedItem.type === 0
                  );
                  setRelatedPages(related);
                } else {
                  console.error("Item with the specified numeric ID not found.");
                }
              } else {
                const selectedItem = data.data.find(
                  (item: MenuItem) => item.title === id
                );

                if (selectedItem) {
                  setHelpContent(selectedItem);

                  const related = data.data.filter(
                    (relatedItem: MenuItem) =>
                      relatedItem.parentId === selectedItem.id &&
                      relatedItem.type === 0
                  );
                  setRelatedPages(related);
                }
              }
            } else {
              // Default behavior when there is no valid id
              setHelpContent(null);
              setRelatedPages([]);
            }
          } else {
            console.error("API returned an error:", data.error);
          }
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    fetchHelpInfo();
  }, [id]);

  useEffect(() => {
    setRelatedPagesTitle(lastClickedTitle);
  }, [lastClickedTitle]);

  const formatContent = (content: string) => {
    return content
      .replace(/\{([A-Fa-f0-9]{6})\}/g, '<span style="color: #$1;">')
      .replace(/\r\n|\n/g, "<br />")
      .replace(
        /Introduction/g,
        '<span class="highlight-black">Introduction</span>'
      );
  };

  return (
    <Container>
      <Card>
        <LeftPanel>
          <Heading>Help Topics</Heading>
          <TopicList>
            {menuItems
              .filter((item) => item.parentId === 1)
              .map((item) => (
                <TopicListItem key={item.id}>
                  <Button onClick={() => handleItemClick(item)}>
                    {removeHyphens(item.title)}
                  </Button>
                </TopicListItem>
              ))}
          </TopicList>
        </LeftPanel>
        <RightPanel>
          {relatedPages.length > 0 && (
            <>
              <Headings>{relatedPagesTitle}</Headings>
              <div>

              <TopicList>
                {relatedPages.map((relatedItem) => (
                  <TopicListItems2 key={relatedItem.id}>
                    <Button onClick={() => handleItemClick(relatedItem)}>
                      {removeHyphens(relatedItem.title)}
                    </Button>
                  </TopicListItems2>
                ))}
              </TopicList>
              {["Jobs", "Properties", "Drugs"].includes(
                helpContent?.title || ""
              )}
                 </div>
            </>
          )}

          {helpContent && !relatedPages.length && (
            <>
              {isEditing ? (
                <div>
                  <textarea
                    value={editContent}
                    onChange={(e) => setEditContent(e.target.value)}
                    style={{ width: "100%", height: "200px" }}
                  />
                  <BackButton onClick={handleSaveClick}>Save</BackButton>
                  <BackButton onClick={handleCancelClick}>Cancel</BackButton>
                </div>
              ) : (
                <>
                  <Heading>{removeHyphens(helpContent.title)}</Heading>
                  <div
                    className="help-content"
                    dangerouslySetInnerHTML={{
                      __html: formatContent(helpContent.content),
                    }}
                  />
                  {!!userProp && userProp.user?.role! >= UserRole.Admin && (
                    <BackButton className="back-btn" onClick={handleEditClick}>
                      Edit
                    </BackButton>
                    
                  )}
                   <BackButton onClick={handleBackClick}>Back</BackButton>
                </>
              )}
            </>
          )}

          {!helpContent && !relatedPages.length && <div>Select a category...</div>}
        </RightPanel>
      </Card>
    </Container>
  );
}
