import React, { useState, useEffect, useContext } from 'react';
import { Faction } from '../../Networking/Interface/Faction/FactionInterface';
import { SunsetAPIFactionContext } from '../../Networking/Interface/Faction/FactionApiProvider';

interface FactionSearchProps {
    onSelectFactionTag: (tag: string) => void;
}

export const FactionSearch: React.FC<FactionSearchProps> = ({ onSelectFactionTag }) => {
    const [query, setQuery] = useState('');
    const [factions, setFactions] = useState<Faction[]>([]);
    const api = useContext(SunsetAPIFactionContext);
    const [inputFocused, setInputFocused] = useState(false);
    const getFactionsByPartialName = async (name: string) => {
        let response = await api!.getFactionsByPartialName(name);
        if (response.success) {
            setFactions(response.data!);
        }
    };

    useEffect(() => {
        if (query.length >= 4) {
            getFactionsByPartialName(query);
        } else {
            setFactions([]);
        }
    }, [query]);

    const handleSelectFaction = (faction: Faction) => {
        onSelectFactionTag(faction.tag);
        setQuery(faction.name);
        setFactions([]); 
    };
    const handleBlur = () => {
        
        setTimeout(() => {
            setInputFocused(false);
            if (!inputFocused) setFactions([]);
        }, 110);
    };
    const handleFocus = () => {
        setInputFocused(true);
        if (query.length >= 4) {
            getFactionsByPartialName(query);
        }
    };

    return (
        <div className="faction-search">
            <input
                type="text"
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Search for a faction"
                className="faction-input"
                onBlur={handleBlur}
                onFocus={handleFocus}
            />
            {inputFocused && query.length >= 4 && factions.length > 0 && (
                <div className="faction-dropdown">
                    {factions.map((faction) => (
                        <div
                            key={faction.id}
                            onClick={() => handleSelectFaction(faction)}
                            className="faction-item"
                        >
                            {faction.name}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};
