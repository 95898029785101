import React, { useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FactionMember, FactionRank } from '../../Networking/Interface/Faction/FactionInterface';
import './FactionMembersView.css';
import { SunsetAPIFactionContext } from '../../Networking/Interface/Faction/FactionApiProvider';

interface FactionMemberViewProps {
    tag:string
}

export const FactionMembersView: React.FC<FactionMemberViewProps> = ({tag}) => {
    const [members, setMembers] = useState<FactionMember[]>([]);
    const [ranks, setRanks] = useState<FactionRank[]>([]);
    const api = useContext(SunsetAPIFactionContext);

    useEffect(() => {
        const fetchData = async () => {
            let membersResponse = await api!.getFactionMembersByTag(tag);
            let ranksResponse = await api!.getFactionRanksByTag(tag);

            if (membersResponse.success) setMembers(membersResponse.data!);
            if (ranksResponse.success) setRanks(ranksResponse.data!);
        };
        fetchData();
    }, [api, tag]);

    const [collapsedRanks, setCollapsedRanks] = useState<{ [key: number]: boolean }>({});

    const toggleCollapse = (rankId: number) => {
        setCollapsedRanks(prev => ({ ...prev, [rankId]: !prev[rankId] }));
    };
    const getUserStatus = (lastSessionStart: Date, lastSessionEnd: Date): string => {
        if (lastSessionStart === lastSessionEnd) return 'online';

        const now = new Date();
        const lastOnline = new Date(lastSessionEnd);
        const differenceInDays = Math.floor((now.getTime() - lastOnline.getTime()) / (1000 * 3600 * 24));

        const statuses = [
            { maxDays: 1, status: 'one-day' },
            { maxDays: 2, status: 'two-days' },
            { maxDays: 3, status: 'three-days' },
            { maxDays: 5, status: 'five-days' },
            { maxDays: 7, status: 'seven-days' },
        ];

        for (let { maxDays, status } of statuses) {
            if (differenceInDays <= maxDays) return status;
        }
        return 'over-seven-days';
    };

    return (
         
        <div className="members-container">
            <Legend />
            {ranks.sort((a, b) => b.rankLevel - a.rankLevel).map((rank) => {
                const rankMembers = members.filter((member) => member.rankID === rank.id);
                return (
                    <div key={rank.id} className="rank-section">
                        <h4
                            className="rank-title"
                            onClick={() => toggleCollapse(rank.id)}
                            aria-expanded={!collapsedRanks[rank.id]}
                            role="button"
                        >
                            {collapsedRanks[rank.id] ? "▼" : "▲"} {rank.rankName} - Level: {rank.rankLevel} ({rankMembers.length} members)
                        </h4>
                        {!collapsedRanks[rank.id] &&
                            <div className="members-grid">
                                {rankMembers.map((member) => (   // Corrected line: Changed 'members' to 'rankMembers'
                                    <Link to={`/accounts/${member.userID}/${member.username}/`} key={member.userID} className="member-card">
                                        <div className={`avatar-wrapper ${getUserStatus(member.lastSessionStart, member.lastSessionEnd)}`}>
                                            <img
                                                src={`../samp/headshots/${member.skinID}.png`}
                                                alt={member.characterName}
                                                className="member-avatar"
                                            />
                                        </div>
                                        <p className="member-name">{member.characterName}</p>
                                        <span className="visually-hidden">{getUserStatus(member.lastSessionStart, member.lastSessionEnd)}</span>
                                    </Link>
                                ))}
                            </div>
                        }
                    </div>
                );
            })}

        </div>
    );
};
export const Legend: React.FC = () => {
    return (
        <div className="legend">
            <div className="legend-item">
                <div className="legend-indicator online"></div>Online
            </div>
            <div className="legend-item">
                <div className="legend-indicator one-day"></div>1 Day
            </div>
            <div className="legend-item">
                <div className="legend-indicator two-days"></div>2 Days
            </div>
            <div className="legend-item">
                <div className="legend-indicator three-days"></div>3 Days
            </div>
            <div className="legend-item">
                <div className="legend-indicator five-days"></div>5 Days
            </div>
            <div className="legend-item">
                <div className="legend-indicator seven-days"></div>7 Days
            </div>
            <div className="legend-item">
                <div className="legend-indicator over-seven-days"></div>Over 7 Days
            </div>
        </div>
    );
};