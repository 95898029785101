
import React from 'react';
import styles from './Wrapper.module.css';

type WrapperProps = {
    width?: string;
    children: React.ReactNode;
};

const Wrapper: React.FC<WrapperProps> = ({ width, children }) => {
    return (
        <div className={styles.wrapper} style={{ maxWidth: width }}>
            {children}
        </div>
    );
};

export default Wrapper;
