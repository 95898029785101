import React, { useContext, useState } from "react";
import { Modal } from "@mui/material";
import styled from "styled-components";
import { UserContext } from "../../User/UserContext";
import { Button } from "antd";
import { LauncherAPIContext } from "../../Networking/LauncherAPIProvider";

const CenteredH3 = styled.h3`
  text-align: center;
  margin-top: 15px;
`;

interface ForceUserProps {
  userId: number;
  onSuccess: () => void;
}

function ForceUser({ userId, onSuccess }: ForceUserProps) {
  const [modalOpen, setModalOpen] = useState(false);
  const api = useContext(LauncherAPIContext);
  const { user } = useContext(UserContext);

  async function submitForce() {
    try {
      const response = await api!.forceUser(userId);

      if (response.success) {
        onSuccess();
        setModalOpen(false);
      } else {
        console.error("Failed to force user to use the launcher");
      }
    } catch (error) {
      console.error("Error sending force request:", error);
    }
  }

  const openModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  if (user && user.role > 3) {
    return (
      <div>
        <Button style={{ border: "none" }} onClick={openModal}>
          Force User
        </Button>

        <Modal open={modalOpen} onClose={handleCloseModal}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "600px",
              height: "220px",
              maxHeight: "90vh",
              overflow: "auto",
              backgroundColor: "rgba(255, 255, 255, 0.76)",
              padding: "20px",
            }}
          >
            <CenteredH3>Force {userId} to Use Launcher</CenteredH3>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                style={{ backgroundColor: "orange" }}
                type="primary"
                onClick={submitForce}
              >
                Confirm
              </Button>
              <Button onClick={handleCloseModal}>Cancel</Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  } else {
    return null;
  }
}

export default ForceUser;
