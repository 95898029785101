import React from 'react';
import styles from './SunsetLogo.module.css'; // Ensure this is the correct path to the CSS module

interface SunsetLogoProps {
    slogan: string;
}

export const SunsetLogo: React.FC<SunsetLogoProps> = ({ slogan }) => {
    return (
        <div className={styles.container}>
            <img src="/logo2.png" alt="Sunset Logo" className={styles.logo} />
            <div className={styles.slogan}>{slogan}</div>
        </div>
    );
};
