import DropdownCheckbox from "../../Components/DropdownCheckbox";
import { FilterData, LogPermission } from "../../Networking/Interface/Logs/LogInterface";
import { LogType } from "../../Utils/enums";

interface FilterItemProps {
    filter: FilterData;
    idx: number;
    onChange: (index: number, key: keyof FilterData, value: any) => void;
    onRemove: (index: number) => void;
    allowedOptions: LogPermission[];
}

export const FilterItem: React.FC<FilterItemProps> = ({ filter, idx, onChange, onRemove, allowedOptions }) => {
    const getSubcategoriesByType = (type: LogType) => {
        return [...new Set(
            allowedOptions
                .filter(option => option.logType === type)
                .flatMap(option => option.subcategories)
        )];
    };

    return (
        <div className="filter-set">
            <input
                className="u-input"
                type="text"
                value={filter.fromID || ""}
                onChange={e => onChange(idx, 'fromID', e.target.value)}
                placeholder="After log ID..."
            />
            <select
                className="u-select log-select"
                value={filter.type}
                onChange={e => onChange(idx, 'type', parseInt(e.target.value))}
            >
                {Array.from(new Set(allowedOptions.map(option => option.logType))).map(type => (
                    <option key={type} value={type}>
                        {LogType[type]}
                    </option>
                ))}
            </select>
            <DropdownCheckbox
                options={getSubcategoriesByType(filter.type)}
                selected={filter.subcategories}
                onChange={(values) => onChange(idx, 'subcategories', values)}
            />
            <input
                className="u-input log-search"
                type="text"
                value={filter.log}
                onChange={e => onChange(idx, 'log', e.target.value)}
                placeholder="Search log text..."
            />
            <button onClick={() => onRemove(idx)} className="remove-filter">x</button>
        </div>
    );
};
