import React, { useState } from "react";
import { useToast } from "../Components/Toast/ToastContext";
import { ToastType } from "../Components/Toast/Toast";
import { Button } from "antd";

const ActiveProcesses: React.FC<{ userId: number }> = ({ userId }) => {
  const [processes, setProcesses] = useState<any>(null);
  const { showToast } = useToast();

  const handleLoadProcesses = async () => {
    try {
      const response = await fetch(
        `https://ss-rp.com/exp-api/launcher/processes/list/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
        }
      );

      if (response.ok) {
        const text = await response.text();
        if (text) {
          const data = JSON.parse(text);
          setProcesses(data);
          showToast(ToastType.Success, "Process requested", 3);
        } else {
          showToast(ToastType.Information, "Process requested", 3);
        }
      } else {
        console.error("Failed to fetch processes data");
        showToast(ToastType.Error, "Failed to fetch processes data", 3);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      showToast(ToastType.Error, "Error fetching data", 3);
    }
  };

  return (
    <Button style={{ border: "none" }} onClick={handleLoadProcesses}>
      Request Processes
    </Button>
  );
};

export default ActiveProcesses;
