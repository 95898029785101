
import React from "react";
import { User } from "./User";
const defaultRefetchUser = () => { };
interface UserContextType {
    user: User | undefined;
    setUser: React.Dispatch<React.SetStateAction<User | undefined>>;
    isLoading: boolean;
    refetchUser: () => void;
}

const defaultSetUser: React.Dispatch<React.SetStateAction<User | undefined>> = () => { };

export const UserContext = React.createContext<UserContextType>({
    user: undefined,
    setUser: defaultSetUser,
    isLoading: true,
    refetchUser: defaultRefetchUser
});