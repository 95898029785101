import React from "react";

interface DateInputProps {
    label: string;
    value: Date;
    onChange: (date: Date) => void;
}

export const DateInput: React.FC<DateInputProps> = ({ label, value, onChange }) => {
    const inputId = `date-input-${label.replace(/\s+/g, '-').toLowerCase()}`; // Generate a unique id based on label

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        if (inputValue.length === 16) {
            try {
                const newDate = new Date(inputValue + "Z");

                if (!isNaN(newDate.getTime())) {
                    onChange(newDate);
                }
            } catch (error) {
                console.error("Invalid date input", error);
            }
        }
    };

    return (
        <>
            <label className="u-label" htmlFor={inputId}>{label}:</label>
            <input
                id={inputId}
                type="datetime-local"
                className="u-input log-dates"
                value={value.toISOString().substring(0, 16)}
                onChange={handleInputChange}
            />
        </>
    );
};
