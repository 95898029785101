import React from 'react';
import styles from './Footer.module.css';

interface FooterProps {
    creditedPeople: string[];
}

const Footer: React.FC<FooterProps> = ({ creditedPeople }) => {
    return (
        <footer className={styles.footer}>
            <div className={styles.content}>
                
                <span className={styles.credited}>
                    <strong>Website designed by:</strong> {creditedPeople.join(', ')}
                </span>
            </div>
        </footer>
    );
};

export default Footer;