
import styles from './StaffActivityCard.module.css';
import { StaffInfo } from '../../Networking/Interface/Management/StaffActivityInterfaces';
import InfoLineView, { InfoLine } from '../../Components/InfoLineView';
import Description from '../../Components/Description';
import Information from '../../Components/Information';
import { getRoleString, UserRole } from '../../Utils/enums';
import UserRoleBadge from '../../Components/UserRoleBadge';

interface StaffActivityCardProps {
    staffInfo: StaffInfo;
}

export const StaffActivityCard: React.FC<StaffActivityCardProps> = ({ staffInfo }) => {
    const { username, userRole, pfp, staffActivitySummary, staffPunishmentSummary, staffTicketSummary} = staffInfo;
    const profilePicture = pfp ? `${pfp}` : `${process.env.PUBLIC_URL}/Staff/staff-default.png`;
    const { label, color } = formatDateDifference(staffActivitySummary?.lastOnline);
    return (
        <div className={styles.staffCard}>
            <div className={styles.lastOnlineBadge} style={{ backgroundColor: color }}>
                {label}
            </div>
            <div className={styles.profileSection}>
                <div className={styles.profilePicWrapper}>
                    <img src={profilePicture} alt="Staff Profile" className={styles.profilePic} />
                </div>
                <h3 className={styles.staffName}>{username || 'Unknown Staff'}</h3>
                <UserRoleBadge role={userRole}></UserRoleBadge>
            </div>

            <div className={styles.activitySection}>
                <div className={styles.activityItem}>
                    <img src={`${process.env.PUBLIC_URL}/Staff/warn.png`} alt="Warnings" className={styles.icon} />
                    <span>{staffPunishmentSummary ? staffPunishmentSummary.totalWarns : 0}</span>
                </div>
                <div className={styles.activityItem}>
                    <img src={`${process.env.PUBLIC_URL}/Staff/jail.png`} alt="AJails" className={styles.icon} />
                    <span>{staffPunishmentSummary ? staffPunishmentSummary.totalAJails : 0}</span>
                </div>
                <div className={styles.activityItem}>
                    <img src={`${process.env.PUBLIC_URL}/Staff/kick.png`} alt="Kicks" className={styles.icon} />
                    <span>{staffPunishmentSummary ? staffPunishmentSummary.totalKicks : 0}</span>
                </div>
                <div className={styles.activityItem}>
                    <img src={`${process.env.PUBLIC_URL}/Staff/mute.png`} alt="Mutes" className={styles.icon} />
                    <span>{staffPunishmentSummary ? staffPunishmentSummary.totalMutes : 0}</span>
                </div>
                <div className={styles.activityItem}>
                    <img src={`${process.env.PUBLIC_URL}/Staff/ban.png`} alt="Bans" className={styles.icon} />
                    <span>{staffPunishmentSummary ? staffPunishmentSummary.totalBans : 0}</span>
                </div>
            </div>
            <InfoLineView title="Tickets">
                <InfoLine label="Total Handled" value={staffTicketSummary ? staffTicketSummary.totalHandledTickets : 0} />
                <InfoLine label="Last 7 days" value={staffTicketSummary ? staffTicketSummary.ticketsLast7Days : 0}/>
                <InfoLine label="Avg. Handling Time" value={`${staffTicketSummary ? staffTicketSummary.averageHandlingTime.toFixed(2) : NaN} mins`} />

            </InfoLineView>
         
        </div>
    );
};
interface DateDifference {
    label: string;
    color: string;
}

const formatDateDifference = (lastOnline: Date | null | undefined): DateDifference => {
    if (!lastOnline) return { label: "Retired", color: "#808080" }; // Gray for retired

    const lastOnlineDate = new Date(lastOnline);
    const now = new Date();
    const differenceInTime = now.getTime() - lastOnlineDate.getTime(); // Use getTime() for TypeScript
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    let label: string, color: string;
    // Default color for online status within 6 days
    color = "#333"; // Dark Gray as the default background color

    if (differenceInDays < 1) {
        label = "Today";
    } else if (differenceInDays < 3) {
        label = `Online: ${Math.floor(differenceInDays)}d ago`;
    } else if (differenceInDays < 7) {
        label = `Online: ${Math.floor(differenceInDays)}d ago`;
    } else {
        label = `Online: ${Math.floor(differenceInDays / 7)}w ago`;
        color = "#8B0000"; // Dark Red for 6+ days
    }

    return { label, color };
};
