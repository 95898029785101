import React, { useContext, useEffect, useState } from "react";
import InfoLineView, { InfoLine } from "../../Components/InfoLineView";
import { DisconnectReason } from "../../Utils/enums";
import { formatDateForDisplay } from "../../Utils/DateTimeUtils";
import PunishmentsView from "../../Staff/Actions/PunishmentView";

import {
  AccountDTO,
  AccountSessionDTO,
  CharacterDTO,
} from "../../Networking/Interface/Account/AccountInterface";
import { TabPane, Tabs } from "../../Components/Tabs";
import { AccountActivityView } from "./AccountActivityView";
import CharacterTab from "./CharacterTab";
import { UserRole } from "../../Utils/enums";
import { UserContext } from "../../User/UserContext";
import { SunsetAPIAccountContext } from "../../Networking/Interface/Account/AccountAPIProvider";
import NotesLookup from "../../Staff/Actions/NotesLookup";

interface AccountStatsProps {
  accountDTO: AccountDTO;
  lastSession: AccountSessionDTO;
}

export const AccountStats: React.FC<AccountStatsProps> = ({
  accountDTO,
  lastSession,
}) => {
  const api = useContext(SunsetAPIAccountContext);
  const [characters, setCharacters] = useState<CharacterDTO[]>([]);
  const [activeTab, setActiveTab] = useState<number | null>(null);
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchData = async () => {
      const response = await api?.getCharactersByAccountIDAndNAme(
        accountDTO.id,
        accountDTO.username
      );
      if (response?.success && response.data) {
        setCharacters(response.data);
        setActiveTab(response.data[0]?.id || null);
      }
    };

    fetchData();
  }, [api, accountDTO]);

  const setCharacterName = (characterId: number, newName: string) => {
    // Add the logic to set the character name here
    // This could involve making an API call to update the character name
    // console.log(`Set character name for character ID ${characterId} to ${newName}`);
  };

  return (
    <>
      <Tabs>
        <TabPane tabKey="0" tabTitle="Overview">
          <hr />
          {lastSession && (
            <div className="flex-container">
              <InfoLineView title="Last session">
                {lastSession.isOnline ? (
                  <>
                    <InfoLine
                      label="Online Since:"
                      value={formatDateForDisplay(new Date(lastSession.start))}
                    />
                    <InfoLine
                      label="Playing As:"
                      value={lastSession.characterName}
                    />
                  </>
                ) : (
                  <>
                    <InfoLine
                      label="Last Online:"
                      value={formatDateForDisplay(new Date(lastSession.end))}
                    />
                    <InfoLine
                      label="Last Played As:"
                      value={lastSession.characterName}
                    />
                    <InfoLine
                      label="Reason:"
                      value={DisconnectReason[lastSession.disconnectReason]}
                    />
                  </>
                )}
              </InfoLineView>
              <InfoLineView title="Playtime stats">
                <InfoLine
                  label="Total playtime:"
                  value={(accountDTO.totalPlaytime / 60).toFixed(2) + "h"}
                />
                <InfoLine
                  label="Registration Date:"
                  value={formatDateForDisplay(
                    new Date(accountDTO.registrationDate)
                  )}
                />
              </InfoLineView>
            </div>
          )}
          <hr />
          <CharacterTab
            setCharacterName={setCharacterName}
            characters={characters}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />
        </TabPane>
        {((!!user && user.role >= UserRole.Mod) ||
          (!!user && accountDTO.username === user.username)) && (
          <TabPane tabKey="1" tabTitle="Staff">
            <NotesLookup account={accountDTO}></NotesLookup>
            <PunishmentsView account={accountDTO}></PunishmentsView>
            <AccountActivityView accountDTO={accountDTO}></AccountActivityView>
          </TabPane>
        )}
      </Tabs>
    </>
  );
};
