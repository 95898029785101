import React, { useContext, useEffect, useState } from 'react';
import { CrateOpening } from '../../Networking/Interface/Gamba/GambaInterface';
import styles from './RewardClaimView.module.css';
import rewardStyles from './CrateRewardView.module.css';
import { Modal2 } from '../../Components/Modal2';
import { UserContext } from '../../User/UserContext';
import CharacterSelection from '../../Components/CharacterSelection';
import Description from '../../Components/Description';
import GoldShinyButton from '../../Components/GoldShinyButton';
import Confetti from '../../Components/Confetti';
import { useToast } from '../../Components/Toast/ToastContext';
import { ToastType } from '../../Components/Toast/Toast';
import { SunsetAPIGambaContext } from '../../Networking/Interface/Gamba/GambaAPIProvider';
import { SunsetAPIAccountContext } from '../../Networking/Interface/Account/AccountAPIProvider';
import { CharacterDTO } from '../../Networking/Interface/Account/AccountInterface';

interface DisplayItemProps {
    wonItem: CrateOpening;
}

const RewardClaimView: React.FC<DisplayItemProps> = ({ wonItem }) => {
    const api = useContext(SunsetAPIGambaContext);
    const api2 = useContext(SunsetAPIAccountContext);
    const { user, isLoading } = useContext(UserContext);
    const { showToast } = useToast();
    const [characters, setCharacters] = useState<CharacterDTO[]>([]);
    const [showModal, setShowModal] = useState<boolean>(true);
    const [selectedCharacter, setSelectedCharacter] = useState<CharacterDTO>();

    const confettiSettings: { [key: number]: { density: number } } = {
        1: { density: 100 },
        2: { density: 300 },
        3: { density: 600 },
        4: { density: 1000 }
    };

    const getRarityClass = (rarity: number) => {
        const rarityClasses: { [key: number]: string } = {
            1: rewardStyles.common,
            2: rewardStyles.uncommon,
            3: rewardStyles.rare,
            4: rewardStyles.legendary
        };
        return rarityClasses[rarity] || '';
    };

    const raritySounds: { [key: number]: string } = {
        1: "/raritysound1.mp3",
        2: "/raritysound2.mp3",
        3: "/raritysound3.mp3",
        4: "/raritysound4.mp3"
    };

    const playRaritySound = (rarity: number) => {
        const audio = new Audio(raritySounds[rarity]);
        audio.play();
    };

    useEffect(() => {
        const timerId = setTimeout(() => {
            playRaritySound(wonItem.crateRewardWon.rarity);
        }, 500);

        const fetchCharacters = async () => {
            if (user) {
                const charactersResponse = await api2?.getCharactersByAccountIDAndNAme(Number(user.id), user.username);
                if (charactersResponse?.success) {
                    setCharacters(charactersResponse.data || []);
                }
            }
        };

        if (!isLoading && user) {
            fetchCharacters();
        }

        return () => clearTimeout(timerId);
    }, [wonItem, user, isLoading, api2]);

    const handleClaim = async () => {
        if (!wonItem.crateRewardWon.reward.autoHandout || !selectedCharacter) {
            return;
        }

        const collectResponse = await api?.collectCrateRewards(wonItem.crateOpeningId, selectedCharacter.id);
        if (collectResponse?.success) {
            showToast(ToastType.Success, "Items transferred ingame!", 12);
        } else {
            showToast(ToastType.Error, collectResponse?.error!, 12);
        }
        setShowModal(false);
    };

    return (
        <>
            <Confetti density={confettiSettings[wonItem.crateRewardWon.rarity].density} />
            {showModal && (
                <Modal2 onClose={() => setShowModal(false)}>
                    <div className={styles.modalOverlay}>
                        <div className={`${styles.displayRewardCard} ${styles.modalContent} ${rewardStyles.rewardCard} ${rewardStyles.rewardBigCard} ${getRarityClass(wonItem.crateRewardWon.rarity)}`}>
                            {wonItem.crateRewardWon.reward.imageUrl && (
                                <img
                                    src={`${process.env.PUBLIC_URL}/gamba/${wonItem.crateRewardWon.reward.imageUrl}`}
                                    alt={wonItem.crateRewardWon.reward.name}
                                    className={styles.rewardImage}
                                />
                            )}
                            {wonItem.crateRewardWon.quantity > 1 && (
                                <span className={rewardStyles.quantity}>{wonItem.crateRewardWon.quantity}x</span>
                            )}
                            <h3 className={styles.itemTitle}>
                                You won: {wonItem.crateRewardWon.quantity}x {wonItem.crateRewardWon.reward.name}
                            </h3>
                            <p className={styles.itemDescription}>{wonItem.crateRewardWon.reward.description}</p>
                            <hr />
                            {wonItem.crateRewardWon.reward.autoHandout ? (
                                <>
                                    <CharacterSelection characters={characters} onCharacterSelect={setSelectedCharacter} />
                                    <Description mode="Dark" size={20} text="Please choose a character to obtain the said rewards and then press claim." />
                                </>
                            ) : (
                                <Description mode="Dark" size={21} text="Please contact an admin ingame or on discord to receive this reward" />
                            )}
                            <GoldShinyButton text={wonItem.crateRewardWon.reward.autoHandout ? "Claim Rewards" : "OK"} onClick={handleClaim} />
                        </div>
                    </div>
                </Modal2>
            )}
        </>
    );
};

export default RewardClaimView;
