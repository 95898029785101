import React, { useState, useEffect, useContext } from "react";
import { InfinitySpin } from "react-loader-spinner";
import { ToastType } from "../../Components/Toast/Toast";
import { useToast } from "../../Components/Toast/ToastContext";
import { SunsetAPIContext } from "../../Networking/ApiProvider";
import { CreditCostCalculator } from "./CreditCostCalculator";
import styled, { css, keyframes } from "styled-components";
import styles from "./BalanceTopUpView.module.css";
import CustomAmountSection from "./Custom";
import PreMadePackagesSection from "./Premade";
import { CreditModal } from "../../Components/CreditModal";


// Animation for highlighting the selected section
const highlight = keyframes`
  0% {
    transform: scale(1);
    box-shadow: none;
  }
  50% {
    transform: scale(1.02);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  100% {
    transform: scale(1);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
`;

// Styled component for the sections
const Section = styled.div<{ active: boolean }>`
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 15px;
  ${(props) =>
    props.active &&
    css`
      animation: ${highlight} 0.6s ease-in-out forwards;
      background-color: #f0f8ff;
      border-radius: 8px;
      padding: 10px;
    `}
`;
const StyledHeading = styled.h2`
  margin-bottom: 10px;
`;

interface ConversionRates {
  USD: number;
  EUR: number;
  GBP: number;
}

interface BalanceTopUpViewProps {
  onClose: () => void;
  conversionRates: ConversionRates;
}

const BalanceTopUpViewSixy: React.FC<BalanceTopUpViewProps> = ({
  onClose,
  conversionRates,
}) => {
  const [inputAmount, setInputAmount] = useState<string>("");
  const [selectedCurrency, setSelectedCurrency] = useState<string>("USD");
  const [priceGBP, setPriceGBP] = useState<number>(3);
  const [selectedCreditOption, setSelectedCreditOption] = useState<
    number | null
  >(null);
  const [creditAmount, setCreditAmount] = useState<number>(0);
  const [creditSectionActive, setCreditSectionActive] = useState<boolean>(true);
  const [buyLoad, setBuyLoad] = useState<boolean>(false);
  const [selectedPaymentMethodCustom, setSelectedPaymentMethodCustom] =
    useState<string>("");
  const [selectedPaymentMethodPremade, setSelectedPaymentMethodPremade] =
    useState<string>("");
  const [customSectionActive, setCustomSectionActive] = useState<boolean>(true);

  const creditCalculator = new CreditCostCalculator(10);
  const api = useContext(SunsetAPIContext);
  const { showToast } = useToast();

  useEffect(() => {
    if (!inputAmount) return;

    const parsedAmount = parseFloat(inputAmount) || 0;
    const amountInGBP =
      selectedCurrency === "GBP"
        ? parsedAmount
        : selectedCurrency === "USD"
        ? parsedAmount / conversionRates.USD
        : parsedAmount / conversionRates.EUR;

    const calculatedPrice = creditCalculator.calculateCreditCost(amountInGBP);
    setPriceGBP(calculatedPrice);
  }, [inputAmount, selectedCurrency, conversionRates, creditCalculator]);

  useEffect(() => {
    if (selectedCreditOption) {
      setCreditAmount(selectedCreditOption);
    } else if (inputAmount && !isNaN(parseFloat(inputAmount))) {
      setCreditAmount(parseFloat(inputAmount));
    } else {
      setCreditAmount(0);
    }
  }, [selectedCreditOption, inputAmount]);

  const handleCreditOptionClick = (credits: number) => {
    setSelectedCreditOption(credits);
    setInputAmount("");
    setSelectedPaymentMethodCustom("");
    setSelectedPaymentMethodPremade("");
    setCreditSectionActive(true);
  };

  const handlePaymentMethodSelectCustom = (method: string) => {
    setSelectedPaymentMethodCustom(method);
    setSelectedPaymentMethodPremade(""); // Clear payment method for Premade section
    setCreditSectionActive(false); // Ensure Custom section is active
    setCustomSectionActive(true);
  };

  const handlePaymentMethodSelectPremade = (method: string) => {
    setSelectedPaymentMethodPremade(method);
    setSelectedPaymentMethodCustom("");
    setCreditSectionActive(true);
    setCustomSectionActive(false);
  };

  const handleCustomSectionActivate = () => {
    setSelectedCreditOption(null);
    setInputAmount("");
    setSelectedPaymentMethodCustom("");
    setSelectedPaymentMethodPremade("");
    setCreditSectionActive(false);
    setCustomSectionActive(true);
  };

  const handlePreMadeSectionActivate = () => {
    setSelectedCreditOption(null);
    setSelectedPaymentMethodCustom("");
    setSelectedPaymentMethodPremade("");
    setCreditSectionActive(true);
    setCustomSectionActive(false);
  };

  const buyCreditsWithStripe = async () => {
    try {
      setBuyLoad(true);

      let creditAmountString: string;
      let selectedPackage: string;
      let priceInGBP: number;
      let selectedPaymentMethod: string;

      if (selectedCreditOption) {
        creditAmountString = selectedCreditOption.toString();
        selectedPackage = "Pre-made Package";
        priceInGBP = parseFloat(creditAmountString);
        selectedPaymentMethod = selectedPaymentMethodPremade;
      } else if (inputAmount && !isNaN(parseFloat(inputAmount))) {
        creditAmountString = parseFloat(inputAmount).toString();
        selectedPackage = "Custom credits";
        priceInGBP = parseFloat(inputAmount) / 10;
        selectedPaymentMethod = selectedPaymentMethodCustom;
      } else {
        throw new Error("No valid credit amount selected or entered.");
      }

      const paymentMethod = selectedPaymentMethod;
      const userName = "John Doe";
      const userEmail = "john.doe@example.com";
      let url = `http://localhost:3001/exp-api/payment/create-checkout-session/${creditAmountString}`;

      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          paymentMethod,
          selectedPackage,
          userName,
          userEmail,
          amount: priceInGBP.toFixed(2),
        }),
      });

      const responseData = await response.json();

      if (response.ok) {
        window.location.href = responseData.data;
      } else {
        setBuyLoad(false);
        showToast(
          ToastType.Error,
          responseData.error || "Failed to process the payment",
          10
        );
      }
    } catch (ex) {
      const errorMessage =
        ex instanceof Error ? ex.message : "An unexpected error occurred";
      setBuyLoad(false);
      showToast(ToastType.Error, errorMessage, 10);
    }
  };
  const buyCreditsWithPayPal = async () => {
    try {
      setBuyLoad(true);

      // Check the selected payment method
      if (
        selectedPaymentMethodPremade === "paypal" ||
        selectedPaymentMethodCustom === "paypal"
      ) {
        // Ensure creditAmount is correctly calculated for custom credits
        if (!creditAmount || creditAmount <= 0) {
          throw new Error("Invalid credit amount");
        }

        const response = await api!.buyCredits(creditAmount * 100);

        if (response.success) {
          // Redirect to the URL provided in response.data
          window.location.href = response.data!;
        } else {
          setBuyLoad(false);
          showToast(
            ToastType.Error,
            response.error || "Failed to process the payment",
            10
          );
        }
      } else {
        setBuyLoad(false);
        showToast(ToastType.Error, "Selected payment method is not PayPal", 10);
      }
    } catch (ex) {
      // Handle any unexpected errors during the API call
      const errorMessage =
        ex instanceof Error ? ex.message : "An unexpected error occurred";
      setBuyLoad(false);
      showToast(ToastType.Error, errorMessage, 10);
    }
  };

  const handleBuyCredits = () => {
    if (
      selectedCreditOption ||
      (inputAmount && !isNaN(parseFloat(inputAmount)))
    ) {
      if (
        selectedPaymentMethodCustom === "stripe" ||
        selectedPaymentMethodPremade === "stripe"
      ) {
        buyCreditsWithStripe();
      } else if (
        selectedPaymentMethodCustom === "paypal" ||
        selectedPaymentMethodPremade === "paypal"
      ) {
        buyCreditsWithPayPal();
      } else {
        // Handle case where no payment method is selected or recognized
        showToast(ToastType.Error, "Please select a payment method", 10);
      }
    } else {
      // Handle case where no credit option or input amount is selected
      showToast(
        ToastType.Error,
        "Please select a credit option or enter an amount",
        10
      );
    }
  };

  return (
    <CreditModal onClose={onClose}>
      <div style={{ color: "black" }} className={styles.modalContent}>
        <StyledHeading>Add Credits</StyledHeading>
        {/* <h3> Select from: </h3> */}
        <Section
          active={creditSectionActive}
          onClick={() => setCreditSectionActive(true)}
        >
          <PreMadePackagesSection
            selectedCreditOption={selectedCreditOption}
            handleCreditOptionClick={handleCreditOptionClick}
            selectedPaymentMethod={selectedPaymentMethodPremade}
            handlePaymentMethodSelect={handlePaymentMethodSelectPremade}
            activateCustomSection={handlePreMadeSectionActivate}
          />
        </Section>
        <h3>Or...</h3>
        <Section
          active={!creditSectionActive}
          onClick={() => setCreditSectionActive(false)}
        >
          <CustomAmountSection
            selectedCreditOption={
              selectedCreditOption ? selectedCreditOption.toString() : ""
            }
            inputAmount={inputAmount}
            setInputAmount={setInputAmount}
            selectedPaymentMethod={selectedPaymentMethodCustom}
            handlePaymentMethodSelect={handlePaymentMethodSelectCustom}
            activateCustomSection={handleCustomSectionActivate}
          />
        </Section>

        {(selectedCreditOption || inputAmount) &&
          (selectedPaymentMethodCustom || selectedPaymentMethodPremade) && (
            <div className={styles.controls}>
              {buyLoad ? (
                <InfinitySpin />
              ) : (
                <button
                  className={styles.confirmButton}
                  onClick={handleBuyCredits}
                >
                  Pay
                </button>
              )}
            </div>
          )}
      </div>
    </CreditModal>
  );
};

export default BalanceTopUpViewSixy;
