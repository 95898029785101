import React from 'react';
import { SunsetAPIFaction } from './SunsetAPIFaction';

interface SunsetAPIProviderProps {
    children: React.ReactNode;
}

const SunsetAPIFactionContext = React.createContext<SunsetAPIFaction | undefined>(undefined);

const SunsetFactionAPIProvider: React.FC<SunsetAPIProviderProps> = ({ children }) => {
    const apiURL = "http://localhost:7203/";
    const api = new SunsetAPIFaction();

    return (
        <SunsetAPIFactionContext.Provider value={api}>
            {children}
        </SunsetAPIFactionContext.Provider>
    );
};

export { SunsetFactionAPIProvider, SunsetAPIFactionContext };