import { useContext, useState } from "react";
import "./login.css";
import { useLocation, useNavigate } from 'react-router-dom';

import './../../App.css';
import { SunsetAPIContext } from "../../Networking/ApiProvider";
import { UserContext } from "../../User/UserContext";




export default function Login(): React.ReactElement {
    const [loggedIn] = useState(false);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState<string | null>(null);
    const navigate = useNavigate();
    const loc = useLocation();
    const from = loc.state?.from || "/";
    const api = useContext(SunsetAPIContext);
    const {refetchUser } = useContext(UserContext);

    const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setError(null);
        const credentials = {
            Username: username,
            Password: password,
        };

        try {
            const response = await api!.login(credentials);
            if (response.success) {
                refetchUser(); 
                navigate(from);
            } else {
                setError(response.error || 'Login failed. Please try again.');
            }
        } catch {
            setError('Login failed. Please try again.');
        }
    };

  

    return (
        <>
        <div className="login-container">
         
            <div className="login-form">
                {!loggedIn && (
                    <form className="login">
                        <h2 > Login </h2>
                    
                        <div className="input-container">
                            <label> Username </label>
                            <input className='login-input'
                                type="text"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div className="input-container">
                            <label>Password</label>
                                <input className="login-input"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <button onClick={handleSubmit} className="login-submit-btn">
                            Login
                        </button>
                        {error && <div className={`error-message ${error ? 'active' : ''}`}>{error}</div>}
                    </form>
                )}
            </div>
            </div>
        </>
    );
}
