import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Description from '../../Components/Description';
import { DescriptionLine, FancyDescription } from '../../Components/FancyDescription';
import { SunsetAPIContext } from '../../Networking/ApiProvider';
import { DonatorPackage } from './Donations';
import styles from './PackageView.module.css';
import { Modal } from '../../Components/Modal';


export const PackageView = () => {
  const [donorPackages, setDonorPackages] = useState<DonatorPackage[]>([]);
  const [selectedPackage, setSelectedPackage] = useState<DonatorPackage | null>(null);
  const api = useContext(SunsetAPIContext);

  useEffect(() => {
    fetch(`./samp/donoPackages.json`)
      .then(response => response.json())
      .then(data => setDonorPackages(data))
      .catch(error => console.error("Error fetching donor packages:", error));
  }, [api]);

  const handleCardClick = (donorPackage: DonatorPackage) => {
    setSelectedPackage(donorPackage);

  };

  const handleCloseModal = () => {
    setSelectedPackage(null);
  };

  return (
    <div className={styles.container}>
      <Description mode="Light" size={20} text="To buy the packages, head ingame and type */donor* .&br Your rewards and crates will be handed automatically. For any issues or questions, contact the staff team." />
      {donorPackages.map(donorPackage => (
        <div
          className={`${styles.donorCard} ${styles[donorPackage.apperance]}`}
          key={donorPackage.id}
          onClick={() => handleCardClick(donorPackage)}
        >
          <img className={styles.packageImage} src={`${donorPackage.apperance}.png`} alt={donorPackage.title} />
          <div className="card">
            <h2>{donorPackage.title}</h2>
            {donorPackage.costAfterDiscount ? (
              <div className={styles.priceContainer}>
                <p>Cost: </p>
                <s className={styles.strikethroughPrice}>{donorPackage.cost}</s>
                <strong>{donorPackage.costAfterDiscount}</strong>
                <img src={`${process.env.PUBLIC_URL}/credit.png`} alt="Credits" className={styles.creditImage} />
              </div>
            ) : (
              <div className={styles.priceContainer}>
                <p>Cost: {donorPackage.cost}</p>
                <img src={`${process.env.PUBLIC_URL}/credit.png`} alt="Credits" className={styles.creditImage} />
              </div>
            )}
          </div>
        </div>
      ))}
      {selectedPackage && (
        <Modal key="donorPackageModal" onClose={handleCloseModal} className={styles.packagemodal}>
          <div className={styles.modalContent}>
            <div
              className={`card ${styles[selectedPackage.apperance]}`}
              key={selectedPackage.id}
            >
              <h3 style={{color: "white", fontSize: "25px", textAlign: "center"}}>{selectedPackage.title}</h3>
            </div>
            <div className={styles.costDetails}>
              <p><strong>Cost:</strong>
                {selectedPackage.costAfterDiscount ? (
                  <> <s>{selectedPackage.cost}</s> <strong>{selectedPackage.costAfterDiscount}</strong></>
                ) : (
                  ` ${selectedPackage.cost}`
                )}
              </p>
            </div>
            <p><strong>Duration:</strong> {selectedPackage.duration}</p>
            <hr />
            <div className={styles.cratesSection}>
              <h3>Crates to receive</h3>
              <div className={styles.cratesContainer}>
                {Object.entries(selectedPackage.crates).map(([crateId, quantity]) => (
                  <Link to={`/crates/${crateId}`} className={styles.crateItem} key={crateId}>
                    <img src={`./gamba/${crateId}.png`} alt={`Crate ${crateId}`} />
                    <p>{quantity}</p>
                  </Link>
                ))}
              </div>
            </div>
            <hr />
            <FancyDescription headline="Benefits">
              {selectedPackage.benefits.map((benefit, index) => (
                <DescriptionLine key={index} text={benefit} />
              ))}
            </FancyDescription>
          </div>
        </Modal>
      )}
    </div>
  );
}
