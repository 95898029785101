import React, { useState } from "react";
import { useToast } from "../Components/Toast/ToastContext";
import { ToastType } from "../Components/Toast/Toast";
import { Button } from "antd";

interface TakeScreenshotProps {
  id: number;
}



const TakeScreenshot: React.FC<TakeScreenshotProps> = ({ id }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const { showToast } = useToast();


  async function handleSS() {
    try {


      const response = await fetch(
        `https://ss-rp.com/exp-api/launcher/screenshot/request/${id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: id }),
          credentials: "include",
        }
      );

      if (response) {
       
        const base64Data = await response.text();
        const imageUrl = `data:image/jpeg;base64,${base64Data}`;
        setImageSrc(imageUrl);
        showToast(ToastType.Success, "Screenshot requested", 3);
      } else {
        showToast(ToastType.Error, "Failed to request screenshot", 3);
      }
    } catch (error) {
    
      console.error("Error fetching data:", error);
      showToast(ToastType.Error, "Error fetching screenshot", 3);
    }
  }

  return (
    <div>
      <Button style={{ border: "none" }} onClick={handleSS}>Request Screenshot</Button>
    </div>
  );
};

export default TakeScreenshot;
