import React, { isValidElement } from "react";
import "./FancyDescription.css";

interface FancyDescriptionProps {
  headline: string;
  children:
    | React.ReactElement<DescriptionLineProps>
    | React.ReactElement<DescriptionLineProps>[];
}

export const FancyDescription: React.FC<FancyDescriptionProps> = ({
  headline,
  children,
}) => {
  React.Children.forEach(children, (child) => {
    if (!isValidElement(child) || child.type !== DescriptionLine) {
      throw new Error(
        "FancyDescription only accepts DescriptionLine as children."
      );
    }
  });

  return (
    <div className="fancyDescription">
      <h2>{headline}</h2>
      {children}
    </div>
  );
};

interface DescriptionLineProps {
  text: string;
}

export const DescriptionLine: React.FC<DescriptionLineProps> = ({ text }) => {
  return (
    <div className="description-line">
      <p>{text}</p>
    </div>
  );
};
