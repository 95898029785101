import React, { useState, useEffect } from "react";
import UserRoleBadge from "../../Components/UserRoleBadge";
import { useNavigate } from "react-router-dom";
import styles from './OnlinePlayers.module.css';
const getAdminLabel = (adminLevel: number): string => {
  switch (adminLevel) {
    case 1:
      return "Helper";
    case 2:
      return "Moderator";
    case 3:
      return "Admin";
    case 4:
      return "Senior Admin";
    case 5:
      return "Lead Admin";
    case 6:
      return "Manager";
    default:
      return "Player";
  }
};

interface PlayerData {
  accountID: number;
  accountName: string;
  adminLevel: number;
  displayName: string;
}


function OnlinePlayersView() {
  const [onlinePlayers, setOnlinePlayers] = useState<PlayerData[]>([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://ss-rp.com/api/ServerStats/OnlinePlayers"
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();

        const updatedData = data.data.map((player: PlayerData) => ({
          ...player,
          adminLabel: getAdminLabel(player.adminLevel),
          accountURL: `./accounts/${player.accountID}/${player.accountName}`,
        }));

        setOnlinePlayers(updatedData);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    const intervalId = setInterval(fetchData, 30000);

    return () => clearInterval(intervalId);
  }, []);

    return (
        <div className={styles.playerContainer}>
            <h1 className={styles.totalPlayers}>
                Online Players: {onlinePlayers.length}
            </h1>
            <ul>
                {onlinePlayers.map((player) => (
                    <li
                        className={styles.playerList}
                        key={player.accountID}
                        onClick={() => {
                            navigate(`/accounts/${player.accountID}/${player.accountName}`);
                        }}
                    >
                        <span className={styles.playerName}>{player.displayName}</span>
                        {player.adminLevel > 0 && (
                            <div className={styles.badge}>
                                <UserRoleBadge role={player.adminLevel} />
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
  
                  }
export default OnlinePlayersView;
