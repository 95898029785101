import React, { useEffect, useCallback } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

interface ModalProps {
  onClose: () => void;
  children: React.ReactNode;
  className?: string; // Add className prop to allow custom styling
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border: 5px red solid;
  height: 100%;
  background: rgba(62, 62, 62, 0.404);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 500;
`;

const ModalContainer = styled.div`
  border-radius: 8px;
  align-items: center;
  margin: 20px 20px;
  display: flex;
  border: yellow 5px solid;
  justify-content: center;
  max-height: 95%;
  overflow: hidden; /* Ensure scrolling for overflow content */
  padding: 20px;
  position: relative; /* Ensure proper positioning */
  background: transparent; /* Add a background to ensure content visibility */
  width: 100%;
  max-width: 500px; /* Set a max-width for better scaling */
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10;
  right: 20px;
  background: green;
  border: yellow 5px solid;
  cursor: pointer;
  z-index: 1000;
  font-size: 46px;
  color: #940808;
`;

export const Modal2: React.FC<ModalProps> = ({
  onClose,
  children,
  className,
}) => {
  const handleEscapeKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", handleEscapeKey);
    document.body.classList.add("modal-open"); // Prevent background scrolling
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
      document.body.classList.remove("modal-open"); // Re-enable background scrolling
    };
  }, [handleEscapeKey]);

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (!(event.target as HTMLElement).closest(".modal-container")) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return ReactDOM.createPortal(
    <>
      <ModalContainer className={`modal-container ${className}`}>
        <CloseButton onClick={onClose}>X</CloseButton>
        {children}
      </ModalContainer>
    </>,
    document.body
  );
};
