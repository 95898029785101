import { useContext, useEffect, useState } from "react";
import { BlackCard } from "../../Components/BlackCard";
import { Faction } from "../../Networking/Interface/Faction/FactionInterface";
import { UserContext } from "../../User/UserContext";
import { FactionCardView } from "./FactionCardView";
import "./FactionListView.css";
import React from "react";


interface MyFactionsProps {
    factions: Faction[]
}
//my factions view
export const MyFactions: React.FC<MyFactionsProps> = ({ factions }) => {
    const userProvider = useContext(UserContext);
    const [myFactions, setMyFactions] = useState<Faction[]>([]);

    useEffect(() => {
        if (!!userProvider.user && !!userProvider.user?.factions) {
            const tags: string[] = [];
            userProvider.user.factions.forEach(fac => tags.push(fac.factionTag!));
            setMyFactions(factions.filter(fac => tags.includes(fac.tag)));
        }
    }, [userProvider, factions]);

    return (
        <>
            {myFactions.map(faction => (
                <div key={faction.id} className="faction-list faction-container">
                    <FactionCardView faction={faction} />
                    <hr className="u-center"></hr>
                </div>
            ))}
        </>
    )
    
}