import React from 'react';
import { Helmet } from 'react-helmet';

export const DefaultMetaTags = () => {
    return (
        <Helmet>
            <title>Sunset Roleplay</title>
            <link rel="icon" href="/logo.png" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <meta name="theme-color" content="#000000" />
            <meta name="description" content="Engage in an immersive roleplay experience with Sunset RP Panel." />
            <link rel="apple-touch-icon" href="/logo.png" />
            <link rel="manifest" href="/manifest.json" />
            {/* Open Graph / Facebook */}
            <meta property="og:type" content="website" />
            <meta property="og:url" content="https://ss-rp.com/" />
            <meta property="og:title" content="Sunset Roleplay Panel" />
            <meta property="og:description" content="Engage in an immersive roleplay experience with Sunset RP Panel." />
            <meta property="og:image" content="/logo.png" />
        </Helmet>
    );
};