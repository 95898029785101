import React from 'react';
import styles from './Description.module.css'; 

interface DescriptionProps {
    text: string;
    size: number;
    mode: "Dark" | "Light";
}

const Description: React.FC<DescriptionProps> = ({ text, size, mode }) => {
    const modeStyles = mode === 'Dark' ? styles.darkMode : styles.lightMode;
    const sizeStyle = {
        fontSize: `${size}px`, 
    };


    const parseTextToJSX = (inputText: string) => {
        return inputText.split('&br').map((line, index, array) => (
            <span key={index}>
                {
                    line.split(/(\*.*?\*)/).map((segment, segmentIndex) => {
                        if (segment.startsWith('*') && segment.endsWith('*')) {
                            // Extract the text inside the * *
                            const boldText = segment.slice(1, -1);
                            return <strong key={segmentIndex}>{boldText}</strong>;
                        } else {
                            // Process regular and hyperlink text
                            return segment.split(" ").map((word, wordIndex) => {
                                const match = word.match(/<(.+?)>/);
                                return match ? (
                                    <a href={match[1]} key={wordIndex} target="_blank" rel="noopener noreferrer">
                                        {match[1]}
                                    </a>
                                ) : (
                                    <React.Fragment key={wordIndex}>{word} </React.Fragment>
                                );
                            });
                        }
                    })
                }
                {index < array.length - 1 && <br />}
            </span>
        ));
    };

    return (
        <div className={`${styles.wrapper} ${modeStyles}`}>
            <p className={styles.description} style={sizeStyle}>
                {parseTextToJSX(text)}
            </p>
        </div>
    );
};

export default Description;
