import React from 'react';
import styles from './Tooltip.module.css';
interface TooltipProps {
    content: React.ReactNode; // Accepts any renderable content
    children: React.ReactNode; // Accepts any renderable content
}
const Tooltip: React.FC<TooltipProps> = ({ children, content }) => {
    return (
        <div className={styles.tooltipWrapper}>
            {children}
            <div className={styles.tooltipContent}>{content}</div>
        </div>
    );
};
export default Tooltip;
