import React, { useContext, useEffect, useState } from 'react';
import { SunsetAPIAccountContext } from '../../Networking/Interface/Account/AccountAPIProvider';
import { DealershipVehicle } from '../../Networking/Interface/Account/AccountInterface';
import './Dealership.css';

const dealershipNames: { [key: number]: string } = {
  0: 'Rodeo Grotti',
  1: 'Blueberry Trucks',
  2: 'Dillimore Trailers',
  3: 'Marina Boats',
  4: 'Jefferson Coutt and Schutz',
  5: 'Blueberry Special Vehicles',
  6: 'LVA Freight Depot Industrial',
  7: 'LVAP Aircraft',
  8: 'LVAP Shody Used Autos',
  9: 'Redsands East Autobahn',
  10: 'Royal Casino Autobahn Bikes',
  11: 'Valle Ocultado Boat Shop',
  12: 'Bayside Boats',
  13: 'Downtown Ottos Autos',
  14: 'Doherty Wang Cars',
  15: 'Ocean Docks Boats',
  16: 'LSAP Aircraft'
};

const DealershipPage: React.FC = () => {
  const api = useContext(SunsetAPIAccountContext);
  const [vehicles, setVehicles] = useState<DealershipVehicle[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    if (!api) return;
    const fetchVehicles = async () => {
      try {
        const response = await api.getDealershipVehicles();
        if (response.success && response.data) {
          setVehicles(response.data);
        } else {
          console.error('Failed to fetch vehicles:', response.error);
        }
      } catch (error) {
        console.error('Error fetching vehicles:', error);
      }
    };

    fetchVehicles();
  }, [api]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filteredVehicles = vehicles.filter(vehicle =>
    vehicle.modelName.toLowerCase().includes(searchTerm.toLowerCase()) ||
    vehicle.price.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    (dealershipNames[vehicle.dealership] && dealershipNames[vehicle.dealership].toLowerCase().includes(searchTerm.toLowerCase()))    );

  return (
    <div className="dealership-page">
      <h1>Dealership Vehicles</h1>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by model, price, or dealership"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>
      <div className="vehicle-list">
        {filteredVehicles
          .filter(vehicle => vehicle.dealership != -1)
          .map(vehicle => (
            <div key={vehicle.id} className="vehicle-card">
              <h2>{vehicle.modelName}</h2>
              <img
                src={`/samp/vehicles/${vehicle.model}.png`}
                alt={`${vehicle.model} image`}
                className="vehicle-image"
              />
              <p>Model: {vehicle.model}</p>
              <p>Price: ${vehicle.price.toLocaleString()}</p>
              <p>Dealership: {dealershipNames[vehicle.dealership]}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default DealershipPage;
