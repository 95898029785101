import React from 'react';
import './LandingPage.css';
import { MdCasino, MdContentPaste, MdEventSeat, MdLocalPolice, MdOutlineCases, MdOutlinePeople, MdOutlineSensorOccupied } from "react-icons/md";
import { Connections } from './Connections';  // Ensure to import the Connections component

import { SunsetLogo } from './SunsetLogo';
import { TabPane, Tabs } from '../../Components/Tabs';
import Information from '../../Components/Information';
import Grid from '../../Components/Grid';
import { IconCard } from '../../Components/IconCard';
import { StyledParagraph } from '../../Components/StyledParagraph';
import { AccountDisplayCard } from '../../Components/Account/AccountDisplayCard';
import Gallery from './Gallery/Gallery';
import OnlinePlayersView from './OnlinePlayersView';


const LandingPage: React.FC = () => {
    return (
        <div className="outer-container">
            <SunsetLogo slogan="Your leading source of roleplay!" />
            <div className="connections-wrapper">
                <Connections />
            </div>
            <Tabs defaultActiveKey="About">
                <TabPane tabKey="About" tabTitle="About">
                    <StyledParagraph maxHeight="200px"  headline="Welcome to Sunset Roleplay! (SS-RP)">
                        Immerse yourself in the timeless world of Sunset Roleplay, a standout roleplay server for the Grand Theft Auto: San Andreas Multiplayer (SAMP) community. Nestled at the core of the GTA SA multiplayer action, Sunset Roleplay (SS-RP) breathes new life into this beloved classic. Sure, SAMP might be older than some of its players, but it's far from riding into the sunset! &br&br Our server provides a peerless, community-driven roleplay experience, finely tuned for die-hard fans and newcomers to the nostalgic streets of GTA San Andreas. Join us at SS-RP, where the old school is the cool school, and discover why this game refuses to hang up its boots!
                    </StyledParagraph>
                    <Information title="Features">
                        
                        <Grid elementWidth="250px" minHeight="130px">
                            <IconCard backgroundColor="#1e262c" title="Custom Factions" icon={ <MdOutlinePeople /> }>
                                Extensive and customizable group system
                            </IconCard>
                            <IconCard backgroundColor="#085673" title="Custom script" icon={<MdContentPaste/>}>
                                Fully customized server script, made for your enjoyment
                            </IconCard>
                            <IconCard backgroundColor="#1e262c" title="Community" icon={<MdOutlinePeople />}>
                                Friendly and active community
                            </IconCard>
                            <IconCard backgroundColor="#DEA300" title="Casino" icon={<MdCasino />}>
                                Blackjack, Roulette, Slot Machines, and more!
                            </IconCard>
                            <IconCard backgroundColor="#550755" title="Jobs" icon={<MdOutlineCases />}>
                                15+ Unique jobs to choose from
                            </IconCard>
                            <IconCard backgroundColor="#1e262c" title="Furniture System" icon={<MdEventSeat/>}>
                                Pimp the inside AND outside of your crib!
                            </IconCard>
                            <IconCard backgroundColor="#670017" title="Scripted Gangwars" icon={<MdOutlineSensorOccupied />}>
                                Turfs, perks and more!
                            </IconCard>
                            <IconCard backgroundColor="#007675" title="Law Factions" icon={<MdLocalPolice/>}>
                                Extensive police features,custom mappings
                            </IconCard>
                        </Grid>
                    </Information>
                    <Information title="Images from the server">
                        <Gallery />
                    </Information>
                </TabPane>
                <TabPane tabKey="Stats" tabTitle="Stats">
                    <OnlinePlayersView/>
                </TabPane>
                <TabPane tabKey="Credits" tabTitle="Credits">
                    <Grid elementWidth="250px">
                        <AccountDisplayCard accountID="3" rank="Owner" key="Duplon.webp" pfp="Duplon.webp" rankColor="#5D0505" account="Duplon" name="Duplon" description="Does not know what multiverse means."></AccountDisplayCard>
                        <AccountDisplayCard accountID="2" rank="Manager" key="proxus.webp" pfp="proxus.webp" rankColor="#F5BC00" account="proxus" name="proxus" description="The 2nd best SAMP scripter."></AccountDisplayCard>
                        <AccountDisplayCard accountID="59" rank="Manager" key="valentin.png" pfp="valentin.png" rankColor="#F5BC00" account="Luigi_Diablo" name="Valentin" description="Boss"></AccountDisplayCard>
                        <AccountDisplayCard accountID="4" rank="Server leader" key="Xerkz.webp" pfp="Xerkz.webp" rankColor="#00B5BC" account="xerkz"  name="Xerkz" description="Where is truco?"></AccountDisplayCard>
                        {/* <AccountDisplayCard accountID='5' rank='Lead Admin' key="Sixy.jpg" pfp='Sixy.jpg' rankColor='#9900bc' account='Sixy' name='Sixy' description='Thanks for credits!' ></AccountDisplayCard> */}
                    </Grid>
                </TabPane>
            </Tabs>
        </div>
    );

}

export default LandingPage;
