import React from "react";
import styled, { keyframes } from "styled-components";
import styles from "./BalanceTopUpView.module.css";

// Define keyframes for the shine animation
const shine = keyframes`
  0% { left: -100%; }
  100% { left: 100%; }
`;

const PackageButton = styled.button<{ color: string; gradient: string }>`
  padding: 10px;
  width: 250px;
  margin: 5px;
  background-color: ${(props) => props.color};
  background-image: ${(props) => props.gradient};
  color: #161515;
  font-weight: 600;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 50%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform: skewX(-20deg);
    animation: ${shine} 1.5s infinite;
  }

  &:hover {
    background-image: url("/source.gif");
    background-size: contain;
    transform: scale(1.05);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }

  &.selectedOption {
    transform: scale(1.05);
    border: 2px black solid;
  }
`;

const PaymentButton = styled.button`
  background: white;
  /* border: 2px solid transparent; */
  border-radius: 10px;
  padding: 5px;
  margin: 10px;
  cursor: pointer;
  transition: border 0.3s ease;

  &.selectedPayment {
    border: 2px solid #0070ba;
    border: 2px black solid;
  }

  &:hover {
    border: 2px solid #0070ba;
  }

  img {
    width: 60px;
  }
`;

const PackageName = styled.div`
  font-size: 1.2rem;
  font-weight: bold;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
`;

const PackageCredits = styled.div`
  font-size: 0.8rem;
  color: #393939;
`;

const Image = styled.img`
  width: 60px;
  background-color: transparent;
  margin: 0 10px;
  transition: transform 0.3s ease, opacity 0.3s ease;
`;

// Styled components for the selected credit option section
const SelectedSection = styled.div`
  background: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const SelectedText = styled.p`
  font-size: 1.1rem;
  color: #333;
  margin: 0 0 10px;
`;

const PaymentOptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

type PreMadePackagesProps = {
  selectedCreditOption: number | null;
  handleCreditOptionClick: (credits: number) => void;
  selectedPaymentMethod: string;
  handlePaymentMethodSelect: (method: string) => void;
  activateCustomSection: () => void;
};

const PreMadePackagesSection: React.FC<PreMadePackagesProps> = ({
  selectedCreditOption,
  handleCreditOptionClick,
  selectedPaymentMethod,
  handlePaymentMethodSelect,
  activateCustomSection,
  
}) => {
  return (
    <div style={{ color: "black" }} className={styles.preMadePackages}>
      <h3>Pre-made Packages:</h3>
      <div className={styles.creditOptions}>
        <PackageButton
          color="#cd7f32"
          gradient="linear-gradient(145deg, #cd7f32, #d49447)"
          className={selectedCreditOption === 100 ? styles.selectedOption : ""}
          onClick={() => handleCreditOptionClick(100)}
        >
          <PackageName>Bronze</PackageName>
          <PackageCredits>100 Credits</PackageCredits>
        </PackageButton>
        <PackageButton
          color="#c0c0c0"
          gradient="linear-gradient(145deg, #c0c0c0, #d0d0d0)"
          className={selectedCreditOption === 200 ? styles.selectedOption : ""}
          onClick={() => handleCreditOptionClick(200)}
        >
          <PackageName>Silver</PackageName>
          <PackageCredits>200 Credits</PackageCredits>
        </PackageButton>
        <PackageButton
          color="#ffd700"
          gradient="linear-gradient(145deg, #ffd700, #ffe066)"
          className={selectedCreditOption === 500 ? styles.selectedOption : ""}
          onClick={() => handleCreditOptionClick(500)}
        >
          <PackageName>Gold</PackageName>
          <PackageCredits>500 Credits</PackageCredits>
        </PackageButton>
        <PackageButton
          color="#3498db"
          gradient="linear-gradient(145deg, #3498db, #5dade2)"
          className={selectedCreditOption === 1000 ? styles.selectedOption : ""}
          onClick={() => handleCreditOptionClick(1000)}
        >
          <PackageName>Platinum</PackageName>
          <PackageCredits>1000 Credits</PackageCredits>
        </PackageButton>
      </div>
      {selectedCreditOption && (
        <SelectedSection>
          <SelectedText>
            Selected: {selectedCreditOption} Credits (
            {(selectedCreditOption / 10).toFixed(2)} GBP)
          </SelectedText>
          <p>Select a payment method:</p>
          <PaymentOptionsContainer>
            <PaymentButton
              className={selectedPaymentMethod === "paypal" ? styles.selectedPayment : ""}
              onClick={() => handlePaymentMethodSelect("paypal")}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/paypal.png`}
                alt="Pay with PayPal"
              />
            </PaymentButton>
            <PaymentButton
              className={selectedPaymentMethod === "stripe" ? styles.selectedPayment : ""}
              onClick={() => handlePaymentMethodSelect("stripe")}
            >
              <Image
                src={`${process.env.PUBLIC_URL}/Stripe.png`}
                alt="Pay with Stripe"
              />
            </PaymentButton>
          </PaymentOptionsContainer>
        </SelectedSection>
      )}
    </div>
  );
};

export default PreMadePackagesSection;
