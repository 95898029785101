import { APIResponse, APIUtility } from "../../ApiUtility";
import { StaffInfo } from "./StaffActivityInterfaces";

export class SunsetAPIManagement {
    private apiUtility: APIUtility;
    public baseURL: string;

    constructor(baseURL: string = "https://ss-rp.com/") {
        this.apiUtility = new APIUtility(baseURL);
        this.baseURL = baseURL;
    }

    async getStaffActivitySummary(): Promise<APIResponse<StaffInfo[]>> {
        return await this.apiUtility.get('api/management/staff-activity');
    }
}