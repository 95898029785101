import { ButtonBase, Modal } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";

const Badge = styled.span<{ score: number }>`
  display: inline-block;
  padding: 0.5em;
  border-radius: 0.25em;
  color: white;
  background-color: ${({ score }) => {
    if (score > 0.7) return "red";
    if (score > 0.5) return "yellow";
    return "green";
  }};
`;

interface ScreenshotData {
  screenshot_data: string;
  adminRequested: number; 
  interrogation_result: number;
}

interface ScreenshotInfoProps {
  data: string; 
  userName: string;
  userId: number;
}

const ModalContent = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: 3px solid black;
  padding: 20px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  border-radius: 8px;
`;

const ScreenshotInfo: React.FC<ScreenshotInfoProps> = ({ data, userName, userId }) => {
  const parsedData: ScreenshotData = JSON.parse(data);

  const [modalOpen, setModalOpen] = useState(false);

  const handleViewScreenshot = () => {
    setModalOpen(true);
  }

  return (
    <div>
      <div>Requested by: {parsedData.adminRequested ? "Watchdog(Auto)" : `${userName} (ID: ${userId})`}</div>
      <div>
        Interrogation result:
        <Badge score={parsedData.interrogation_result}>
          {parsedData.interrogation_result}
        </Badge>
      </div>
      <ButtonBase onClick={handleViewScreenshot}>Click here to view</ButtonBase>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="screenshot-modal-title"
        aria-describedby="screenshot-modal-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <ModalContent>
          <img src={parsedData.screenshot_data} alt="Screenshot" style={{ width: '100%', maxHeight: '400px', objectFit: 'contain' }} />
        </ModalContent>
      </Modal>
    </div>
  );
};

export default ScreenshotInfo;
