import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ToastType } from "../../Components/Toast/Toast";
import { useToast } from "../../Components/Toast/ToastContext";
import { AccountDTO } from "../../Networking/Interface/Account/AccountInterface";
import { AccountSearch } from "./AccountSearch";
import { AccountView } from "./AccountView";
import { SunsetAPIAccountContext } from "../../Networking/Interface/Account/AccountAPIProvider";




export const AccountDashboard: React.FC = () => {
    const [selectedAcc, setSelectedAcc] = useState<AccountDTO | undefined>();
    const navigate = useNavigate();
    const { showToast } = useToast();
    const onAccountSelect = (account: AccountDTO) => {
        setSelectedAcc(account);
        navigate(`/accounts/${account.id}/${account.username}`);
    };


    const { id, name } = useParams<{ id: string; name: string }>();
    const api = useContext(SunsetAPIAccountContext);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const response = await api!.getAccountByIdAndName(parseInt(id!), name!);
                if (response.success && response.data) {
                    if (selectedAcc?.id !== response.data.id || selectedAcc?.username !== response.data.username) {
                        setSelectedAcc(response.data);
                        // console.log(response.data)
                        //  - lmao i found this when i was making an extension / foksha
                    }
                } else {
                    showToast(ToastType.Error,'Failed to fetch the account. Please try again later.',10);
                }
            } catch (e) {
                showToast(ToastType.Error, 'You are likely being ratelimited, please wait.',5);
            }
        }

        if ((id && name) && (!selectedAcc || selectedAcc.id.toString() !== id || selectedAcc.username !== name)) {
            fetchUser();
        }

    }, [id, name, api, selectedAcc]);

    return (
        <>
            <div className="account-wrapper">
                <AccountSearch onAccountSelect={onAccountSelect} />
                {!!selectedAcc && <AccountView accountDTO={selectedAcc} />}
            </div>
        </>
    );
}
