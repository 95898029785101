import { FactionCharacter, User } from "../User/User";
import { JobClass } from "../Utils/enums";
import { APIResponse, APIUtility } from "./ApiUtility";
import { AccountBalance } from "./Interface/Account/AccountInterface";
import { CrateOpening, CrateReward } from "./Interface/Gamba/GambaInterface";

export class SunsetAPI {
  private apiUtility: APIUtility;
  public baseURL: string;

  // constructor(baseURL: string = "http://localhost:7203/") {
  constructor(baseURL: string = "https://ss-rp.com/") {
    this.apiUtility = new APIUtility(baseURL);
    this.baseURL = baseURL;
  }
  async buyCreditss(
    creditCents: number,
    promoCode: string | null,
    paymentMethod: string
  ): Promise<APIResponse<string>> {
    let url: string;
  
    if (paymentMethod === "paypal") {
      url = `http://localhost:3000/api/paypal/buy-credits/${creditCents}`;
    } else if (paymentMethod === "stripe") {
      url = `http://localhost:3000/exp-api/payment/create-checkout-session/${creditCents}`;
    } else {
      throw new Error("Unsupported payment method");
    }
  
    if (promoCode) {
      url += `?promoCode=${encodeURIComponent(promoCode)}`;
    }
  
    return await this.apiUtility.post(url);
  }
  

  // async buyCreditss(
  //   creditCents: number,
  //   promoCode: string | null,
  //   paymentMethod: string
  // ): Promise<APIResponse<string>> {
  //   let url: string;

  //   if (paymentMethod === "paypal") {
  //     url = `api/paypal/buy-credits/${creditCents}`;
  //   } else if (paymentMethod === "stripe") {
  //     url = `http://localhost:3000/exp-api/payment/stripe`;
  //   } else {
  //     throw new Error("Unsupported payment method");
  //   }

  //   if (promoCode) {
  //     url += `?promoCode=${encodeURIComponent(promoCode)}`;
  //   }

  //   return await this.apiUtility.post(url);
  // }

  async getCurrentUser(): Promise<APIResponse<User>> {
    const response = await this.apiUtility.get<User>("api/user/current");

    return response;
  }
  async login(
    credentials: Credentials
  ): Promise<APIResponse<{ message: string }>> {
    return await this.apiUtility.post("api/auth/login", credentials);
  }

  async logout(): Promise<APIResponse<{ Message: string }>> {
    return await this.apiUtility.post("api/auth/logout", {});
  }

  async buyProductPaypal(packageID: number): Promise<APIResponse<string>> {
    return await this.apiUtility.post(`api/paypal/buyProduct/${packageID}`);
  }

  async getServerRules(): Promise<APIResponse<ServerRule[]>> {
    return await this.apiUtility.get("api/ServerInfo/Rules");
  }

  async getFaqInfo(): Promise<APIResponse<FaqInfo[]>> {
    return await this.apiUtility.get("api/ServerInfo/FAQ");
  }

  async getChangelog(
    page: number = 1,
    pageSize: number = 10
  ): Promise<APIResponse<Changelog[]>> {
    return await this.apiUtility.get(
      `api/serverinfo/Changelog?page=${page}&pageSize=${pageSize}`
    );
  }

  async getActivityByAccountId(
    accountName: string
  ): Promise<APIResponse<{ [key: number]: number }>> {
    return await this.apiUtility.get(
      `api/activity/by-account-name/${accountName}`
    );
  }

  async executePayment(
    paymentID: string,
    payerID: string,
    token: string
  ): Promise<APIResponse<string>> {
    const url = `api/paypal/execute-payment?paymentId=${encodeURIComponent(
      paymentID
    )}&payerId=${encodeURIComponent(payerID)}&token=${encodeURIComponent(
      token
    )}`;
    return await this.apiUtility.post(url);
  }

  async buyCredits(
    creditCents: number
  ): Promise<APIResponse<string>> {
    let url = `api/paypal/buy-credits/${creditCents}`;
 
    return await this.apiUtility.post(url);
  }

  async getPromoCode(promoCode: string): Promise<APIResponse<PromoCode>> {
    return await this.apiUtility.get(`api/donation/promo-codes/${promoCode}`);
  }
  async getUserFactions(): Promise<APIResponse<FactionCharacter[]>> {
    return await this.apiUtility.get("api/user/Factions");
  }

  async getCrates(): Promise<APIResponse<{ [key: number]: number }>> {
    return await this.apiUtility.get(`api/gamba/crates`);
  }
  async getScrollRewards(
    crateID: number,
    totalSlots: number
  ): Promise<APIResponse<CrateReward[]>> {
    return await this.apiUtility.get(
      `api/gamba/scrolling/${crateID}/${totalSlots}`
    );
  }
  async openCrate(crateID: number): Promise<APIResponse<CrateOpening>> {
    return await this.apiUtility.post(`api/gamba/open/${crateID}`);
  }
  async getAccountBalance(
    userId: number
  ): Promise<APIResponse<AccountBalance>> {
    return await this.apiUtility.get(
      `api/donation/get-account-balance/${userId}`
    );
  }
}

export interface PromoCode {
  id: number;
  expiry: Date;
  code: string;
  usesLeft: number;
  enabled: boolean;
  discountPercent: number;
  discountFlat: number;
}

export interface ServerRule {
  Id: number;
  ParentId: number;
  SpecialId: string;
  RuleName: string;
  Content: string;
}

export interface Changelog {
  id: number;
  text: string;
  lastEdited: Date;
}

export interface FaqInfo {
  Id: number;
  Question?: string;
  Content?: string;
}

export interface Credentials {
  Username: string;
  Password: string;
}

export interface JobDataDTO {
  jobSkin: number;
  jobClass: JobClass;
}

export interface PlaytimeSummary {
  date: Date;
  timePlayedHours: number;
}
