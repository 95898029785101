import React, { useContext, useState } from "react";
import {
  FilterData,
  FilterPayload,
  LogPermission,
} from "../../Networking/Interface/Logs/LogInterface";
import { LogType } from "../../Utils/enums";
import { DateInput } from "./DateInput";
import { FilterItem } from "./FilterItem";
import "./Logs.css";
import { APIResponse, Log } from "./LogView";
import Button from "../../ui/Button";
import { SunsetAPILogContext } from "../../Networking/Interface/Logs/LogAPIProvider";
interface LogFilterProps {
  onSubmit: (response: APIResponse<Log[]>) => void;
  allowedOptions: LogPermission[];
}
const LogFilter: React.FC<LogFilterProps> = ({ onSubmit, allowedOptions }) => {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [filters, setFilters] = useState<FilterData[]>([]);
  const api = useContext(SunsetAPILogContext);
  const calculateTimezoneOffset = () => {
    const now = new Date();
    const offset = -now.getTimezoneOffset() / 60;
    return offset >= 0 ? `+${offset}` : offset;
  };
  const handleAddFilter = () => {
    setFilters((prev) => [
      ...prev,
      {
        type: LogType.Chat,
        subcategories: [],
        log: "",
        caseSensitive: false,
        fromID: null,
      },
    ]);
  };
  const handleChangeFilter = (
    index: number,
    key: keyof FilterData,
    value: LogType | string | string[]
  ) => {
    const newFilters = [...filters];
    newFilters[index] = { ...newFilters[index], [key]: value };

    setFilters(newFilters);
  };
  const handleRemoveFilter = (index: number) => {
    setFilters((prev) => prev.filter((_, idx) => idx !== index));
  };
  const handleSubmit = async () => {
    const requestData: FilterPayload = {
      fromDateUTC: fromDate.toISOString(),
      toDateUTC: toDate.toISOString(),
      filters: filters.map((filter) => ({
        type: filter.type,
        subcategories: filter.subcategories,
        log: filter.log,
        caseSensitive: false,
        fromID: filter.fromID,
      })),
    };

    try {
      const data = await api?.postLogFilter(requestData);
      onSubmit(data!);
    } catch (error) {
      console.error("Error sending request:", error);
    }
  };
  return (
    <div className="log-view u-box-shadow-light">
      <DateInput label="From" value={fromDate} onChange={setFromDate} />
      <DateInput label="To" value={toDate} onChange={setToDate} />
      <p className="timezone-info">
        Note: Server logs use UTC time. Your timezone offset is UTC
        {calculateTimezoneOffset()} hours.
      </p>
      {filters.map((filter, idx) => (
        <FilterItem
          key={idx}
          idx={idx}
          filter={filter}
          onChange={handleChangeFilter}
          onRemove={handleRemoveFilter}
          allowedOptions={allowedOptions}
        />
      ))}
      <Button onClick={handleAddFilter}>Add Filter </Button>
      <Button onClick={handleSubmit}> Submit </Button>
    </div>
  );
};

export default LogFilter;
