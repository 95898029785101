
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SunsetAPIContext } from "../../Networking/ApiProvider";
import { UserContext } from "../../User/UserContext";

export default function Logout(): React.ReactElement {
  const api = useContext(SunsetAPIContext);
  const userProp = useContext(UserContext);
  const navigate = useNavigate();

  const handleLogout = async () => {
    const response = await api!.logout();

    if (response.success) {
      userProp.setUser(undefined);
      navigate('/login'); 
    } else {
      console.error("Logout failed.");
    }
  };

  return (
    <p onClick={handleLogout} className="logout-button">
      Logout
    </p>
  );
}
