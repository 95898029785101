import React, { useContext, useState, useEffect } from 'react';
import { ToastType } from '../../Components/Toast/Toast';
import { useToast } from '../../Components/Toast/ToastContext';
import UserRoleBadge from '../../Components/UserRoleBadge';
import { AccountCharDTO, AccountDTO } from '../../Networking/Interface/Account/AccountInterface';
import { APIResponse } from '../../Staff/Logs/LogView';
import './Account.css';
import { SunsetAPIAccountContext } from '../../Networking/Interface/Account/AccountAPIProvider';

interface AccountSearchProps {
    onAccountSelect: (account: AccountDTO) => void;
}


export const AccountSearch: React.FC<AccountSearchProps> = ({onAccountSelect }) => {
    const api = useContext(SunsetAPIAccountContext);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [suggestions, setSuggestions] = useState<Array<AccountDTO | AccountCharDTO>>([]);
    const [searchType, setSearchType] = useState<'Account' | 'Character'>('Account');
    const [hideDropdown, setHideDropdown] = useState(false);
    const [inputFocused, setInputFocused] = useState<boolean>(false);
    const { showToast } = useToast();
    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (!inputFocused && suggestions.length > 0) {
            timeout = setTimeout(() => setHideDropdown(true), 100); // 100 ms delay
        } else {
            setHideDropdown(false);
        }
        return () => clearTimeout(timeout); // Clean up the timer
    }, [inputFocused, suggestions]);
    useEffect(() => {
        
        const fetchSuggestions = async () => {
            try {
                let response: APIResponse<AccountDTO[]> | APIResponse<AccountCharDTO[]>;
                if (searchType === "Account") {
                    response = await api!.getAccountsByPartialName(searchQuery);
                }
                else {
                    response = await api!.getAccountsByCharPartialName(searchQuery);
                }
                if (response.success && response.data) {
                    setSuggestions(response.data);
                }
                else {
                    console.error(`Unable to fetch the suggestion. Account prolly doesnt exist. Query: ${searchQuery}`)                }
                }
            catch {
                console.error(`Unable to fetch the suggestion. Account prolly doesnt exist. Query: ${searchQuery}`)
                // showToast(ToastType.Error, "Unable to fetch the suggestions,likely due to ratelimiting. Please wait.", 10);
            }
        };

        if (searchQuery.length > 3) {
            fetchSuggestions();
        }
    }, [searchType,searchQuery, api]);
    const handleOnChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        let text: string = e.target.value;
        setSearchQuery(text);
        if (text.length <= 3) {
            setSuggestions([]);
        }
    }
    return (
        <div className="account-container u-container card u-flex">
            <label className="switch">
                <input type="checkbox" onChange={() => setSearchType(prev => prev === 'Account' ? 'Character' : 'Account')} />
                <span className="slider round"></span>
            </label>
            <div style={{ position: 'relative' }}>
                <input
                    id="search-box"
                    className="u-input "
                    type="text"
                    value={searchQuery}
                    onChange={handleOnChange}
                    onFocus={() => { setInputFocused(true); setHideDropdown(false); }}
                    onBlur={() => { setInputFocused(false); }}
                    placeholder={`Search by ${searchType}`}
                />
                <div className={`account-dropdown-content ${!hideDropdown && suggestions.length > 0 ? 'expanded' : ''}`}
                >
                    {suggestions.map((suggestion, i) => (
                        <div key={i} className="suggestion-item" onClick={() => onAccountSelect(suggestion as AccountDTO)}>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <UserRoleBadge role={suggestion.adminLevel}></UserRoleBadge>
                            </div>
                            <span className="player-name">
                                {'characterName' in suggestion ? suggestion.characterName : suggestion.username}
                            </span>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    );


};
