import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';

interface FactionPlaytimeGraphProps {
    sessions: AccountSessionFactionDTO[];
}

interface CharacterPlaytime {
    [userId: number]: {
        hours: number[];
        latestUsername: string;
    };
}

const FactionPlaytimeGraph: React.FC<FactionPlaytimeGraphProps> = ({ sessions }) => {
    const [chartData, setChartData] = useState<any>(null);
    const MAX_PLAYERS_DISPLAY = 18;

    useEffect(() => {
        const data = transformData(sessions);
        setChartData(data);
    }, [sessions]);

    const transformData = (sessions: AccountSessionFactionDTO[]): any => {
        const lastMonthDates = Array.from({ length: 30 }, (_, i) => {
            const d = new Date();
            d.setDate(d.getDate() - i);
            return d.toISOString().split('T')[0]; // get YYYY-MM-DD format
        }).reverse();

        const characterPlaytime: CharacterPlaytime = {};

        sessions.forEach((session) => {
            const userId = session.userId;
            const date = new Date(session.start).toISOString().split('T')[0];
            const index = lastMonthDates.indexOf(date);
            const playtime = (new Date(session.end).getTime() - new Date(session.start).getTime()) / (60 * 60 * 1000); // converting ms to hours

            if (index !== -1) {
                if (!characterPlaytime[userId]) {
                    characterPlaytime[userId] = {
                        hours: Array(30).fill(0),
                        latestUsername: session.username || "Unknown User"
                    };
                }
                characterPlaytime[userId].hours[index] += playtime;
                characterPlaytime[userId].latestUsername = session.username || "Unknown User"; // Always update to the most recent username
            }
        });

        const filteredCharacterPlaytime: CharacterPlaytime = {};
        let othersArray = new Array(30).fill(0);

        Object.keys(characterPlaytime).forEach((userId) => {
            if (Object.keys(filteredCharacterPlaytime).length < MAX_PLAYERS_DISPLAY) {
                filteredCharacterPlaytime[parseInt(userId)] = characterPlaytime[parseInt(userId)];
            } else {
                othersArray = othersArray.map(
                    (val, idx) => val + characterPlaytime[parseInt(userId)].hours[idx]
                );
            }
        });

        if (othersArray.some((val) => val > 0)) {
            filteredCharacterPlaytime[-1] = { hours: othersArray, latestUsername: "Others" }; // Use a special ID for 'Others'
        }

        const datasets = Object.keys(filteredCharacterPlaytime).map((userId, i) => ({
            label: userId === "-1" ? "Others" : filteredCharacterPlaytime[Number.parseInt( userId)].latestUsername,
            data: filteredCharacterPlaytime[Number.parseInt(userId)].hours,
            backgroundColor: generateColor(i),
            borderColor: generateBorderColor(i),
            borderWidth: 1,
            stack: 'stack1',
        }));

        return { labels: lastMonthDates, datasets };
    };

    const generateColor = (index: number) => `hsla(${index * 35}, 50%, 50%, 0.6)`;
    const generateBorderColor = (index: number) => `hsla(${index * 35}, 50%, 50%, 1)`;

    return (
        <div className="graphContainer">
            {chartData ? (
                <Bar
                    data={chartData}
                    options={{
                        plugins: {
                            legend: {
                                display: true, // Always display the legend
                            },
                        },
                        scales: {
                            y: {
                                beginAtZero: true,
                                title: {
                                    display: true,
                                    text: "Playtime (hours)",
                                },
                                stacked: true,
                            },
                            x: {
                                title: {
                                    display: true,
                                    text: "Date",
                                },
                                stacked: true,
                            },
                        },
                    }}
                />
            ) : (
                <p>Loading chart...</p>
            )}
        </div>
    );
};

export default FactionPlaytimeGraph;

export interface AccountSessionFactionDTO {
    userId: number;
    sessionId: number;
    username?: string;
    start: Date;
    end: Date;
}
