import React, { useState } from "react";
import { Button } from "antd";

interface ViewButtonProps {
  fileName: string;
  fileHash: string;
  userId: number;
}

const ViewButton: React.FC<ViewButtonProps> = ({ fileName, fileHash, userId }) => {
  const [viewData, setViewData] = useState<any>(null);

  const handleViewRequest = async () => {
    const url = `https://ss-rp.com/exp-api/launcher/directory/files/content/${encodeURIComponent(fileName)}/${encodeURIComponent(fileHash)}`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({ userId }),
      });

      if (!response.ok) {
        console.error("Failed to make view request");
        return;
      }

      const text = await response.text();
      if (text) {
        const result = JSON.parse(text);
        setViewData(result);
       
      } else {
        setViewData(null);
      }
    } catch (error) {
      console.error("Error making view request:", error);
    }
  };

  return (
    <>
      <Button onClick={handleViewRequest}>View</Button>
      {viewData && <pre>{JSON.stringify(viewData, null, 2)}</pre>} 
    </>
  );
};

export default ViewButton;
