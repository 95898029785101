import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import CharacterSelection from "../../Components/CharacterSelection";
import { SunsetAPIAccountContext } from "../../Networking/Interface/Account/AccountAPIProvider";
import { ToastType } from "../../Components/Toast/Toast";
import { useToast } from "../../Components/Toast/ToastContext";
import { Button } from "antd";
import { AccountDTO, CharacterDTO } from "../../Networking/Interface/Account/AccountInterface";

interface ResetSpawnProps {
  accountDTO: AccountDTO;
  onModalClose: () => void;
}

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center; 
   z-index: 3;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 50px;
  margin: 10px 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;

`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

const ResetSpawnButton: React.FC<ResetSpawnProps> = ({
  accountDTO,
  onModalClose,
}) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [characters, setCharacters] = useState<CharacterDTO[]>([]);
  const { showToast } = useToast();
  const api = useContext(SunsetAPIAccountContext);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (showModal) {
      const fetchData = async () => {
        if (!api) return;
        const response = await api.getCharactersByAccountIDAndNAme(
          accountDTO.id,
          accountDTO.username
        );
        if (response?.success && response.data) {
          setCharacters(response.data);
        }
      };

      fetchData();
    }
  }, [api, accountDTO.id, accountDTO.username, showModal]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        setShowModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleModal = () => {
    setShowModal(!showModal);
    if (!showModal) {
      setCharacters([]);
    }
  };

  const resetCharacterSpawn = async (characterId: number) => {
    if (!api) return;
    try {
      const response = await api.ResetCharacterSpawn(characterId);
      if (!response?.success) {
        throw new Error("Failed to reset spawn");
      }
      showToast(ToastType.Success, "Spawn reset successful", 5);
      setShowModal(false);
    } catch (error) {
      console.error("Error resetting spawn:", error);
      showToast(ToastType.Error, "Spawn reset failed", 5);
    }
  };

  const handleCharacterSelect = (character: CharacterDTO) => {
    resetCharacterSpawn(character.id);
  };

  return (
    <>
      <Button style={{ border: "none" }} onClick={toggleModal}>
        Reset Spawn
      </Button>
      {showModal && (
        <ModalContainer ref={modalRef}>
          <ModalContent>
            <CloseButton onClick={() => setShowModal(false)}>×</CloseButton>
            <h2>Select a character to respawn:</h2>
            <CharacterSelection
              characters={characters}
              onCharacterSelect={handleCharacterSelect}
            />
            <ButtonBox>
              <Button onClick={() => setShowModal(false)}>Close</Button>
            </ButtonBox>
          </ModalContent>
        </ModalContainer>
      )}
    </>
  );
};

export default ResetSpawnButton;
