
import React, { useEffect } from 'react';

const DynamicPage = ({ title, description }) => {
  useEffect(() => {
    document.title = title; // Update the document title
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', description); // Update the meta description tag
    }
  }, [title, description]);

  return (
    <div>

    </div>
  );
};

export default DynamicPage;
