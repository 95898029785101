import { APIResponse, APIUtility } from "../../ApiUtility";
import { CrateOpening, CrateReward } from "./GambaInterface";




export class SunsetAPIGamba {
  private apiUtility: APIUtility;
  public baseURL: string;

  constructor(baseURL: string = "https://ss-rp.com/") {
      this.apiUtility = new APIUtility(baseURL);
      this.baseURL = baseURL;
  }

async getCrateRewards(crateID: number): Promise<APIResponse<CrateReward[]>> {
  return await this.apiUtility.get(`api/gamba/crate/${crateID}/rewards`);
}

async getOpenings(crateID: number): Promise<APIResponse<CrateOpening[]>> {
  return await this.apiUtility.get(`api/gamba/openings/${crateID}`);
}
async collectCrateRewards(openingID: number, characterID: number): Promise<APIResponse<CrateOpening[]>> {
  return await this.apiUtility.put(`api/gamba/collect-rewards/${openingID}/${characterID}`);}}