
import { FactionCharacter } from "../../../User/User";
import { APIResponse, APIUtility } from "../../ApiUtility";
import { Punishment } from "../Account/AccountInterface";
import { AccountSessionFactionDTO, Faction, FactionAlliance, FactionMember, FactionPost, FactionRank } from "./FactionInterface";


export class SunsetAPIFaction {
  private apiUtility: APIUtility;
  public baseURL: string;

  constructor(baseURL: string = "https://ss-rp.com/") {
      this.apiUtility = new APIUtility(baseURL);
      this.baseURL = baseURL;
  }

async getFactions(): Promise<APIResponse<Faction[]>> {
  return await this.apiUtility.get('api/factions');
}
async getFactionByTag(tag: string): Promise<APIResponse<Faction>> {
  return await this.apiUtility.get(`api/factions/by-tag/${tag}`);
}
async getFactionRanksByTag(tag: string): Promise<APIResponse<FactionRank[]>> {
  return await this.apiUtility.get(`api/factions/ranks-by-tag/${tag}`);
}
async getFactionsByPartialName(name: string): Promise<APIResponse<Faction[]>> {
  return await this.apiUtility.get(`api/factions/by-partial-name/${name}`);
}
async getFactionMembersByTag(tag: string): Promise<APIResponse<FactionMember[]>> {
  return await this.apiUtility.get(`api/factions/members-by-tag/${tag}`);
}
async getFactionAllianceByTag(tag: string): Promise<APIResponse<FactionAlliance>> {
  return await this.apiUtility.get(`api/factions/alliance-by-tag/${tag}`);
}
async createFactionPost(request: FormData): Promise<APIResponse<FactionPost>> {
  return await this.apiUtility.post("api/factions/create-faction-post", request);
}
async getFactionActivity(tag:string): Promise<APIResponse<AccountSessionFactionDTO[]>> {
  return await this.apiUtility.get(`api/factions/activity-sessions/${tag}`);
  }
async getRecentFactionPosts(page: number): Promise<APIResponse<FactionPost[]>> {
  return await this.apiUtility.get(`api/factions/recent-faction-posts/${page}`);
}

async getFactionPostsByTag(tag: string, page: number): Promise<APIResponse<FactionPost[]>> {
  return await this.apiUtility.get(`api/factions/faction-posts-by-tag/${tag}/${page}`);
}
async getFactionPunishmentsByTag(tag: string): Promise<APIResponse<Punishment[]>> {
  return await this.apiUtility.get(`api/factions/faction-punishments/${tag}`);
}
async deleteFactionPost(id: number): Promise<APIResponse<string>> {
  return await this.apiUtility.delete(`api/factions/delete-faction-post/${id}`);
}
async toggleFactionPostPin(postID: number): Promise<APIResponse<boolean>> {
  return await this.apiUtility.put(`api/factions/faction-punishments/${postID}`);
}

}