import React, { useState, useRef } from "react";
import { Modal as MModal } from "@mui/material";
import { Badge, Tag, Tabs, Modal as AModal, Button } from "antd";
import styled from "styled-components";
import { LuPanelBottomClose } from "react-icons/lu";

// Define interfaces
export interface Process {
  Id: number;
  ProcessName: string;
  PrivateMemorySize64: number;
  StartTime: string;
  InjectedProcess: boolean;
  InjectedDlls?: Array<{
    ModulePath: string;
    IsValid: boolean;
    AlertMessage: string;
  }>;
  LauncherDlls?: Array<{
    ModulePath: string;
    IsValid: boolean;
    AlertMessage: string;
  }>;
}

interface ProcessModalProps {
  processes: Process[];
  open: boolean;
  injectedDlls: Array<{
    ModulePath: string;
    IsValid: boolean;
    AlertMessage: string;
  }>; 
  launcherDlls: Array<{
    ModulePath: string;
    IsValid: boolean;
    AlertMessage: string;
  }>;
  dateTime: {
    dateString: string;
    timeString: string;
  };
  onClose: () => void;
}

// Styled components
const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 90vh;
  width: 80vw;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  flex: 1;
  overflow: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
`;

const TableHeader = styled.th`
  background: #f2f2f2;
  padding: 10px;
  text-align: left;
  color: black;
  border-bottom: 1px solid #ddd;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background: #f9f9f9;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const ScrollToBottomButton = styled(Button)`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
`;

// Helper functions
function getDateTime(date: string | Date): {
  dateString: string;
  timeString: string;
} {
  const d = new Date(date);
  const dateString = d.toLocaleDateString();
  const timeString = d.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  return { dateString, timeString };
}

// ProcessModal Component
const ProcessModal: React.FC<ProcessModalProps> = ({
  processes,
  open,
  dateTime,
  onClose,
  injectedDlls,
  launcherDlls,
}) => {
  const [selectedTab, setSelectedTab] = useState<string | null>(null);
  const modalContentRef = useRef<HTMLDivElement>(null);

  const handleTabClick = (key: string) => {
    setSelectedTab(key);
  };

  const scrollToBottom = () => {
    if (modalContentRef.current) {
      modalContentRef.current.scrollTo({
        top: modalContentRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };
  // console.log(injectedDlls);
  // console.log(launcherDlls);
  // Render DLLs for injected DLLs (object format)
  // const renderInjectedDlls = (dllsObject: string) => {
  //   const entries = Object.entries(dllsObject);
  //   return entries.length > 0 ? (
  //     <Table>
  //       <thead>
  //         <tr>
  //           <TableHeader>Module Path</TableHeader>
  //           <TableHeader>Hash</TableHeader>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {entries.map(([path, hash], index) => (
  //           <TableRow key={index}>
  //             <TableCell>{path}</TableCell>
  //             <TableCell>{hash}</TableCell>
  //           </TableRow>
  //         ))}
  //       </tbody>
  //     </Table>
  //   ) : (
  //     <p>No DLLs found.</p>
  //   );
  // };

  // // Render DLLs for launcher DLLs (array format)
  // const renderLauncherDlls = (
  //   dllsArray: Array<{
  //     ModulePath: string;
  //     IsValid: boolean;
  //     AlertMessage: string;
  //   }>
  // ) => {
  //   return dllsArray.length > 0 ? (
  //     <Table>
  //       <thead>
  //         <tr>
  //           <TableHeader>Module Path</TableHeader>
  //           <TableHeader>Validity</TableHeader>
  //           <TableHeader>Alert Message</TableHeader>
  //         </tr>
  //       </thead>
  //       <tbody>
  //         {dllsArray.map((dll, index) => (
  //           <TableRow key={index}>
  //             <TableCell>{dll.ModulePath}</TableCell>
  //             <TableCell>{dll.IsValid ? "Valid" : "Invalid"}</TableCell>
  //             <TableCell>{dll.AlertMessage}</TableCell>
  //           </TableRow>
  //         ))}
  //       </tbody>
  //     </Table>
  //   ) : (
  //     <p>No DLLs found.</p>
  //   );
  // };
  const renderDlls = (
    dllsArray: Array<
      | { ModulePath: string; IsValid: boolean; AlertMessage: string }
      | { ModulePath: string; Hash: string }
    >
  ) => {
    if (dllsArray.length === 0) {
      return <p>No DLLs found.</p>;
    }

    const isInjectedDll = (
      dll: any
    ): dll is { ModulePath: string; Hash: string } => "Hash" in dll;

    return (
      <Table>
        <thead>
          <tr>
            <TableHeader>Module Path</TableHeader>
            {isInjectedDll(dllsArray[0]) ? (
              <>
                <TableHeader>Hash</TableHeader>
              </>
            ) : (
              <>
                <TableHeader>Validity</TableHeader>
                <TableHeader>Alert Message</TableHeader>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {dllsArray.map((dll, index) => (
            <TableRow key={index}>
              <TableCell>{dll.ModulePath}</TableCell>
              {isInjectedDll(dll) ? (
                <TableCell>{dll.Hash}</TableCell>
              ) : (
                <>
                  <TableCell>{dll.IsValid ? "Valid" : "Invalid"}</TableCell>
                  <TableCell>{dll.AlertMessage}</TableCell>
                </>
              )}
            </TableRow>
          ))}
        </tbody>
      </Table>
    );
  };

  return (
    <>
      <MModal
        open={open}
        onClose={onClose}
        aria-labelledby="process-modal-title"
        aria-describedby="process-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 1300,
        }}
      >
        <ModalWrapper>
          <ModalContent ref={modalContentRef}>
            <h4>Processes</h4>
            <Table>
              <thead>
                <tr>
                  <TableHeader>Process Name</TableHeader>
                  <TableHeader>Memory Size</TableHeader>
                  <TableHeader>Start Time</TableHeader>
                  <TableHeader>Remarks</TableHeader>
                </tr>
              </thead>
              <tbody>
                {processes.map((process) => {
                  const processDateTime = getDateTime(process.StartTime);
                  const isNewer =
                    new Date(
                      `${processDateTime.dateString} ${processDateTime.timeString}`
                    ) >
                    new Date(`${dateTime.dateString} ${dateTime.timeString}`);
                  return (
                    <TableRow key={process.Id}>
                      <TableCell>{process.ProcessName}</TableCell>
                      <TableCell>
                        {process.PrivateMemorySize64.toLocaleString()} bytes
                      </TableCell>
                      <TableCell>
                        {new Date(process.StartTime).toLocaleString()}
                      </TableCell>
                      <TableCell>
                        {process.InjectedProcess && (
                          <Badge status="error" text="Injected" />
                        )}
                        {isNewer ? (
                          <Tag color="red">⚠️ Started during session</Tag>
                        ) : (
                          <Tag color="green">OK</Tag>
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </tbody>
            </Table>
            <Tabs
              defaultActiveKey="1"
              items={[
                {
                  key: "1",
                  label: " 💉Injected DLLs",
                  children: null,
                },
                {
                  key: "2",
                  label: " 🤖Launcher DLLs",
                  children: null,
                },
              ]}
              onTabClick={handleTabClick}
            />
            <ScrollToBottomButton onClick={scrollToBottom}>
              <LuPanelBottomClose />
            </ScrollToBottomButton>
          </ModalContent>
        </ModalWrapper>
      </MModal>

      <AModal
        title="Injected DLLs"
        open={selectedTab === "1"}
        onCancel={() => setSelectedTab(null)}
        footer={null}
        zIndex={1400}
        width={1450}
      >
        <ModalContent
          style={{
            height: "100%",
            width: "100%",
            padding: "20px",
            overflowY: "auto",
          }}
        >
          {renderDlls(injectedDlls)}
        </ModalContent>
      </AModal>

      <AModal
        title="Launcher DLLs"
        open={selectedTab === "2"}
        onCancel={() => setSelectedTab(null)}
        footer={null}
        width={1250}
        zIndex={1400}
        // style={{
        //   height: "100vh",
        //   width: "100vw",
        //   padding: 0,
        //   margin: 0,
        //   borderRadius: 0,
        // }}
      >
        <ModalContent
          style={{
            height: "100%",
            width: "100%",
            padding: "20px",
            overflowY: "auto",
          }}
        >
          {renderDlls(launcherDlls)}
        </ModalContent>
      </AModal>
    </>
  );
};

export default ProcessModal;
