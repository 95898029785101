import { useContext, useEffect, useState } from "react";
import { InitialsAPI } from "../../Networking/InitialsAPI";
import { getBannerType, getFactionColor } from "./FactionUtils";
import { useParams } from "react-router-dom";
import "./FactionView.css";
import { FactionMembersView } from "./FactionMembersView";
import { TabPane, Tabs } from "../../Components/Tabs";
import { FactionManagementView } from "./Management/FactionManagementView";
import { UserContext } from "../../User/UserContext";
import { Faction } from "../../Networking/Interface/Faction/FactionInterface";
import { SunsetAPIFactionContext } from "../../Networking/Interface/Faction/FactionApiProvider";

export const FactionView = () => {
  const [faction, setFaction] = useState<Faction | null>(null);
  const { tag } = useParams<{ tag: string }>();
  const api = useContext(SunsetAPIFactionContext);
  const user = useContext(UserContext);

  useEffect(() => {
    const fetchFaction = async () => {
      if (tag) {
        let response = await api!.getFactionByTag(tag);
        if (response.success) {
          setFaction(response.data!);
        }
      }
    };
    fetchFaction();
  }, [api, tag]);

  if (!faction) {
    return <p>Loading...</p>;
  }

  const factionColor = getFactionColor(faction);
  const bannerSrc =
    faction.bannerURL ||
    process.env.PUBLIC_URL +
      `/DefaultFactionBanners/${getBannerType(faction.type)}.png`;
  const profileSrc =
    faction.profileURL ||
    new InitialsAPI().getAvatarURL({
      name: faction.tag,
      length: 4,
      fontSize: 0.35,
    });
  const canViewMembers =
    user.user &&
    (user.user.role >= 6 ||
      user.user.factions?.some((f) => f.factionTag === tag));

  return (
    <div style={{ overflowX: "scroll" }}>
      <div className="main-container">
        <img
          src={bannerSrc}
          alt={`${faction.name} banner`}
          className="faction-view-banner"
        />
        <div className="faction-view-info">
          <img
            src={profileSrc}
            alt={`${faction.name} avatar`}
            className="faction-view-avatar"
            style={{ borderColor: factionColor }}
          />
          <h2 className="faction-view-name">{faction.name}</h2>
          {/* <p className="faction-view-slogan">
            {faction.slogan ?? "Default slogan"}
          </p> */}
        </div>
      </div>
      <div className="card main-container">
        <Tabs
          defaultActiveKey="1"
      
        >
          <TabPane tabKey="1" tabTitle="About">
            <div className="faction-about">
              <p>
                <strong>Name:</strong> {faction.name}
              </p>
              <p>
                <strong>Tag:</strong> {faction.tag}
              </p>
              <p>
                <strong>Date Created:</strong>{" "}
                {new Date(faction.created).toLocaleDateString()}
              </p>
            </div>
          </TabPane>
          <TabPane tabKey="2" tabTitle="Members">
            {canViewMembers ? (
              <FactionMembersView tag={tag!} />
            ) : (
              <p className="u-center u-p">
                You cannot view members of this faction.
              </p>
            )}
          </TabPane>
          {user.user &&
            (user.user.role > 3 ||
              user.user.factions?.some(
                (f) => f.factionTag === tag && f.rankLevel! > 80
              )) && (
              <TabPane tabKey="4" tabTitle="Management">
                <FactionManagementView tag={tag!} />
              </TabPane>
            )}
        </Tabs>
      </div>
    </div>
  );
};
