export class InitialsAPI {
    private readonly baseUrl: string = 'https://ui-avatars.com/api/';

    /**
     * Build the URL based on provided options
     * @param options The options for the avatar generation
     * @returns A URL string for the avatar
     */
    public getAvatarURL(options?: AvatarOptions): string {
        let params = new URLSearchParams();

        if (options?.name) {
            params.append('name', options.name);
        }

        if (options?.background) {
            params.append('background', options.background);
        }

        if (options?.color) {
            params.append('color', options.color);
        }

        if (options?.size) {
            params.append('size', options.size.toString());
        }
        if (options?.length) {
            params.append('length', options.length.toString());
        }
        if (options?.fontSize) {
            params.append('font-size', options.fontSize.toString());
        }
        return `${this.baseUrl}?${params.toString()}`;
    }
}
export interface AvatarOptions {
    name?: string;
    background?: string;
    color?: string;
    size?: number;
    length?: number;
    fontSize?:number
}