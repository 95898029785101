import React from 'react';
import styles from './Grid.module.css'; // Assuming your CSS module file is named Grid.module.css

type GridProps = {
    elementWidth: string; // This can be in any CSS unit (e.g., '200px', '20em', etc.)
    children: React.ReactNode;
    minHeight?: string; // Optional minimum height for grid items
};

const Grid: React.FC<GridProps> = ({ elementWidth, children, minHeight }) => {
    // Use elementWidth in gridTemplateColumns to dynamically adjust items per row
    const gridTemplateColumns = `repeat(auto-fit, minmax(${elementWidth}, 1fr))`;

    return (
        <div
            className={styles.grid}
            style={{ gridTemplateColumns }}
        >
            {React.Children.map(children, (child, index) => (
                <div
                    key={index}
                    className={styles.gridItem}
                    style={{ minHeight: minHeight || 'none' }}
                >
                    {child}
                </div>
            ))}
        </div>
    );
};
export default Grid;