import React, { useContext, useState } from "react";
import { Modal } from "@mui/material";
import styled from "styled-components";
import { AccountDTO } from "../../Networking/Interface/Account/AccountInterface";
import { UserContext } from "../../User/UserContext";
import { Button } from "antd";
import { SunsetAPIAccountContext } from "../../Networking/Interface/Account/AccountAPIProvider";

const CenteredH3 = styled.h3`
  text-align: center;
  margin-top: 15px;
`;

interface BanUserProps {
  accountDTO: AccountDTO;
  onSuccess: () => void;
}

function BanUser({ accountDTO, onSuccess }: BanUserProps) {
  const [banReason, setBanReason] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const api = useContext(SunsetAPIAccountContext);
  const { user } = useContext(UserContext);

  async function submitBan() {
    try {
      const BanUser = await api!.banCurrentUser(accountDTO.id, banReason); // Use api.banCurrentUser method

      if (BanUser.success) { // Assuming BanUser.success indicates success, adjust if needed
        onSuccess();
        setModalOpen(false);
        setBanReason("");
      } else {
        console.error("Failed to ban user");
      }
    } catch (error) {
      console.error("Error sending ban request:", error);
    }
  }

  const openModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setBanReason("");
  };

  if (user && user.role > 3) {
    return (
      <div>
        <Button style={{ border: "none" }} onClick={openModal}>
          Ban
        </Button>

        <Modal open={modalOpen} onClose={handleCloseModal}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "600px",
              height: "220px",
              maxHeight: "90vh",
              overflow: "auto",
              backgroundColor: "rgba(255, 255, 255, 0.76)",
              padding: "20px",
            }}
          >
            <CenteredH3>Ban {accountDTO.username}</CenteredH3>
            <textarea
              placeholder="Enter ban reason here"
              value={banReason}
              onChange={(e) => setBanReason(e.target.value)}
              style={{ width: "100%", marginBottom: "10px" }}
            />
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Button
                style={{ backgroundColor: "orange" }}
                type="primary"
                onClick={submitBan}
              >
                Submit
              </Button>
              <Button onClick={handleCloseModal}>Cancel</Button>
            </div>
          </div>
        </Modal>
      </div>
    );
  } else {
    return null; // Or any appropriate fallback
  }
}

export default BanUser;
