import React, { useContext, useEffect, useState } from "react";
import {
  AccountDTO,
  Notes,
} from "../../Networking/Interface/Account/AccountInterface";
import { SunsetAPIAccountContext } from "../../Networking/Interface/Account/AccountAPIProvider";
import Information from "../../Components/Information";
import "./Punishments.css";
import styled from "styled-components";
import { formatDateForDisplay } from "../../Utils/DateTimeUtils";

const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap; 
  justify-content: center; 
  width: 100%; 
  margin: 0;
  padding: 0; 
`;
const NoteBox = styled.div<{ important: number }>`
  position: relative;
  margin: 5px;
  padding: 30px;
  border: 1px solid #cccccc5d;
  border-radius: 5px;
  background-color: #f9f9f972;
  width: 200px;
  text-align: center;
  &::before {
    content: ${({ important }) => (important === 1 ? '"⚠️"' : '"🙋‍♂️"')};
    position: absolute;
    top: 1px;
    font-size: x-large;
    left: 83px;
  }

  &:hover::after {
    content: ${({ important }) =>
      important === 1 ? '"Important"' : '"Non-important"'};
       ${({ important }: { important: number }) =>
    important === 1 ? "border: 1px red solid;" : ""};
    position: absolute;
    top: 5px;
    left: 80%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.316);
    color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.6rem;
    z-index: 999;
  }
`;
const AdminText = styled.p`
  font-weight: bold;
  margin: 5px 0;
`;

const DescriptionText = styled.p`
  margin: 5px 0;
  text-overflow: ellipsis;
`;

const NoNotesMessage = styled.p`
  text-align: center;
 
`;

interface NotesProps {
  account: AccountDTO;
}

const NotesLookup: React.FC<NotesProps> = ({ account }) => {
  const [notes, setNotes] = useState<Notes[]>([]);
  const api = useContext(SunsetAPIAccountContext);

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const response = await api!.getNotes(account.username);
        if (response && response.success && response.data) {
          setNotes(response.data);
        } else {
          setNotes([]);
        }
      } catch (error) {
        setNotes([]);
      }
    };

    fetchNotes();
  }, [api, account.username]);

  return (
    <div>
      <Information title="Account notes">
        {notes.length > 0 ? (
          <StyledContainer className="note-container">
            {notes.map((note) => (
              <NoteBox key={note.id} important={note.important}>
                <AdminText>Admin: {note.admin}</AdminText>
                <DescriptionText>Description: {note.description}</DescriptionText>
                <DescriptionText>
                  Time: {formatDateForDisplay(new Date(note.time))}
                </DescriptionText>
              </NoteBox>
            ))}
          </StyledContainer>
        ) : (
          <NoNotesMessage>User has no notes. </NoNotesMessage>
        )}
      </Information>
    </div>
  );
};

export default NotesLookup;
