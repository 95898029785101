import React, { useState, useEffect } from "react";
import styled from "styled-components";
import styles from "./BalanceTopUpView.module.css";

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Heading = styled.h3`
  color: #333;
  text-align: center;
  margin-bottom: 5px;
`;

const AmountSection = styled.div`
  margin-bottom: 5px;
  text-align: center;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 5px;
  font-size: 16px;
  border: 2px solid #3498db;
  border-radius: 5px;
  transition: border-color 0.3s ease;

  &:focus {
    border-color: #2980b9;
    box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
    outline: none;
  }
`;

const WarningMessage = styled.p`
  color: red;
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
`;

const ConversionEstimates = styled.div`
  text-align: center;
  margin-bottom: 10px;
`;

const PriceDisplay = styled.p`
  font-size: 18px;
  line-height: 1.5;
  color: #555;
  font-weight: 600;
  margin: 5px;
  padding: 5px;
`;

const PaymentOptionsContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
`;

const PaymentButton = styled.button`
  background: ${props => (props.disabled ? "#ccc" : "white")};
  border-radius: 10px;
  padding: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 10px;
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  transition: border 0.3s ease;

  &:hover {
    border: 2px solid #0070ba;
  }

  img {
    width: 60px;
  }
`;

const Image = styled.img`
  width: 60px;
  height: auto;
`;

type CustomAmountSectionProps = {
  inputAmount: string;
  setInputAmount: (amount: string) => void;
  selectedPaymentMethod: string;
  handlePaymentMethodSelect: (method: string) => void;
  selectedCreditOption: string;
  activateCustomSection: () => void;
};

const CustomAmountSection: React.FC<CustomAmountSectionProps> = ({
  inputAmount,
  setInputAmount,
  selectedPaymentMethod,
  handlePaymentMethodSelect,
  selectedCreditOption,
  activateCustomSection,
}) => {
  const [creditAmount, setCreditAmount] = useState<number>(0);
  const [priceInGBP, setPriceInGBP] = useState<number>(0);
  const [showWarning, setShowWarning] = useState<boolean>(false);

  useEffect(() => {
    const parsedAmount = parseInt(inputAmount);
    if (!inputAmount || isNaN(parsedAmount)) {
      setCreditAmount(0);
      setPriceInGBP(0);
      return;
    }

    setCreditAmount(parsedAmount);

    // Calculate price in GBP based on the assumption 10 credits = 1 GBP
    const price = parsedAmount / 10;
    setPriceInGBP(price);

    // Show warning if amount is less than 20
    if (parsedAmount < 20) {
      setShowWarning(true);
    } else {
      setShowWarning(false);
    }
  }, [inputAmount]);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputAmount(value);
  };

  const handlePaymentMethodClick = (method: string) => {
    if (creditAmount >= 20) {
      handlePaymentMethodSelect(method);
    }
  };

  return (
    <Container>
      <Heading>Buy Custom Credits</Heading>
      <AmountSection>
        <StyledInput
          type="number"
          id="amount"
          value={inputAmount}
          onChange={handleAmountChange}
          className={`${styles.inputField} ${styles.optionalField}`}
          placeholder="Enter credits"
          min="1" // Allow user to type any value
        />
        {showWarning && (
          <WarningMessage>Minimum credit amount is 20.</WarningMessage>
        )}
      </AmountSection>
      <ConversionEstimates>
        <PriceDisplay>Credits: {creditAmount}</PriceDisplay>
        <PriceDisplay>Price in GBP: £{priceInGBP.toFixed(2)}</PriceDisplay>
      </ConversionEstimates>
      {inputAmount && (
        <PaymentOptionsContainer>
          <PaymentButton
            disabled={creditAmount < 20}
            className={selectedPaymentMethod === "paypal" ? styles.selectedPayment : ""}
            onClick={() => handlePaymentMethodClick("paypal")}
          >
            <Image src={`${process.env.PUBLIC_URL}/paypal.png`} alt="Pay with PayPal" />
          </PaymentButton>
          <PaymentButton
            disabled={creditAmount < 20}
            className={selectedPaymentMethod === "stripe" ? styles.selectedPayment : ""}
            onClick={() => handlePaymentMethodClick("stripe")}
          >
            <Image src={`${process.env.PUBLIC_URL}/Stripe.png`} alt="Pay with Stripe" />
          </PaymentButton>
        </PaymentOptionsContainer>
      )}
    </Container>
  );
};

export default CustomAmountSection;
