import React from 'react';
import { SunsetAPI } from "./SunsetAPI";

interface SunsetAPIProviderProps {
    children: React.ReactNode;
}

const SunsetAPIContext = React.createContext<SunsetAPI | undefined>(undefined);

const SunsetAPIProvider: React.FC<SunsetAPIProviderProps> = ({ children }) => {
    const apiURL = "http://localhost:7203/";
    const api = new SunsetAPI();

    return (
        <SunsetAPIContext.Provider value={api}>
            {children}
        </SunsetAPIContext.Provider>
    );
};

export { SunsetAPIProvider, SunsetAPIContext };