import React, { useContext } from "react";
import { Wealth } from "../../Networking/Interface/Account/AccountInterface";
import styles from "./WealthView.module.css";
import SetCashButton from "../../Staff/Actions/SetCash";
import { UserContext } from "../../User/UserContext";

type WealthViewProps = {
  characterName: string;
  userId: number;
  wealth: Wealth;
  setWealth: (wealth: Wealth) => void;
};

const WealthView: React.FC<WealthViewProps> = ({
  wealth,
  characterName,
  userId,
  setWealth,
}) => {
  const { user } = useContext(UserContext);
  const hasRole = (minLevel: number) => {
    return user && user.role >= minLevel;
  };
  return (
    <div className={styles.container}>
      <div className={styles.onHandWealth}>
        <h2>On Hand Wealth</h2>
        <div className={styles.amount}>
          ${wealth.onHandWealth.toLocaleString()}
        </div>
      </div>
      <div className={styles.bankAccounts}>
        <h2>Bank Accounts</h2>
        {wealth.bankAccountWealth.map((amount, index) => (
          <div key={index} className={styles.account}>
            Account {index + 1}: ${amount.toLocaleString()}
          </div>
        ))}
      </div>
      <div className={styles.setCashButton}>
        {hasRole(6) && (
          <SetCashButton
            userId={userId}
            characterName={characterName}
            setWealth={setWealth}
          />
        )}
      </div>
    </div>
  );
};

export default WealthView;
