import { useState } from "react";
import "./Dropdown.css"
interface DropdownProps {
    options: string[];
    selected: string[];
    onChange: (selected: string[]) => void;
}

const DropdownCheckbox: React.FC<DropdownProps> = ({ options, selected, onChange}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleCheckboxChange = (option: string) => {
        if (selected.includes(option)) {
            onChange(selected.filter(item => item !== option));
        } else {
            onChange([...selected, option]);
        }
    };

    return (
        <div className="dropdown-menu"
        onMouseEnter={() => setIsOpen(true)}  
        onMouseLeave={() => setIsOpen(false)} 
        >
        
            <button className="u-btn-gray">
                {selected.length} subcategories selected
            </button>
            {isOpen && (
                <div className="dropdown-menu-content">
                    {options.map((option, idx) => (
                        <div key={`${idx}`}>
                            <input
                                id={`checkbox-${option}`}
                                type="checkbox"
                                value={option}
                                checked={selected.includes(option)}
                                onChange={() => handleCheckboxChange(option)}
                            />
                            <label htmlFor={`checkbox-${option}`}>
                                {option}
                            </label>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default DropdownCheckbox;
