import React, { useState } from 'react';
import ReactConfetti from 'react-confetti';
import { createPortal } from 'react-dom';

interface ConfettiProps {
    density: number; // Density of the confetti
}

const Confetti: React.FC<ConfettiProps> = ({ density }) => {
    const [isActive, setIsActive] = useState(true);

    // Define the inline style for the confetti container
    const confettiStyle: React.CSSProperties = {
        position: 'fixed', // Use fixed to position relative to the viewport
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        pointerEvents: 'none', // Allows interaction with elements beneath the confetti
        zIndex: 9999, // High z-index to ensure it's above other elements
    };

    return isActive
        ? createPortal(
            <div style={confettiStyle}>
                <ReactConfetti
                    numberOfPieces={density}
                    colors={['#FFA500', '#FFD700', '#C0C0C0']}
                    recycle={false}
                    onConfettiComplete={() => setIsActive(false)}
                />
            </div>,
            document.body
        )
        : null;
};

export default Confetti;
