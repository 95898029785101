export interface APIResponse<T> {
    success: boolean;
    code: number;
    data?: T;  
    error?: string; 
}

export class APIUtility {
    baseURL: string;
    private defaultHeaders: Record<string, string> = {};
    constructor(baseURL: string) {
        this.baseURL = baseURL;
    }

    
    setDefaultHeaders(headers: Record<string, string>): void {
        this.defaultHeaders = headers;
    }
    private fetchWithTimeout(url: string, options: RequestInit = {}, timeout: number = 10000): Promise<Response> {
        const controller = new AbortController();
        const id = setTimeout(() => controller.abort(), timeout);

        const fetchOptions: RequestInit = {
            ...options,
            signal: controller.signal,
            credentials: 'include',
            headers: {
                ...this.defaultHeaders,
                ...options.headers, // Include any headers from options instead of hardcoding here
            },
        };

        return fetch(url, fetchOptions).finally(() => clearTimeout(id));
    }
    private async handleResponse<T>(response: Response): Promise<APIResponse<T>> {
        
  
        const data = await response.json();
        return {
            success: response.ok,
            code: response.status,
            data: data.data,
            error: data.error
        };
    }

    private async request<T>(method: string, endpoint: string, body?: any): Promise<APIResponse<T>> {
        try {
            const fetchOptions: RequestInit = {
                method: method,
                headers: {
                    ...this.defaultHeaders,
        
                },
                credentials: 'include',
            };

            if (body instanceof FormData) {
                fetchOptions.body = body;
            } else if (body) {
                fetchOptions.body = JSON.stringify(body);
                // Ensure 'Content-Type' is set to 'application/json' only when body is NOT FormData
                fetchOptions.headers = {
                    ...fetchOptions.headers,
                    'Content-Type': 'application/json',
                };
            }

            const response = await this.fetchWithTimeout(`${this.baseURL}${endpoint}`, fetchOptions);
            return await this.handleResponse<T>(response);
        } catch (error) {

            return {
                success: false,
                code: 500,
                error: "An error occured while reaching the API, you could be ratelimited or the API is down."
            };
        }
    }


    async get<T>(endpoint: string, headers?:Record<string, string> ): Promise<APIResponse<T>> {
        return this.request<T>('GET', endpoint);
    }

    async post<T>(endpoint: string, body?: any): Promise<APIResponse<T>> {
        return this.request<T>('POST', endpoint, body);
    }
    async put<T>(endpoint: string, body?: any): Promise<APIResponse<T>> {
        return this.request<T>('PUT', endpoint, body);
    }
    async delete<T>(endpoint: string, body?: any): Promise<APIResponse<T>> {
        return this.request<T>('DELETE', endpoint, body);
    }
}