// CharacterSelection.js
import React, { useState } from 'react';
import { CharacterDTO } from '../Networking/Interface/Account/AccountInterface';
import styles from './CharacterSelection.module.css'; // Import the module CSS

interface Props {
    characters: CharacterDTO[];
    onCharacterSelect: (character: CharacterDTO) => void; // Callback function when selection updates
}

const CharacterSelection: React.FC<Props> = ({ characters, onCharacterSelect }) => {
    const [selectedCharacterId, setSelectedCharacterId] = useState<number | null>(null);

    const handleSelect = (character: CharacterDTO) => {
        setSelectedCharacterId(character.id);
        onCharacterSelect(character); // Call the prop function with the selected character
    };

    return (
        <div className={styles.container}>
            {characters.map((character) => (
                <div
                    key={character.id}
                    className={`${styles.characterCard} ${selectedCharacterId === character.id ? styles.selected : ''}`}
                    onClick={() => handleSelect(character)}
                >
                    <img
                        src={`${process.env.PUBLIC_URL}/samp/headshots/${character.lastSkin}.png`}
                        alt={character.characterName}
                        className={styles.characterImage}
                    />
                    <div className={styles.characterInfo}>
                        <h3 className={styles.characterName}>{character.characterName}</h3>                    
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CharacterSelection;
