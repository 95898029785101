import React from 'react';
import styles from './Table.module.css';




interface TableProps {
    headers: string[];
    children: React.ReactNode;
}

export const Table: React.FC<TableProps> = ({ headers, children }) => {
    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    {headers.map((header, index) => (
                        <th key={index}>{header}</th>
                    ))}
                </tr>
            </thead>
            <tbody>{children}</tbody>
        </table>
    );
};

interface TableRowProps {
    children: React.ReactNode;
}

export const TableRow: React.FC<TableRowProps> = ({ children }) => {
    return <tr className={styles.row}>{children}</tr>;
};

