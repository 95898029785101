import React from 'react';
import "./InfoLineView.css"


export type InfoLineViewProps = {
    children: React.ReactNode;
    title: string;
}
const InfoLineView: React.FC<InfoLineViewProps> = ({ children, title }) => (
    <div className="info-line-container" data-title={title}>
        {children}
    </div>
);

export default InfoLineView;
export type InfoLineProps = {
    label: string;
    value: any;
};

export const InfoLine: React.FC<InfoLineProps> = ({ label, value }) => (
    <div className="info-pair">
        <div>{label}</div>
        <div className="divider">|</div>
        <div>{value?.toString()}</div>
    </div>
);

