import React, { useState } from "react";
import { Modal } from "@mui/material";
import styled from "styled-components";
import { Button } from "antd";
import UseButton from "./UseButton"; 
import ViewButton from "./ViewButton";

export interface File {
  Name: string;
  Path: string;
  Size: number;
  LastModified: string;
  Hash: string;
  Blacklisted: boolean;
  Content: string | null;
  Remark: string | null;
}

interface FileModalProps {
  open: boolean;
  userId: number;
  onClose: () => void;
  files: File[];
  deletedFiles?: File[];
  deletedFilesCount?: number;
}

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  background: #f2f2f2;
  padding: 5px;
  text-align: left;
  color: black;
  border-bottom: 1px solid #ddd;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background: #f9f9f9;
  }
`;

const TableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;

const DeleteModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
`;

const DeleteTable = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const DeleteTableHeader = styled.th`
  background: #f2f2f2;
  padding: 5px;
  text-align: left;
  color: black;
  border-bottom: 1px solid #ddd;
`;

const DeleteTableRow = styled.tr`
  &:nth-child(even) {
    background: #f9f9f9;
  }
`;

const DeleteTableCell = styled.td`
  padding: 10px;
  border-bottom: 1px solid #ddd;
`;
const Badge = styled.span<{ blacklisted: boolean }>`
  display: inline-block;
  padding: 0.25em 0.5em;
  font-size: 0.75em;
  color: #fff;
  background-color: ${(props) => (props.blacklisted ? "red" : "green")};
  border-radius: 12px;
  text-align: center;
`;

export interface File {
  Name: string;
  Path: string;
  Size: number;
  LastModified: string;
  Hash: string;
  Blacklisted: boolean;
  Content: string | null;
  Remark: string | null;
}

interface FileModalProps {
  open: boolean;
  userId: number;
  onClose: () => void;
  files: File[];
  deletedFiles?: File[];
  deletedFilesCount?: number;
}
const FileModal: React.FC<FileModalProps> = ({
  files,
  deletedFiles = [],
  deletedFilesCount = 0,
  open,
  userId,
  onClose,
}) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const handleDeleteClick = () => {
    setDeleteModalOpen(true);
  };

  const parsedDeletedFiles =
    typeof deletedFiles === "string" ? JSON.parse(deletedFiles) : deletedFiles;

  return (
    <>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ModalContent>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h3>Files</h3>
            <Button onClick={handleDeleteClick}>
              {deletedFilesCount} Deleted Files
            </Button>
          </div>
          <Table>
            <thead>
              <tr>
                <TableHeader>Name</TableHeader>
                <TableHeader>Size</TableHeader>
                <TableHeader>Last Modified</TableHeader>
                <TableHeader>Hash</TableHeader>
                <TableHeader>Blacklisted</TableHeader>
                <TableHeader>Content</TableHeader>
                <TableHeader>Remarks</TableHeader>
                <TableHeader>Actions</TableHeader>
                <TableHeader>Path</TableHeader>
              </tr>
            </thead>
            <tbody>
              {files.map((file, index) => {
                const { dateString, timeString } = getDateTime(
                  file.LastModified
                );
                return (
                  <TableRow key={index}>
                    <TableCell>{file.Name}</TableCell>
                    <TableCell>{file.Size.toLocaleString()} bytes</TableCell>
                    <TableCell>
                      {dateString} {timeString}
                    </TableCell>
                    <TableCell>{file.Hash}</TableCell>
                    <TableCell>
                      <Badge blacklisted={file.Blacklisted}>
                        {file.Blacklisted ? "Yes" : "No"}
                      </Badge>
                    </TableCell>
                    <TableCell>{file.Content ? file.Content : "N/A"}</TableCell>
                    <TableCell>{file.Remark ? file.Remark : ""}</TableCell>
                    <TableCell>
                      <ViewButton
                      userId = {userId || 0}
                       fileName={file.Name}
                       fileHash={file.Hash}
                      />
                      <UseButton
                        fileName={file.Name}
                        fileHash={file.Hash}
                      />{" "}
                  
                    </TableCell>
                    <TableCell>{file.Path}</TableCell>
                  </TableRow>
                );
              })}
            </tbody>
          </Table>
        </ModalContent>
      </Modal>
      <Modal
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="delete-modal-title"
        aria-describedby="delete-modal-description"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <DeleteModalContent>
          <h4>Deleted Files</h4>
          <DeleteTable>
            <thead>
              <DeleteTableRow>
      
                <DeleteTableHeader>File Path</DeleteTableHeader>
              </DeleteTableRow>
            </thead>
            <tbody>
              {Array.isArray(parsedDeletedFiles) &&
              parsedDeletedFiles.length > 0 ? (
                parsedDeletedFiles.map((filePath, index) => (
                  <DeleteTableRow key={index}>
                    <DeleteTableCell>{filePath}</DeleteTableCell>
                  </DeleteTableRow>
                ))
              ) : (
                <DeleteTableRow>
                  <DeleteTableCell colSpan={2}>
                    No deleted files
                  </DeleteTableCell>
                </DeleteTableRow>
              )}
            </tbody>
          </DeleteTable>
        </DeleteModalContent>
      </Modal>
    </>
  );
};

export default FileModal;

function getDateTime(date: string | Date): {
  dateString: string;
  timeString: string;
} {
  const d = new Date(date);
  const dateString = d.toLocaleDateString();
  const timeString = d.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
  });
  return { dateString, timeString };
}

