import React, { useState, useEffect } from 'react';
import cratesData from './crates.json';
import styles from './CrateDescription.module.css';
import { Crate } from './Models/Crate';
import List from '../../Components/List';

interface CrateDescriptionProps {
    crateID: number;
}

const CrateDescription: React.FC<CrateDescriptionProps> = ({ crateID }) => {
    const [crate, setCrate] = useState<Crate | null>(null); // Corrected type to Crate | null

    useEffect(() => {

        const matchingCrate = cratesData.crates.find((c: Crate) => c.ID === crateID);

        setCrate(matchingCrate || null);
    }, [crateID]);


    if (!crate) return null; 

    return (
        <div className={styles.crateDescription}>
            <h2 className={styles.crateName}>{crate.Name}</h2>
            <p className={styles.crateDesc}>{crate.Description}</p>
            <List items={crate.Obtainability} />
        </div>
    );
};

export default CrateDescription;
