import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Tooltip from "../../Components/Tooltip";
import { AccountBalance } from "../../Networking/Interface/Account/AccountInterface";
import { UserContext } from "../../User/UserContext";
import BalanceTopUpViewSixy from "./BalanceTopUpViewSixy";
import styles from "./BalanceView.module.css";
import { SunsetAPIContext } from "../../Networking/ApiProvider";
import { useToast } from "../../Components/Toast/ToastContext";
import { ToastType } from "../../Components/Toast/Toast";

interface ConversionRates {
  USD: number;
  EUR: number;
  GBP: number;
}

const BalanceViewSixy: React.FC = () => {
  const api = useContext(SunsetAPIContext);
  const { user, isLoading } = useContext(UserContext);
  const [balance, setBalance] = useState<AccountBalance | null>(null);
  const [showTopUpModal, setShowTopUpModal] = useState<boolean>(false);
  const { showToast } = useToast();
  const [conversionRates, setConversionRates] = useState<ConversionRates>({
    USD: 0,
    EUR: 0,
    GBP: 0,
  });
  const navigate = useNavigate(); // Hook from react-router-dom
  const location = useLocation();

  useEffect(() => {
    if (!isLoading && user?.id) {
      (async () => {
        try {
          const response = await api!.getAccountBalance(Number(user.id));
          if (response.success) {
            setBalance(response.data!);
          }
        } catch (error) {
          console.error(
            "An error occurred while fetching the account balance:",
            error
          );
        }
      })();
    }
  }, [api, user, isLoading]);

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        const response = await fetch(
          `https://api.frankfurter.app/latest?from=GBP&to=USD,EUR`
        );
        const data = await response.json();
        setConversionRates(data.rates as ConversionRates);
      } catch (error) {
        console.error("Failed to fetch exchange rates", error);
      }
    };

    fetchExchangeRates();
  }, []);

  const handleCloseModal = () => {
    setShowTopUpModal(false);
  };

  const getPackageDescription = (packageId: number) => {
    const descriptions: { [key: number]: string } = {
      1: "Bronze Package",
      2: "Silver Package",
      3: "Gold Package",
      4: "Platinum Package",
    };
    return descriptions[packageId] || "No Donation Packages Owned";
  };

  const packageImages: any = {
    1: "bronze.png",
    2: "silver.png",
    3: "golden.png",
    4: "platinum.png",
  };

  const handleAddCreditsClick = () => {
    // Save the current location before redirecting to login
    if (!user) {
      showToast(ToastType.Error, "Please login to add credits.", 3);
      navigate("/login", { state: { from: location.pathname } });
    } else {
      navigate("/donations");
      setShowTopUpModal(true);
    }
  };

  return (
    <div className={styles.balanceContainer}>
      <div className={styles.packageDisplay}>
        {balance && balance.package > 0 ? (
          <>
            <img
              src={`${process.env.PUBLIC_URL}/${
                packageImages[balance.package]
              }`}
              alt={getPackageDescription(balance.package)}
              className={styles.packageImage}
            />
            <div className={styles.packageDetails}>
              <h2>{getPackageDescription(balance.package)}</h2>
              <p>
                Expires on:{" "}
                {balance.packageEnd
                  ? new Date(balance.packageEnd).toLocaleDateString()
                  : "N/A"}
              </p>
            </div>
          </>
        ) : (
          <p className={styles.noPackage}>{getPackageDescription(0)}</p>
        )}
      </div>
      <div className={styles.creditsDisplay}>
        <div className={styles.creditAmount}>
          <h1>{balance?.credits ?? 0}</h1>
          <img
            src={`${process.env.PUBLIC_URL}/credit.png`}
            alt="Credits"
            className={styles.creditImage}
          />
        </div>
        <Tooltip
          content={`1 GBP = ${conversionRates.USD.toFixed(
            2
          )} USD / ${conversionRates.EUR.toFixed(2)} EUR.`}
        >
          <span className={styles.priceDisplay}>
            Conversion rate: ${conversionRates.USD.toFixed(2)} / €
            {conversionRates.EUR.toFixed(2)}
          </span>
        </Tooltip>
        <div className={styles.creditDetails}>
          <p>Total Credits: {balance?.totalCredits ?? 0}</p>
        </div>
      </div>
      <button
        className={styles.addCreditsButton}
        onClick={handleAddCreditsClick}
      >
        Add Credits
      </button>
      {showTopUpModal && (
        <BalanceTopUpViewSixy
          onClose={handleCloseModal}
          conversionRates={conversionRates}
        />
      )}
    </div>
  );
};

export default BalanceViewSixy;
