import { PromoCode } from "../../Networking/SunsetAPI";

export class CreditCostCalculator {
    private readonly gbpCreditConversion: number = 10;
    private readonly maxAmount: number = 100000; //
    private readonly minAmount: number = 50; // 

    constructor(gbpCreditConversion: number) {
        this.gbpCreditConversion = gbpCreditConversion;
    }

    calculateCreditCost(creditAmount: number, promoCode?: PromoCode): number {
        // Correcting the amount to ensure it's within the allowed range
        if (isNaN(creditAmount) || creditAmount < this.minAmount) {
            creditAmount = this.minAmount;
        } else if (creditAmount > this.maxAmount) {
            creditAmount = this.maxAmount;
        }

        let paymentAmount = 0;
        if (creditAmount !== 0) {
            paymentAmount = creditAmount / this.gbpCreditConversion;
        }

        if (promoCode) {
            paymentAmount -= promoCode.discountFlat;
            if (paymentAmount <= 0) paymentAmount = 0;
            paymentAmount *= 1 - (promoCode.discountPercent / 100);
        }

        return paymentAmount;
    }
}