import React from 'react';
import { UserRole } from '../Utils/enums';
import shield  from './../img/icons8-shield-94.png'


interface UserRoleBadgeProps {
  role: UserRole;

}


// Define a mapping from enum values to role names
const roleNames = {
  [UserRole.User]: 'Player',
  [UserRole.Trusted]: 'Trusted',
  [UserRole.Helper]: 'Helper',
  [UserRole.Mod]: 'Moderator',
  [UserRole.Admin]: 'Admin',
  [UserRole.SnrAdmin]: 'Senior Admin',
  [UserRole.LeadAdmin]: 'Lead Admin',
  [UserRole.ServerLeader]: 'Server Leader',
  [UserRole.Manager]: 'Manager',
  [UserRole.Owner]: 'Owner',
};

interface UserRoleBadgeProps {
  role: UserRole;
}
interface RoleData {
  backgroundColor: string;

}


const UserRoleBadge: React.FC<UserRoleBadgeProps> = ({ role }) => {
  const roleData = getColorForRole(role);
  const displayText = roleNames[role];

  return (
    <div className='badge-container'>
      {roleData && (
        <div  className='user-role-badge'
        style={{
          backgroundColor: roleData.backgroundColor,
          display: 'flex',
          alignItems: 'center',
            borderRadius: '4px',
          maxWidth:"120px"
        }}
        >
          <img src={shield} alt="Role Badge" className="shield" />
          {displayText}
        </div>
      )}
    </div>
  );
};


export default UserRoleBadge;

function getColorForRole(role: UserRole): RoleData {
  switch (role) {
    case UserRole.User:
      return { backgroundColor: 'darkgray' };
    case UserRole.Trusted:
      return { backgroundColor: 'darkgray' };
    case UserRole.Helper:
      return { backgroundColor: '#32abaf' };
    case UserRole.Mod:
      return { backgroundColor: '#a671bf' };
    case UserRole.Admin:
      return { backgroundColor: '#8a2be2' }; // Purple text color
    case UserRole.SnrAdmin:
      return { backgroundColor: '#7a1bd2' }; // Darker purple text color
    case UserRole.LeadAdmin:
      return { backgroundColor: '#6a0ac2' }; // Even darker purple text color
      case UserRole.ServerLeader: 
      return {backgroundColor: '#e0218a' };
    case UserRole.Manager:
      return { backgroundColor: 'orange' };
    case UserRole.Owner:
      return { backgroundColor: 'red' };
    default:
      return { backgroundColor: 'transparent' };
  }
}
