// Assuming you are in GoldShinyButton.tsx
import React from 'react';
import styles from './GoldShinyButton.module.css'; // The CSS module for the button

// Define the interface for props here
interface GoldShinyButtonProps {
    text: string;
    onClick: () => void; 
}

const GoldShinyButton: React.FC<GoldShinyButtonProps> = ({ text, onClick }) => {
    return (
        <button className={styles.addCreditsButton} onClick={onClick}>
            {text}
        </button>
    );
};

export default GoldShinyButton;
