import React, { useState } from "react";
import { useToast } from "../Components/Toast/ToastContext";
import { ToastType } from "../Components/Toast/Toast";
import { Button } from "antd";

interface GetHotkeyProps {
  userId: number;
}

const GetHotkey: React.FC<GetHotkeyProps> = ({ userId }) => {
  const { showToast } = useToast();

  async function handleGetHotkeys() {
    try {
      const response = await fetch(
        `https://ss-rp.com/exp-api/launcher/hotkeys/list/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ userId: userId }), // Include the userId in the body
        }
      );
  
      if (response.ok) {
        // Check if the response has content before parsing
        const text = await response.text();
        if (text) {
          const result = JSON.parse(text);
  
          showToast(ToastType.Success, "Hotkeys retrieved", 3);
        } else {
          showToast(ToastType.Information, "Request sent", 3);
        }
      } else {
        showToast(ToastType.Error, "Failed to retrieve hotkeys", 3);
      }
    } catch (error) {
      console.error("Error fetching hotkeys:", error);
      showToast(ToastType.Error, "Error fetching hotkeys", 3);
    }
  }
  

  return (
    <div>
      <Button style={{ border: "none" }} onClick={handleGetHotkeys}>
        Request Hotkeys
      </Button>
    </div>
  );
};

export default GetHotkey;
