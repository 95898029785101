import React from "react";
import { useToast } from "../Components/Toast/ToastContext";
import { ToastType } from "../Components/Toast/Toast";
import { Button } from "antd";

interface GetDirectoryProps {
  userId: number;
}

const GetDirectory: React.FC<GetDirectoryProps> = ({ userId }) => {
  const { showToast } = useToast();
  async function handleDirectory() {
    try {
      const response = await fetch(
        `https://ss-rp.com/exp-api/launcher/directory/list/${userId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          credentials: "include",
          body: JSON.stringify({ userId: userId }),
        }
      );
  
      if (response.ok) {
        // Check if the response has content before parsing
        const text = await response.text();
        if (text) {
          const data = JSON.parse(text);
     
          showToast(ToastType.Success, "Directory requested", 3);
        } else {
          showToast(ToastType.Information, "Directory requested", 3);
        }
      } else {
        console.error("Failed to fetch directory data");
        showToast(ToastType.Error, "Failed to fetch directory data", 3);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      showToast(ToastType.Error, "Error fetching data", 3);
    }
  }
  

  // async function handleDirectory() {
  //   try {
  //     const response = await fetch(
  //       `https://ss-rp.com/exp-api/launcher/directory/list/${userId}`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         credentials: "include",
  //         body: JSON.stringify({ userId: userId }),
  //       }
  //     );

  //     if (response) {
  //       const data = await response.json();

  //       showToast(ToastType.Success, "Direcxtory requested", 3);
  //     } else {
  //       console.error("Failed to fetch directory data");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // }

  return (
    <div>
      <Button style={{ border: "none" }} onClick={handleDirectory}>
        Request Directory
      </Button>
    </div>
  );
};

export default GetDirectory;
