import styled, { css } from 'styled-components';

const sizes = {
  small: css`
    font-size: 0.8rem;
    padding: 0.8 1.2rem;
    font-weight: 250;
    text-align: center;
  `,
  medium: css`
    font-size: 0.9rem;
    padding: 1.1rem 1.6rem;
    font-weight: 500;
  `,
  large: css`
    /* font-size: 1.9rem; */
    /* padding: 1.2rem 3.2rem; */
    font-weight: 200;
  `,
};

const variations = {
  primary: css`
    color: var(--whiteish-color);
    background-color: var(--orange-color);
    box-shadow: var(--shadow-light);
     width: 7.1rem;
    
    &:hover {
      background-color: darken(var(--orange-color), 10%);
      transform: scale(1.05);
    }
  `,
  secondary: css`
    color: var(--color-grey-600);
    background: var(--color-grey-0);
    border: 1px solid var(--color-grey-200);
    width: 7.1rem;
    
    &:hover {
      background-color: var(--color-grey-50);
    }
  `,
  danger: css`
    color: white;
    background-color: #ab2d2d;

    &:hover {
      background-color: var(--color-red-800);
    }
  `,
};

const Button = styled.button`
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: var(--btn-transition);
  background: var(--orange-color);
  color: var(--whiteish-color);
  box-shadow: var(--shadow-light);
  margin: 0.5rem;
  padding: 12px 24px;

  ${(props) => sizes[props.size]}
  ${(props) => variations[props.variation]}
`;

Button.defaultProps = {
  variation: "primary",
  size: "medium",
};

export default Button;

