import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from './CrateViewer.module.css';
import { CrateOpening, CrateReward } from '../../Networking/Interface/Gamba/GambaInterface';
import OpeningsView from './CrateOpeningsView';
import Information from '../../Components/Information';
import CrateRewardView from './CrateRewardView';
import { CrateOpener } from './CrateOpener';
import CrateDescription from './CrateDescription';
import Description from '../../Components/Description';
import { UserContext } from '../../User/UserContext';
import OrangeButton from '../../Components/OrangeButton';
import { SunsetAPIGambaContext } from '../../Networking/Interface/Gamba/GambaAPIProvider';


const CrateContainerView: React.FC = () => {
    const { crateID } = useParams<{ crateID: string }>();
    
    const location = useLocation();
    const api = useContext(SunsetAPIGambaContext);
    const { user, isLoading } = useContext(UserContext);
    const [openings, setOpenings] = useState<CrateOpening[]>([]);
    const [rewards, setRewards] = useState<CrateReward[]>([]);
    const navigate = useNavigate();

    const handleLogin = () => {
        navigate('/login', { state: { from: location } });
    };
    async function fetchOpenings() {
        const response = await api?.getOpenings(Number(crateID));
        if (response?.success) {
            setOpenings(response.data!);
        }
    }
    useEffect(() => {
        if (!crateID) {
            return;
        }

        const fetchData = async () => {

            const rewardsResponse = await api!.getCrateRewards(Number.parseInt(crateID));
            if (rewardsResponse.success) {
                setRewards(rewardsResponse.data!);
            }
            fetchOpenings();
            
        }
        fetchData();
    }, [api, crateID]);

    const handleCrateOpened = () => {
        fetchOpenings(); 
    };



    return (
        <div className={styles.wrapper}>
            <div className={styles.crateContainer}>
                <CrateDescription crateID={Number(crateID)}></CrateDescription>
                
            </div>
            <div className={styles.crateContainer}>
                {
                    (!user || isLoading) ? (
                        <OrangeButton onClick={handleLogin}>Login</OrangeButton>
                    ) : (
                        <CrateOpener crateID={Number(crateID)} onCrateOpened={handleCrateOpened}></CrateOpener>
                    )
                }
                
            </div>
            <div className={styles.crateContainer}>
                <Information title="Rewards">
                    <CrateRewardView rewards={rewards} />
                </Information>
                <Description size={15} mode="Light" text="Crate openings are provably fair. Randomization logic code is viewable on request.
                All probabilities are clearly stated and reflect on the actual unboxing chance values. Crate openings are non-refundable, unless a clear system fault was identified. &br 
                Purchasing crates from third party resellers is strictly prohibitied. Reselling crates for real-life currency is strictly prohibited.
                "></Description>
            </div>
            <div className={styles.crateContainer}>
                <Information title="Recent openings">
                    <OpeningsView openings={openings}></OpeningsView>
                </Information>
            </div>
            
        </div>
    );
}

export default CrateContainerView;
