import React, { useContext, useEffect, useState } from "react";
import { Pie } from 'react-chartjs-2';
import { Link } from "react-router-dom";
import { BlackCard } from "../../../Components/BlackCard";
import { APIResponse } from "../../../Networking/ApiUtility";
import "./FactionGraphs.css";
import "./FactionPunishments.css"
import { Punishment } from "../../../Networking/Interface/Account/AccountInterface";
import { SunsetAPIFactionContext } from "../../../Networking/Interface/Faction/FactionApiProvider";
interface ChartData {
    labels: string[];
    userIds: number[];  
    datasets: {
        data: number[];
        backgroundColor: string[];
    }[];
}
interface PunishmentChartProps {
    tag:string
}
export const FactionPunishmentChart: React.FC<PunishmentChartProps> = ({ tag }) => {
    const [chartData, setChartData] = useState<ChartData>({
        labels: [],
        userIds: [],
        datasets: [{ data: [], backgroundColor: [] }],
    });

    const apiContext = useContext(SunsetAPIFactionContext);

    const fetchPunishments = async () => {
        try {
            const response: APIResponse<Punishment[]> = await apiContext!.getFactionPunishmentsByTag(tag);
            return response.success ? response.data : [];
        } catch (error) {
            console.error("Error fetching data:", error);
            return [];
        }
    };

    const transformData = (punishments: Punishment[]): ChartData => {
        const userPunishmentsData = punishments.reduce<{ [key: string]: { count: number, userId: number } }>(
            (acc, punishment) => {
                acc[punishment.userUsername] = {
                    count: (acc[punishment.userUsername]?.count || 0) + 1,
                    userId: punishment.userid
                };
                return acc;
            },
            {}
        );

        // Sort users by punishment count in descending order
        const sortedUsers = Object.entries(userPunishmentsData).sort(([, a], [, b]) => b.count - a.count);

        // Take top 12 users
        const topUsers = sortedUsers.slice(0, 12);

        // Aggregate all other users into 'Others' category
        const otherUsers = sortedUsers.slice(12);
        const otherCounts = otherUsers.reduce((acc, [, userData]) => acc + userData.count, 0);

        const labels = [...topUsers.map(([username]) => username), 'Others'];
        const counts = [...topUsers.map(([, userData]) => userData.count), otherCounts];
        const userIds = [...topUsers.map(([, userData]) => userData.userId), -1]; // Using -1 for Others as it won't map to a real user

        return {
            labels,
            userIds,
            datasets: [{
                data: counts,
                backgroundColor: labels.map(() => {
                    let randomColor = [
                        Math.floor(Math.random() * 127) + 128,  // R
                        Math.floor(Math.random() * 127) + 128,  // G
                        Math.floor(Math.random() * 127) + 128   // B
                    ];
                    return `rgb(${randomColor[0]}, ${randomColor[1]}, ${randomColor[2]})`;
                }),
            }],
        };
    };
    const renderTableData = () => {
        const topUsersCount = 12;

        return chartData.labels.slice(0, topUsersCount).map((user, index) => {
            const userId = chartData.userIds[index]; // Directly using index as we are not sorting here anymore
            return (
                <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                        <Link className="clickable-user" to={`/accounts/${userId}/${user}`}>
                            {user}
                        </Link>
                    </td>
                    <td>{chartData.datasets[0].data[index]}</td>
                </tr>
            );
        });
    };

    useEffect(() => {
        const loadData = async () => {
            const punishments = await fetchPunishments();
            const data = transformData(punishments!);
            setChartData(data);
        };

        loadData();
    }, [apiContext]);

    return (
        <>
            {chartData.userIds.length > 1 ? (<div className="card chart-container">
                <BlackCard subtitle="Member Punishments" text="Charted punishments. These include light and severe punishments."></BlackCard>
                <div className="pie-chart-display">
                    <Pie data={chartData} />
                </div>
                
                <table>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>User</th>
                            <th>Punishments</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderTableData()}
                    </tbody>
                </table>
            </div>) : (<p className="u-center u-p">No punishments :)</p>)}
            
        </>
       
    );
};
