import React from 'react';
import Chart from 'chart.js/auto';
import "./DailyActivityGraph.css";
import { Line } from 'react-chartjs-2';

interface DailyActivityGraphProps {
    data: { [key: number]: number };
}

const DailyActivityGraph: React.FC<DailyActivityGraphProps> = ({ data }) => {
    const chartData = {
        labels: Object.keys(data).map(hour => `${hour}:00`), // Display hour with ":00" suffix
        datasets: [
            {
                label: 'Hourly Activity',
                data: Object.values(data),
                borderColor: '#e7752eb9',
                backgroundColor: 'rgba(231, 117, 46, 0.3)', // 30% transparent
                fill: true,
                borderWidth: 2,
                pointBackgroundColor: '#e7752eb9',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#e7752eb9'
            }
        ]
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                beginAtZero: true,
            },
            y: {
                beginAtZero: true
            }
        }
    };

    return (
        <div className="activityGraphContainer">
            <div className="activityGraphWrapper">
                <Line data={chartData} options={chartOptions} />
            </div>
        </div>
    );
}

export default DailyActivityGraph;
