import Description from "../../Components/Description";

import Information from "../../Components/Information";
import BalanceViewSixy from "./BalanceViewSixy";

import styles from "./DonationView.module.css";
import { PackageView } from "./PackageView";

export const DonationView = () => {
  return (
    <div className={styles.wrapper}>
      <Information title="Balance">
        <BalanceViewSixy></BalanceViewSixy>
      </Information>
      <Information title="Packages">
        <PackageView></PackageView>
      </Information>
      <Information title="Info">
        <Description
          mode="Light"
          size={18}
          text="All donations are used to pay the host and other running server costs. &br Your donations are very appreciated and more features will be added in the future!"
        ></Description>
      </Information>
    </div>
  );
};
