import React, { useContext, useEffect, useState } from "react";
import { Chart, CategoryScale, LinearScale, BarElement } from 'chart.js/auto';
import { Line } from "react-chartjs-2";
import { APIResponse } from "../../Networking/ApiUtility";
import { PlaytimeSummary } from "../../Networking/SunsetAPI";

import { SunsetAPIAccountContext } from "../../Networking/Interface/Account/AccountAPIProvider";
import { CharacterDTO } from "../../Networking/Interface/Account/AccountInterface";

Chart.register(CategoryScale, LinearScale, BarElement);
interface PlaytimeSummaryProps {
    character: CharacterDTO;
}

const PlaytimeGraph: React.FC<PlaytimeSummaryProps> = ({ character }) => {
    const api = useContext(SunsetAPIAccountContext);
    const [playtimeSummary, setPlaytimeSummary] = useState<PlaytimeSummary[]>([]);

    useEffect(() => {
        const fetchPlaytimeSummary = async () => {
            const response: APIResponse<PlaytimeSummary[]> = await api!.getPlaytimeSummary(character.id, character.characterName);
            if (response.success) {
                const dates = generateDates(30);
                const summary = response.data!.map(item => ({ ...item, date: new Date(item.date) }));

                const mergedData = dates.map(date => {
                    const found = summary.find(s => s.date.toLocaleDateString() === date);
                    return found ? found : { date: new Date(date), timePlayedHours: 0 };
                });

                setPlaytimeSummary(mergedData);
            }
        };
        fetchPlaytimeSummary();
    }, [character, api]);

    const generateDates = (daysBack: number): string[] => {
        const result = [];
        for (let i = daysBack; i >= 0; i--) {
            const date = new Date();
            date.setDate(date.getDate() - i);
            result.push(date.toLocaleDateString());
        }
        return result;
    };

    const data = {
        labels: playtimeSummary.map(p => p.date.toLocaleDateString()),
        datasets: [
            {
                label: 'Playtime in Hours',
                data: playtimeSummary.map(p => p.timePlayedHours),
                borderColor: '#e7752eb9',
                backgroundColor: 'rgba(231, 117, 46, 0.3)',
                fill: true,
                borderWidth: 2,
                pointBackgroundColor: '#e7752eb9',
                pointBorderColor: '#fff',
                pointHoverBackgroundColor: '#fff',
                pointHoverBorderColor: '#e7752eb9'
            }
        ],
    };

    return (
        <div style={{ maxWidth: '900px', margin: 'auto', marginBottom: '25px' }}>
            <Line
                data={data}
                width={650}
                height={400}
                options={{
                    maintainAspectRatio: false,
                    plugins: {
                        tooltip: {
                            callbacks: {
                                title: function (context: any) {
                                    return context[0].label;
                                },
                                label: function (context: any) {
                                    return `${context.dataset.label}: ${context.parsed.y} hrs`;
                                }
                            }
                        }
                    },
                    scales: {
                        y: {
                            beginAtZero: true,
                            ticks: {
                                color: '#333',
                                callback: function (tickValue: string | number): string {
                                    return `${tickValue} hrs`;
                                }
                            }
                        },
                        x: {
                            type: 'category',
                            ticks: {
                                color: '#333'
                            }
                        }
                    }
                }}
            />
        </div>
    );
};

export default PlaytimeGraph;
