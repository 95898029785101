import React, { useState, useContext, useEffect } from "react";

import LogFilter from "./LogFilter";
import LogView, { APIResponse, Log } from "./LogView";
import { LogPermission } from "../../Networking/Interface/Logs/LogInterface";
import { SunsetAPILogContext } from "../../Networking/Interface/Logs/LogAPIProvider";
import { UserContext } from "../../User/UserContext";


const LogDashboard: React.FC = () => {
    const [response, setResponse] = useState<APIResponse<Log[]>>();
    const { user } = useContext(UserContext);
    const [allowedOptions, setAllowedOptions] = useState<LogPermission[]>();
    const handleLogFilterSubmit = async (response: APIResponse<Log[]>) => {
        setResponse({ ...response });
    };

    const api = useContext(SunsetAPILogContext);
    useEffect(() => {
        const fetchPerms = async () => {
            const permsResponse = await api?.getLogPermissions();
            if (permsResponse?.success) {

                setAllowedOptions(permsResponse.data!)

            }
        };
        fetchPerms();
    }, [user, api]);
    return (
        <div className="log-dashboard">
            <LogFilter onSubmit={handleLogFilterSubmit} allowedOptions={allowedOptions!} />
            <LogView response={response} />
        </div>
    );
};

export default LogDashboard;