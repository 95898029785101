import React, { useState, useEffect, useContext } from 'react';
import { StaffActivityCard } from './StaffActivityCard'; // Adjust this import based on your actual file path
import { Input, Select, Checkbox, Button} from 'antd';
import { InfinitySpin } from 'react-loader-spinner';
import { SunsetAPIManagementContext } from '../../Networking/Interface/Management/SunsetAPIManagementProvider';
import { StaffInfo } from '../../Networking/Interface/Management/StaffActivityInterfaces';
import Wrapper from '../../Components/Wrapper';
import { TabPane, Tabs } from '../../Components/Tabs';
import Grid from '../../Components/Grid';

const { Option } = Select;
const { Search } = Input;

export const ManagementDashboard = () => {
    const api = useContext(SunsetAPIManagementContext);
    const [staffInfo, setStaffInfo] = useState<StaffInfo[]|null>([]);
    const [filteredStaffInfo, setFilteredStaffInfo] = useState<StaffInfo[] | null>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterBy, setFilterBy] = useState('lastOnline'); // Default filter
    const [order, setOrder] = useState('asc'); // Default order
    const [includeRetired, setIncludeRetired] = useState(false);

    useEffect(() => {
        const fetchStaffInfo = async () => {
            const response = await api?.getStaffActivitySummary();
            if (response && response.success) {
                setStaffInfo(response.data!);
                setFilteredStaffInfo(response.data!); // Initialize filtered data with all staff info
            }
        };
        fetchStaffInfo();
    }, [api]);

    const handleSearch = () => {
        let filteredData = staffInfo!.filter((staff) =>
            staff.username?.toLowerCase().includes(searchTerm.toLowerCase())
        );

        if (!includeRetired) {
            filteredData = filteredData.filter((staff) => staff.staffActivitySummary?.lastOnline);
        }

        // Filtering further based on the filterBy selection
        switch (filterBy) {
            case 'ticketsLast7Days':
                filteredData.sort((a, b) =>
                    order === 'asc' ?
                        (a.staffTicketSummary?.ticketsLast7Days || 0) - (b.staffTicketSummary?.ticketsLast7Days || 0) :
                        (b.staffTicketSummary?.ticketsLast7Days || 0) - (a.staffTicketSummary?.ticketsLast7Days || 0)
                );
                break;
            case 'totalHandledTickets':
                filteredData.sort((a, b) =>
                    order === 'asc' ?
                        (a.staffTicketSummary?.totalHandledTickets || 0) - (b.staffTicketSummary?.totalHandledTickets || 0) :
                        (b.staffTicketSummary?.totalHandledTickets || 0) - (a.staffTicketSummary?.totalHandledTickets || 0)
                );
                break;
            case 'punishmentsLast7Days':
                filteredData.sort((a, b) =>
                    order === 'asc' ?
                        (a.staffPunishmentSummary?.punishmentsLast7Days || 0) - (b.staffPunishmentSummary?.punishmentsLast7Days || 0) :
                        (b.staffPunishmentSummary?.punishmentsLast7Days || 0) - (a.staffPunishmentSummary?.punishmentsLast7Days || 0)
                );
                break;
            case 'lastOnline':
                filteredData.sort((a, b) => {
                    const now = new Date();
                    const aYearAgo = new Date(now.setFullYear(now.getFullYear() - 1)).getTime();
                    const dateA = new Date(a.staffActivitySummary?.lastOnline || aYearAgo).getTime();
                    const dateB = new Date(b.staffActivitySummary?.lastOnline || aYearAgo).getTime();
                    return order === 'asc' ? dateA - dateB : dateB - dateA;
                });
                break;
            case 'userRole':
                filteredData.sort((a, b) => {
                    if (order === 'asc') {
                        return a.userRole > b.userRole ? 1 : -1;
                    } else {
                        return a.userRole < b.userRole ? 1 : -1;
                    }
                });
                break;
            default:
            // Handle default case if needed
        }

        setFilteredStaffInfo(filteredData);
    };

    const handleClearFilters = () => {
        setFilteredStaffInfo(staffInfo);
        setSearchTerm('');
        setFilterBy('lastOnline');
        setOrder('asc');
        setIncludeRetired(false);
    };

    return (
        <Wrapper width="1200px">
            <Tabs defaultActiveKey="Staff">
                <TabPane tabTitle="Staff Activity" tabKey="Staff">
                    <div style={{ marginBottom: 20 }}>
                        <Search
                            placeholder="Search by name"
                            onSearch={handleSearch}
                            style={{ width: 200, marginRight: 8 }}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <Select
                            defaultValue="lastOnline"
                            style={{ width: 200, marginRight: 8 }}
                            onChange={setFilterBy}
                        >
                            <Option value="lastOnline">Last Online</Option>
                            <Option value="ticketsLast7Days">Tickets Last 7 Days</Option>
                            <Option value="totalHandledTickets">Total Handled Tickets</Option>
                            <Option value="punishmentsLast7Days">Punishments Last 7 Days</Option>
                            <Option value="userRole">User Role</Option>
                        </Select>
                        <Select defaultValue="asc" style={{ width: 120, marginRight: 8 }} onChange={setOrder}>
                            <Option value="asc">Lowest to Highest</Option>
                            <Option value="desc">Highest to Lowest</Option>
                        </Select>
                        <Checkbox
                            onChange={(e) => setIncludeRetired(e.target.checked)}
                            style={{ marginRight: 8 }}
                        >
                            Include Retired Staff
                        </Checkbox>
                        <Button type="primary" onClick={handleSearch}>
                            Search
                        </Button>
                        <Button onClick={handleClearFilters} style={{ marginLeft: 8 }}>
                            Clear Filters
                        </Button>
                    </div>
                    <Grid elementWidth="300px">
                        {filteredStaffInfo!.length > 0 ? (
                            filteredStaffInfo!.map((staff, index) => (
                                <StaffActivityCard key={index} staffInfo={staff} />
                            ))
                        ) : (
                            <InfinitySpin width="200px" color="#4fa94d" />
                        )}
                    </Grid>
                </TabPane>
            </Tabs>
        </Wrapper>
    );
};
