import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "../ui/Button";
import { ButtonProps } from "../ui/ButtonProps";

interface ViewSessionProps {
  id: number;
}

function ViewSession({ id }: ViewSessionProps) {
  const navigate = useNavigate();
  const buttonProps: ButtonProps = {
    size: "small",
    onClick: handleViewSession,
  };
  function handleViewSession() {
    navigate(`/launcher/session/${id}`);
  }

  return (
    <div>
      <Button {...buttonProps}>View Session</Button>
    </div>
  );
}

export default ViewSession;
