import React, { useEffect, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { AccountSessionFactionDTO } from '../../../Networking/Interface/Faction/FactionInterface';


interface ActiveMembersPieChartProps {
    sessions: AccountSessionFactionDTO[];
}

const FactionPlaytimePieChart: React.FC<ActiveMembersPieChartProps> = ({ sessions }) => {
    const [chartData, setChartData] = useState<any>(null);
    const [totalPlaytime, setTotalPlaytime] = useState<number>(0);
    useEffect(() => {
        const transformedData = transformData(sessions);
        setChartData(transformedData);
    }, [sessions]);

    const transformData = (sessions: AccountSessionFactionDTO[]) => {
        const playtimes: { [username: string]: number } = {};

        sessions.forEach(session => {
            const username = session.username || 'Unknown User';
            const playtime = (new Date(session.end).getTime() - new Date(session.start).getTime()) / (60 * 60 * 1000);

            if (playtimes[username]) {
                playtimes[username] += playtime;
            } else {
                playtimes[username] = playtime;
            }
        });

        const sortedPlaytimes = Object.entries(playtimes).sort(([, a], [, b]) => b - a);
        const topPlaytimes = sortedPlaytimes.slice(0, 10);
        const othersPlaytime = sortedPlaytimes.slice(10).reduce((acc, [, time]) => acc + time, 0);
        const totalHours = sortedPlaytimes.reduce((acc, [, time]) => acc + time, 0);
        setTotalPlaytime(totalHours);
        if (othersPlaytime > 0) {
            topPlaytimes.push(['Others', othersPlaytime]);
        }

        const labels = topPlaytimes.map(([username]) => username);
        const data = topPlaytimes.map(([, time]) => time);

        return {
            labels,
            datasets: [{
                data,
                backgroundColor: labels.map((_, i) => `hsla(${i * 36}, 50%, 50%, 0.6)`),
                borderColor: labels.map((_, i) => `hsla(${i * 36}, 50%, 50%, 1)`),
                borderWidth: 1,
            }],
        };
    };

    return (
        <div className="pieChartContainer">
            {chartData ? (
                <>
                    <Pie data={chartData} />
                    <p className="totalPlaytime">
                        Total Playtime: {totalPlaytime.toFixed(2)} Hours
                    </p>
                </>
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};

export default FactionPlaytimePieChart;
