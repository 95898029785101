
import React, { useContext, useState } from 'react';
import { InfinitySpin } from 'react-loader-spinner';
import { useLocation, useNavigate, useParams } from 'react-router-dom'; // or your preferred routing library
import { BlackCard } from '../../Components/BlackCard';
import GoldShinyButton from '../../Components/GoldShinyButton';
import { ToastType } from '../../Components/Toast/Toast';
import { useToast } from '../../Components/Toast/ToastContext';
import Wrapper from '../../Components/Wrapper';
import { SunsetAPIContext } from '../../Networking/ApiProvider';
import styles from './ApprovePayment.module.css'; // Your CSS module

export const ApprovePayment: React.FC = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const api = useContext(SunsetAPIContext);
    const { amount } = useParams<{ amount: string }>();
    const paymentId = queryParams.get('paymentId');
    const token = queryParams.get('token');
    const payerId = queryParams.get('PayerID');
    const [loading, setLoading] = useState(false);
    const creditAmount = parseInt(amount!, 10) / 100;
    const navigate = useNavigate();
    const { showToast } = useToast();
    const executePayment = async () => {
        try {
            setLoading(true);
   
            const response = await api!.executePayment(paymentId!, payerId!, token!);
            if (response.success) {
                setLoading(false);
                showToast(ToastType.Success, "Successfuly added your credits!", 10);
                navigate("/payment-success");
            }
        }
        catch {
            showToast(ToastType.Success, "Something went wrong! Please contact staff", 10);
        }
    };

    return (
        <Wrapper width="1000px">
            <div className={styles.paymentApproval}>

                <BlackCard subtitle="You are all set!" text="Confirm your purchase to receive your credits!"></BlackCard>
                <div className={styles.creditInfo}>
                    <img src={`${process.env.PUBLIC_URL}/credit.png`} alt="Credit" className={styles.creditIcon} />
                    <span className={styles.creditAmount}>+ {creditAmount.toFixed(2)}</span>
                </div>
                {
                    !loading ? (
                        <GoldShinyButton text="Approve" onClick={executePayment}></GoldShinyButton>
                    ) : (
                        <InfinitySpin></InfinitySpin>
                    )
                }
                
            </div>
        </Wrapper>
        
    );
};

