export enum UserRole {
    User = 0,
    Trusted = 1,
    Helper = 2,
    Mod = 3,
    Admin = 4,
    SnrAdmin = 5,
    LeadAdmin = 6,
    ServerLeader= 7,
    Manager = 8,
    Owner = 9
}
export function getRoleString(role: UserRole): string {
    switch (role) {
        case UserRole.User: return 'User';
        case UserRole.Trusted: return 'Trusted';
        case UserRole.Helper: return 'Helper';
        case UserRole.Mod: return 'Mod';
        case UserRole.Admin: return 'Admin';
        case UserRole.SnrAdmin: return 'Snr Admin';
        case UserRole.LeadAdmin: return 'Lead Admin';
        case UserRole.ServerLeader: return 'Server Leader'
        case UserRole.Manager: return 'Manager';
        case UserRole.Owner: return 'Owner';
        default: return 'Unknown';
    }
}
export enum JobClass {
    Civilian = 0,
    TruckDriver = 2,
    Police = 3,
    Mafia = 4,
    Courier = 5,
    Government = 6,
    SWAT = 7,
    EMS = 8,
    SAFD = 9,
    Detective = 10,
    Pizzaboy = 11,
    GarbageCollector = 12,
    Miner = 13,
    Mechanic = 14,
    Taxi = 15,
    TrainDriver = 16,
    Pilot = 17,
    Dockworker = 18,
    Sweeper = 19,
    BusDriver = 20,
    Lifeguard = 21
}
export enum LogType {
    Player,
    Other,
    Chat,
    Command,
    Anticheat
}

export enum ChatSubcategories {
    global,
    local,
    dev,
    local_ooc,
    admin,
    manager,
    pm,
    ask,
    helper
}

export enum OtherSubcategories {
    weapon,
    storage
}

export enum PlayerSubcategories {
    action,
    damage,
    connection,
    money,
    wound,
    aim
}

export enum AnticheatSubcategories {
    warn,
    kick
}

export enum CommandSubcategories {
    other
}
export enum DisconnectReason {
    crash = 0,
    quit = 1,
    kick = 2
}
