import React, { useContext } from 'react';
import { CrateReward } from '../../Networking/Interface/Gamba/GambaInterface';
import styles from './CrateRewardView.module.css';
import { SunsetAPIGambaContext } from '../../Networking/Interface/Gamba/GambaAPIProvider';

interface CrateRewardViewProps {
    rewards: CrateReward[];
}

const CrateRewardView: React.FC<CrateRewardViewProps> = ({ rewards }) => {
    
    const sortedRewards = [...rewards].sort((a, b) => a.rarity - b.rarity);
    const api = useContext(SunsetAPIGambaContext)
    const getRarityColor = (rarity: number): string => {
        switch (rarity) {
            case 1: return styles.common;
            case 2: return styles.uncommon;
            case 3: return styles.rare;
            case 4: return styles.legendary;
            default: return '';
        }
    };

    return (
        <div className={styles.wrapper}>
            {sortedRewards.map((reward) => (
                <div key={reward.rewardid} className={`${styles.rewardCard} ${styles.rewardMediumCard} ${getRarityColor(reward.rarity)}`}>
                    {reward.reward.imageUrl && <img src={`${process.env.PUBLIC_URL}/gamba/${reward.reward.imageUrl}`} alt={reward.reward.name} style={{ width: '150px', height: '150px' }} />}
                    {reward.quantity > 1 && <span className={styles.quantity}>{reward.quantity}x</span>}
                    <div className={styles.details}>
                        <h3>{reward.probability}%</h3>
                        <p>{reward.reward.name}</p>
                        {reward.reward.description && <p>{reward.reward.description}</p>}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default CrateRewardView;
