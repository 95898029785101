
import React from 'react';
import { SunsetAPIAccount } from './SunsetAPIAccount';

interface SunsetAPIProviderProps {
    children: React.ReactNode;
}

const SunsetAPIAccountContext = React.createContext<SunsetAPIAccount | undefined>(undefined);

const SunsetAccountAPIProvider: React.FC<SunsetAPIProviderProps> = ({ children }) => {
    const api = new SunsetAPIAccount();

    return (
        <SunsetAPIAccountContext.Provider value={api}>
            {children}
        </SunsetAPIAccountContext.Provider>
    );
};

export { SunsetAccountAPIProvider, SunsetAPIAccountContext };