import React, { useState } from "react";
import "./Tabs.css";

interface TabsProps {
  defaultActiveKey?: string;
  onTabChange?: (key: string) => void;
  children: React.ReactNode;
}

export const Tabs: React.FC<TabsProps> = ({
  defaultActiveKey,
  onTabChange,
  children,
}) => {
  const [activeKey, setActiveKey] = useState<string>(defaultActiveKey || "");

  const handleTabClick = (key: string) => {
    setActiveKey(key);
    onTabChange && onTabChange(key);
  };

  return (
    <>
      <ul className="tabs">
        {React.Children.map(children, (child) => {
          if (
            React.isValidElement<TabPaneProps>(child) &&
            child.props.tabKey &&
            child.props.tabTitle
          ) {
            return (
              <li
                className={`tab ${
                  activeKey === child.props.tabKey ? "tab-active" : ""
                }`}
                onClick={() => handleTabClick(child.props.tabKey)}
              >
                {child.props.tabTitle}
              </li>
            );
          }
          return null;
        })}
      </ul>
      {React.Children.map(children, (child) => {
        if (
          React.isValidElement<TabPaneProps>(child) &&
          child.props.tabKey === activeKey
        ) {
          return child;
        }
        return null;
      })}
    </>
  );
};

interface TabPaneProps {
  tabKey: string;
  tabTitle: string;
  children: React.ReactNode;
  style?: React.CSSProperties; // Add this line
}

export const TabPane: React.FC<TabPaneProps> = ({ children, style }) => {
  return <div style={style}>{children}</div>; // Apply the style prop conditionally
};
