import React from 'react';
import styles from './StyledParagraph.module.css'; // Adjust the import path to your CSS module

interface StyledParagraphProps {
    headline?: string;
    children: React.ReactNode;
    maxHeight?: string;
}

export const StyledParagraph: React.FC<StyledParagraphProps> = ({
    headline,
    children,
    maxHeight,
}) => {
    const containerStyle = {
        '--max-height': maxHeight,
    } as React.CSSProperties;

    // Function to allow rendering of <br> tags in text
    const renderTextWithBreaks = (text: React.ReactNode) => {
        if (typeof text === 'string') {
            // Split the string by <br> and map it to include JSX <br> tags
            const splitText = text.split('&br');
            return splitText.map((chunk, index) => (
                <React.Fragment key={index}>
                    {chunk}
                    {index < splitText.length - 1 && <br />}
                </React.Fragment>
            ));
        }
        return text;
    };

    return (
        <div className={styles.container} style={containerStyle}>
            {headline && (
                <h3 className={styles.headline}>{headline}</h3>
            )}
            <p className={styles.text}>
                {renderTextWithBreaks(children)}
            </p>
        </div>
    );
};